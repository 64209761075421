<template>
  <div>
    <Header :info="info" />
    <div v-if="pages" class="container pgContainer">
      <div>
        <div class="sideNav">
          <ul v-for="page in pages" :key="page.id">
            <li>
              <a :class="{ 'activePage': selectedPageId === page.id }" @click="scroll(page.id)">{{ page.title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div :id="'page' + page.id" v-for="page in pages" :key="page.id" style="margin-bottom:20px">
          <JsonPageNormal :id="'subPage' + page.id" v-if="page.description !== 'form' && page.description !== 'file'" :page="page" :hideTitle="true" />
          <div v-if="page.description === 'form'">
            {{ page.title }}
            <JsonPageNormal :page="page" :hideTitle="true" />
          </div>
          <div v-if="page.description === 'file'">
            <p style="text-align:center">
              <a class="file" :href="fileCdn + page.src" target="_blank">{{ page.title }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container" style="min-height:900px">
      <img src="../assets/loading-pulse.svg">
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.file {
  color:var(--color1);
  font-weight: bold;
  display: block;
  padding:12px;
  text-decoration: underline;
}
.pgContainer {
  background:#fff;
  display:grid;
  grid-template-columns: 5fr 7fr;
  margin-top:20px;
  margin-bottom:20px;
  min-height:900px;
}
.sideNav {
  width:calc(100% - 15px);
  position:sticky;
  top:54px;
  background:#fff;
  ul {
    list-style: none;
    li {
      border-bottom: 1px solid #ccc;
      a {
        color:var(--color2);
        font-weight: 400;
        display: block;
        padding:10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      a:hover, .activePage {
        color:var(--color2);
        background: var(--color1);
      }
      .activePage {
        font-weight: bold;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .pgContainer {
    grid-template-columns: 1fr;
  }
  .sideNav {
    display:none;
  }
}
</style>

<script>
import axios from 'axios'
import JsonPageNormal from '@/components/JsonPageNormal'
export default {
  components: { JsonPageNormal },
  props: ['info'],
  data () {
    return {
      id: null,
      parent: null,
      pageType: null,
      fileCdn: this.$store.state.fileCdn,
      selectedPageId: null,
      pages: []
    }
  },
  beforeMount () {
    this.getPages()
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.parent = this.$route.params.parent
      this.pageType = this.$route.name
      this.pages = null
      this.getPages()
    }
  },
  methods: {
    scroll: function (id) {
      this.$nextTick(() => {
        const el = document.getElementById('page' + id)
        el.scrollIntoView({ behavior: 'smooth' })
        this.selectedPageId = id
      })
    },
    getPages: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        parent: this.$route.params.parent,
        sId: this.$store.state.user.sId,
        pageType: this.pageType,
        user: this.$store.state.user
      }
      await axios.post('api/getPages.php', payload).then(response => {
        this.pages = response.data.pages
        this.selectedPageId = this.pages[0].id
        const id = parseInt(this.$route.params.id)
        if (id) {
          setTimeout(() => {
            // document.getElementById('subPage' + id).scrollIntoView()
            console.log(id)
            const el = 'subPage' + id
            document.getElementById(el).scrollIntoView({ behavior: 'smooth', block: 'center' })
          }, 2000)
        }
      })
    }
  }
}
</script>
