<template>
  <div class="block" v-if="shipmentOptions.length > 0">
    <div>
      <div class="tabs">
        <button class="activeTab">Teslimat Seçenekleri</button>
      </div>
      <div class="options">
        <button @click="chooseSc(item)" class="option" :class="{ 'activeOption': item.kargoId === selectedShipment.kargoId }" v-for="item in shipmentOptions" :key="item.kargoId">
          <div class="kargoIcon">
            <img :src="require('../assets/icons/' + item.gateway + '.png')" />
          </div>
          <p>{{ item.kargoName }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user', 'shipmentOptions', 'selectedShipment'],
  data () {
    return {
    }
  },
  methods: {
    chooseSc (sc) {
      this.$parent.$parent.selectedShipment = sc
      this.$parent.$parent.summary.shipment = sc.kargoTutar
      this.$parent.$parent.summary.shipmentTo = sc.kargoMinTutar
      this.$parent.$parent.getCart()
    }
  }
}
</script>

<style scoped lang="scss">
.kargoIcon {
  width:60px;
  height: 60px;
}
.tabs {
  display:flex;
  margin-bottom: 8px;
  button {
    border:none;
    background:none;
    border-bottom: 3px solid white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    transition: all 0.1s ease-in;
    cursor: default;
  }
  .activeTab {
    border-bottom: 3px solid var(--color1);
  }
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .option {
    border: 2px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;
    display: grid;
    place-items: center;
    gap: 2px;
    font-family: var(--font2);
    padding: 10px;
    p {
      font-size: 10px;
      color: #333;
    }
    &.activeOption {
      border: 2px solid var(--color1);
      // background: var(--color1);
      // color: white;
    }
  }
}
@media screen and (max-width: 768px) {
  .options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    .option {
      padding: 2px;
    }
  }
}

</style>
