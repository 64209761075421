<template>
  <div class="blogs">
    <div class="grd container">
      <div class="sideBar">
        <div class="cats">Kategoriler</div>
        <div class="tags">
          <router-link :to="{ name: 'Tag', params: { tag: $fnc.seo(item) }}" class="chip" v-for="item in keywords" :key="item">
            #{{ item }}
          </router-link>
          <br>
          <router-link :to="{ name: 'Blogs' }" class="chip">
            tüm yazılar
          </router-link>
        </div>
      </div>
      <div class="blogList">
        <div v-for="item in blogs" :key="item.id">
          <router-link
          class="image"
          :title="item.title"
          :style="'background:url(' + fileCdn + item.src + ') no-repeat center; background-size: cover;'"
          :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: 0 } }">
          </router-link>
          <div class="text">
            <h3><router-link :to="{ name: 'Page', params: { id: item.id }}">{{ item.title }}</router-link></h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <div class="homeSideBar">
        <div class="box" v-for="item, index in sideBar" :key="index">
          <router-link :to="item.url">
            <img :src="fileCdn + item.image" :alt="item.title" :title="item.title" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
a {
  color:#666;
}
a:hover {
  color:var(--color1);
  text-decoration: underline;
}
.image {
  width:100%;
  height:300px;
  overflow:hidden;
  display:block;
  border-radius: 4px 4px 0 0;
}
.grd {
  display: grid;
  grid-template-columns: 300px 1fr 310px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .grd {
    grid-template-columns: 1fr;
  }
}
.homeSideBar {
  position: sticky;
  top: 50px;
}
.box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  border-radius: 3px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.blogs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background:#f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.blogList {
  display:grid;
  grid-template-columns: 1fr;
  gap: 20px;
  div {
    padding: 0px;
    background:#fff;
    .text {
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    img {
      width: 100%;
    }
    h3 {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
// tag chip class
.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
  border-radius: 15px;
  background: #fff;
  margin-right: 5px;
  margin-bottom: 10px;
  color: #000;
  text-decoration: none;
}
.chip:hover {
  color: var(--color1);
  text-decoration: underline;
}
</style>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      blogs: [],
      fileCdn: this.$store.state.fileCdn,
      sideBar: [],
      keywords: [],
      title: 'En Son Blog Yazıları'
    }
  },
  beforeMount () {
    this.getBlogs()
  },
  watch: {
    $route (to, from) {
      this.getBlogs()
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: 'Blogs'
        }
      ]
    }
  },
  methods: {
    getBlogs: async function () {
      const payload = {
        user: this.$store.state.user,
        tag: this.$route.params.tag
      }
      await axios.post('api/getBlogs.php', payload).then(response => {
        this.blogs = response.data.data
        this.keywords = response.data.keywords
        this.getRightSideBar()
        if (this.$route.params.tag) {
          this.title = '#' + this.$route.params.tag.replace('-', ' ') + ' etiketine Göre Blog Yazıları'
        }
      })
    },
    getRightSideBar: async function () {
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('api/right_block.php', payload).then(response => {
        this.sideBar = response.data.data
      })
    }
  }
}
</script>
