<template>
  <div style="padding-top:10px;padding-bottom:10px">
    <div style="box-shadow: 0px -5px 10px 0px rgba(0,0,0,0.1);width:95%;margin:auto">
      <div v-for="item in items" :key="item.id" class="sepetRow">
        <div style="text-align:center">
          <router-link :to="item.url"><img style="padding:4px; border:1px solid #e0e0e0;background:#fff" width="100%" :src="item.img"></router-link>
        </div>
        <div class="text">
          <div class="title">
            <div class="baslik" style="padding:4px;font-size:12px">{{ item.title }}</div>
            <div class="cartActions">
              <a @click="editQty(item)">{{ $tc('piece', item.qty) }} <i class="fa fa-edit"></i></a>
              <a>{{ (item.realPrice).toFixed(2) }} {{ $parent.cur.sign }}</a>
              <a title="Sepetten Sil" @click="deleteFromCart(item)"><i class="fa fa-trash-alt"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GlassModal v-if="cartRemoveModal" @close="closeModal">
      <template v-slot:title>Sepetten Çıkarın</template>
      <template v-slot:body>
        <div>
          <div>
            <div style="text-align:center;margin-bottom:12px"><img style="padding:4px; border:1px solid #e0e0e0;" width="30%" :src="activeItem.img"></div>
            <div style="font-size:12px"><span style="font-weight:bold">{{ activeItem.title }}</span><br><br>Bu ürünü sepetinizden çıkarmak istiyor musunuz?</div>
          </div>
          <div class="modalActions">
            <button class="primaryButton" style="background:red" @click="reallyDelete">Sepetten Çıkar</button>
            <button class="secondaryButton" @click="closeModal">Vazgeç</button>
          </div>
        </div>
      </template>
    </GlassModal>
    <GlassModal v-if="qtyModal" @close="closeQtyModal">
      <template v-slot:title>Miktarı Düzenleyin</template>
      <template v-slot:body>
        <div>
          <div>
            <div style="text-align:center;margin-bottom:12px"><img style="padding:4px; border:1px solid #e0e0e0;" width="30%" :src="activeItem.img"></div>
            <div style="font-size:12px"><span style="font-weight:bold">{{ activeItem.title }}</span></div>
          </div>
          <div style="margin-top:22px;display:grid; grid-template-columns:auto 120px 80px">
            <div class="inputRow">
              <button @click="decreaseQty"><i class="fa fa-minus"></i></button>
              <input @keypress.enter="updateCart(activeItem)" class="qtyInput" type="number" v-model="activeItem.qty" placeholder="Miktar">
              <button @click="increaseQty"><i class="fa fa-plus"></i></button>
            </div>
            <button class="primaryButton" style="width:calc(100% - 12px)" @click="updateCart(activeItem)">Güncelle</button>
            <button class="secondaryButton" @click="closeQtyModal">Vazgeç</button>
          </div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
export default {
  props: ['items'],
  data () {
    return {
      cartRemoveModal: false,
      qtyModal: false,
      activeItem: null
    }
  },
  methods: {
    decreaseQty () {
      this.activeItem.qty--
      if (this.activeItem.qty < 1) {
        this.activeItem.qty = 1
      }
    },
    increaseQty () {
      console.log(this.activeItem)
      this.activeItem.qty++
    },
    updateCart (item) {
      this.$parent.updateCartQty(item)
      this.closeQtyModal()
    },
    editQty (item) {
      this.activeItem = item
      this.qtyModal = true
    },
    closeQtyModal () {
      this.qtyModal = false
      this.activeItem = null
    },
    reallyDelete () {
      this.$parent.deleteFromCart(this.activeItem.id)
      this.closeModal()
    },
    deleteFromCart (item) {
      this.cartRemoveModal = true
      this.activeItem = item
    },
    closeModal () {
      this.cartRemoveModal = false
      this.activeItem = null
    }
  }
}
</script>

<style scoped lang="scss">
.sepetRow:nth-child(even) {
  background:#efefef;
}
.inputRow {
  max-width: 180px;
  display:grid; grid-template-columns: 30px auto 30px;
  width: calc(100% - 12px);
  border-radius: 4px;
  overflow: hidden;
  button {
    border: none;
    background: #d8d8d8;
  }
}
.qtyInput {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  font-size: 14px;
  outline:none;
  font-weight: bold;
  width:calc(100%);
  text-align: center;
}
.modalActions {
  margin-top: 12px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  button {
    margin-right: 12px;
  }
  button:last-child {
    margin-right: 0;
  }
}
.cartActions {
  display: grid;
  grid-template-columns: auto 80px 40px;
  justify-items: flex-end;
  width:100%;
  a {
    padding: 6px;
    font-size: 11px !important;
    font-weight: normal !important;
    margin-right:12px;
  }
  a:last-child {
    margin-right: 0;
  }
}
.primaryButton {
  background-color: var(--color1);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.secondaryButton {
  background-color: #fff;
  color: var(--color1);
  border: 1px solid var(--color1);
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
}
</style>
