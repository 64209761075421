<template>
  <div>
    <Header :info="info" />
    <div class="tContainer">
      <div class="container" style="display:grid;place-items:center">
        <i class="fa fa-minus-circle"></i>
        <h1>HATA :/</h1>
        <h2>{{ order.error_message }}</h2>
        <p>
          <router-link v-if="order.mode !== 'payment'" :to="{ name: 'CompleteOrder' }">
            Tekrar deneme için tıklayınız
          </router-link>
          <router-link v-else :to="{ name: 'MyAccountCariOdeme' }">
            Tekrar deneme için tıklayınız
          </router-link>
        </p>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  padding-top:50px;
  padding-bottom:50px;
}
i {
  font-size: 100px;
  color: red;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
  text-align: center;
  font-size:18px;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
</style>

<script>
import axios from 'axios'
export default {
  components: { },
  props: ['info'],
  data () {
    return {
      order: ''
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Hata'),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  beforeMount () {
    this.getOrder()
  },
  watch: {
    $route (to, from) {
      this.getOrder()
    }
  },
  methods: {
    getOrder: async function () {
      const payload = {
        id: parseInt(this.$route.query.r),
        sId: this.$store.state.user.sId,
        user: this.$store.state.user
      }
      await axios.post('api/getOrder.php', payload).then(response => {
        setTimeout(() => {
          this.order = response.data.order
        }, 0)
      })
    }
  }
}
</script>
