<template>
  <div class="serviceRequest">
    <div class="formContainer">
      <div class="image">
        <img width="100%" v-if="image" :src="fileCdn + image.source" />
      </div>
      <div class="selector" id="selector">
        <div class="column">
          <h3>Marka Seçin</h3>
          <ul :class="{ collapsed: selectedBrand }" id="brandsContainer">
            <li v-for="item in brands" :key="item.id">
              <a @click="getModels(item, '')" :class="{ active: item.id === selectedBrand }">{{ item.title }}</a>
            </li>
          </ul>
          <a v-if="selectedBrand" @click="clearBrand" class="active">{{ selectedBrand.title }}</a>
        </div>
        <div class="column">
          <h3>Model Seçin</h3>
          <input
            v-if="modelCount > 20 && !selectedModel"
            autocomplete="off"
            type="text"
            placeholder="Modellerde Ara"
            v-model="searchTerm"
            id="modelFilter">
          <ul :class="{ collapsed: selectedModel }" id="modelsContainer" style="height:0px">
            <li v-for="item in filtered" :key="item.id">
              <a @click="getContacts(item)" :class="{ active: item.id === selectedModel }">{{ item.title }}</a>
            </li>
          </ul>
          <a v-if="selectedModel" @click="clearModel" class="active">{{ selectedModel.title }}</a>
        </div>
        <div class="column">
          <h3>İletişim Bilgileriniz</h3>
          <div v-if="selectedModel">
            <div v-if="!savingForm">
              <div class="inputRow">
                <label>Cihazınızın Sorunu</label>
                <input autocomplete="off" name="malfunction" type="text" v-model="malfunction">
              </div>
              <div class="inputRow">
                <label>Adınız - Soyadınız</label>
                <input autocomplete="off" name="userName" type="text" v-model="user.userName">
              </div>
              <div class="inputRow">
                <label>E-Posta Adresiniz</label>
                <input autocomplete="off" name="email" type="email" v-model="user.email">
              </div>
              <div class="inputRow">
                <label>Telefon Numaranız</label>
                <input autocomplete="off" name="userPhone" type="number" v-model="user.phone">
              </div>
              <p><button @click="sendForm" class="primary">Teklif Al</button></p>
            </div>
            <div v-if="savingForm" style="text-align:center">
              <div v-if="isSaving">
                <img src="~@/assets/loading-pulse.svg" />
              </div>
              <div v-else>
                <p><i class="success far fa-check-circle"></i></p>
                <p>Formunuz başarıyla gönderildi.</p>
                <p>Müşteri temsilcimiz size en kısa sürede geri dönüş yapacaktır.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      brands: [],
      models: [],
      selectedBrand: null,
      selectedModel: null,
      modelCount: 0,
      searchTerm: '',
      user: this.$store.state.user,
      malfunction: '',
      savingForm: false,
      isSaving: false,
      image: '',
      fileCdn: this.$store.state.fileCdn
    }
  },
  mounted () {
    this.getPageImage()
    this.getBrands()
  },
  computed: {
    filtered () {
      return this.models.filter(item => {
        return item.title.toLowerCase().includes(this.searchTerm)
      })
    }
  },
  methods: {
    getPageImage: async function () {
      const response = await axios.get('api/getTechnicImage.php?image=teknikKayit')
      this.image = response.data
    },
    sendForm: function () {
      const data = {
        user: this.user,
        selectedBrand: this.selectedBrand,
        selectedModel: this.selectedModel,
        malfunction: this.malfunction
      }
      // validate first
      if (!data.malfunction) {
        this.$toast.error('Cihazınızın sorunu giriniz')
        document.getElementsByName('malfunction')[0].focus()
        return
      }
      if (!data.user.userName) {
        this.$toast.error('Adınızı giriniz')
        document.getElementsByName('userName')[0].focus()
        return
      }
      if ((!data.user.email) || (!data.user.email.includes('@'))) {
        this.$toast.error('E-Posta adresinizi giriniz')
        document.getElementsByName('email')[0].focus()
        return
      }
      if ((!data.user.phone) || (data.user.phone.length < 10)) {
        this.$toast.error('Telefon numaranızı giriniz')
        document.getElementsByName('userPhone')[0].focus()
        return
      }
      if (!data.selectedBrand) {
        this.$toast.error('Marka seçiniz')
        return
      }
      if (!data.selectedModel) {
        this.$toast.error('Model seçiniz')
        return
      }
      this.isSaving = true
      this.savingForm = true
      axios.post('api/serviceRequest.php', data)
        .then(response => {
          console.log(response)
          setTimeout(() => {
            this.$toast.success('İşlem başarılı')
            this.isSaving = false
            // this.savingForm = false
          }, 200)
        })
        .catch(error => {
          console.log(error)
        })
    },
    clearBrand: function () {
      this.selectedBrand = null
      this.selectedModel = null
      this.models = []
      this.modelCount = 0
      this.searchTerm = ''
      document.getElementById('modelsContainer').style.height = '0px'
    },
    clearModel: function () {
      this.selectedModel = null
      let h = 0
      const he = (this.models.length) * 32
      if (he > 300) {
        h = 300
      } else {
        h = he
      }
      // document.getElementById('modelFilter').style.display = 'none'
      document.getElementById('modelsContainer').style.height = h + 'px'
    },
    reset: function () {
      this.brands = []
      this.models = []
      this.selectedBrand = null
      this.selectedModel = null
      this.getBrands()
    },
    getBrands: async function () {
      const payload = {
        user: this.$store.state.user,
        brandId: 95
      }
      await axios.post('/api/getBrands.php', payload).then(response => {
        this.brands = response.data.brands
        this.modelCount = 0
      })
    },
    getModels: async function (brandId, search) {
      this.selectedBrand = brandId
      const payload = {
        user: this.$store.state.user,
        brandId: this.selectedBrand.id,
        search: search
      }
      await axios.post('/api/getModelsForService.php', payload).then(response => {
        this.models = response.data.brands
        if (!search) {
          this.modelCount = response.data.count
        }
        let h = 0
        const he = (this.models.length) * 32
        if (he > 300) {
          h = 300
        } else {
          h = he
        }
        document.getElementById('modelsContainer').style.height = h + 'px'
      })
    },
    getContacts: async function (modelId) {
      this.selectedModel = modelId
      const payload = {
        user: this.$store.state.user,
        brandId: this.selectedBrand.id,
        modelId: this.selectedModel.id
      }
      document.getElementById('modelsContainer').style.height = '0px'
      console.log(payload)
    }
  }
}

</script>

<style scoped lang="scss">
.success {
  margin-top:20px;
  font-size: 50px;
  color: green;
}
p {
  margin-bottom:8px;
}
.inputRow {
  display: grid;
  margin-bottom: 12px;
  label {
    display:block;
    margin-bottom:3px;
    font-size: 12px;
  }
  input {
    width:100%;
    padding: 8px;
    border: 1px solid #ccc;
  }
}
.primary {
  background-color: var(--color1);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  transition: all .2s ease;
}
.primary:hover {
  background-color: var(--color2);
  color: #fff;
}
#modelFilter {
  width:100%; padding:12px;margin-bottom:12px;margin-top:4px;border:1px solid #ccc;border-radius:2px;font-size:16px;
}
.serviceRequest {
  h2 {
    font-size: 18px;
    font-family: var(--font3);
  }
}
h3 {
  font-size: 14px;
  font-family: var(--font3);
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.formContainer {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.selector {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  .column {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  ul {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.1s ease-out;
  }
  .collapsed {
    height: 0px;
    overflow: hidden;
  }
}
ul {
  list-style: none;
}
a {
  display: block;
  padding: 5px;
}
a:hover, .active {
  color:var(--color1);
  text-decoration: underline;
}
li {
  border-bottom:1px solid #e6e6e6;
}
@media screen and (max-width: 768px) {
  .selector {
    grid-template-columns: 1fr;
    .column {
      padding: 0;
      border:none;
    }
  }
  .formContainer {
    display:grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>
