<template>
  <div style="background:#fff;">
    <img loading="lazy" style="margin-bottom:12px" :alt="page.title" width="100%" v-if="page.src" :src="fileCdn + page.src">
    <div v-for="(item, idx) in page.blocks" :key="idx" class="pageBlock">
      <h1 v-if="item.type === 'header' && item.data.level === 1">{{ item.data.text }}</h1>
      <h2 v-if="item.type === 'header' && item.data.level === 2">{{ item.data.text }}</h2>
      <h3 v-if="item.type === 'header' && item.data.level === 3">{{ item.data.text }}</h3>
      <div v-if="item.type === 'paragraph'">
        <p v-html="item.data.text"></p>
      </div>
      <div v-if="item.type === 'list'">
        <ul v-if="item.data.style === 'unordered'">
          <li v-for="(li, idx) in item.data.items" :key="idx" v-html="li"></li>
        </ul>
        <ol v-if="item.data.style === 'ordered'">
          <li v-for="(li, idx) in item.data.items" :key="idx" v-html="li"></li>
        </ol>
      </div>
    </div>
    <SimpleForm v-if="page.form" :form="page.form" :cls="'pageBlock'" />
  </div>
</template>

<script>
import SimpleForm from '@/small-components/SimpleForm'
export default {
  props: ['page', 'hideTitle'],
  components: { SimpleForm },
  data: function () {
    return {
      fileCdn: this.$store.state.fileCdn
    }
  }
}
</script>

<style lang="scss">
.pageBlock {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: var(--font2);
  h1 {
    font-size: 18px;
  }
  h2 {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;
  }
  h3 {
    font-size: 15px;
  }
  p,li {
    font-size: 13px;
    line-height: 22px;
  }
  ul {
    padding-left:20px;
  }
}
</style>
