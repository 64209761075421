<template>
  <div class="container" style="min-height:700px;padding-top:10px">
    campaign details
    <p>{{ $route.params.id }}</p>
    <div class="list" style="min-height:370px">
      <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
      <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
      <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
      <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
      <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/small-components/ProductCard.vue'
import axios from 'axios'
export default {
  components: {
    ProductCard
  },
  data () {
    return {
      campaigns: [],
      products: [],
      mobileWidth: this.$store.state.mobileWidth,
      width: '320px',
      height: '320px'
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Nettech Bayi Kampanyaları')
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: this.$i18n.t('metaDescription') }]
    }
  },
  beforeMount () {
    this.width = this.$fnc.getWidth()
    this.getCampaigns()
  },
  methods: {
    getCampaigns: async function () {
      this.$fnc.pageView(this)
      const payload = {
        user: this.$store.state.user,
        id: this.$route.params.id
      }
      await axios.post('api/getCampaignDetails.php', payload).then(response => {
        this.campaigns = response.data.data
        this.products = response.data.products
      })
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2px;
  min-height: 1800px;
  min-height: 400px;
  grid-auto-rows: min-content;
}
</style>
