<template>
  <div>
    <form v-if="!isLoading" method="dialog" @submit.prevent="resetPassword">
      <div v-if="!sendingMail">
        <h1>Parolanızı Sıfırlayın</h1>
        <div>
          <label>E-Posta Adresiniz</label>
          <input type="email" v-model="resetEmail" placeholder="E-Posta Adresiniz">
        </div>
        <div>
          <router-link :to="{ name: 'Login' }"><i class="fa fa-user"></i> Giriş yapmak için tıklayın</router-link>
        </div>
        <div>
          <button type="button" @click="resetPassword"><i class="fa fa-lock"></i>Parolayı Sıfırla</button>
        </div>
      </div>
      <div v-else>
        Yükleniyor...
      </div>
    </form>
    <form v-else method="dialog" @submit.prevent="sendVerifyCode">
      <div v-if="!verifyCodeResult">
        <h1>E-Posta adresinize gönderilen doğrulama kodunu girin</h1>
        <div>
          <label>Doğrulama Kodu</label>
          <input type="text" v-model="verifyCode" placeholder="Doğrulama Kodu">
        </div>
        <div>
          <button type="button" @click="sendVerifyCode"><i class="fa fa-check"></i>Doğrula</button>
        </div>
      </div>
      <div v-else>
        <h1>Parolanızı sıfırlayın</h1>
        <div>
          <label>Yeni Parola</label>
          <input type="password" v-model="newPassword" placeholder="Yeni Parola">
        </div>
        <div>
          <label>Yeni Parola Tekrar</label>
          <input type="password" v-model="newPasswordRepeat" placeholder="Yeni Parola Tekrar">
        </div>
        <div>
          <button type="button" @click="resetPasswordConfirm"><i class="fa fa-check"></i>Parolayı Sıfırla</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default ({
  data () {
    return {
      resetEmail: '',
      verifyCode: '',
      isLoading: false,
      verifyCodeResult: false,
      newPassword: '',
      newPasswordRepeat: '',
      sendingMail: false
    }
  },
  methods: {
    resetPasswordConfirm: async function () {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.$toast.error('Parolalar eşleşmiyor')
        return
      }
      const payload = {
        verifyCode: this.verifyCode,
        user: this.$store.state.user,
        newPassword: this.newPassword,
        newPasswordRepeat: this.newPasswordRepeat,
        verifyCodeResult: this.verifyCodeResult
      }
      await axios.post('/api/saveChangePasswordForReset.php', payload).then(response => {
        const data = response.data
        const err = data.error
        if (err === 'none') {
          this.$toast.success('Parolanız başarıyla değiştirildi')
          this.$router.push({ name: 'Login' })
        } else {
          this.$toast.error(err)
        }
      })
    },
    sendVerifyCode: async function () {
      const payload = {
        verifyCode: this.verifyCode,
        user: this.$store.state.user
      }
      await axios.post('/api/verifyPasswordReset.php', payload).then(response => {
        console.log(response.data)
        const data = response.data
        const err = data.error
        if (err === 'none') {
          this.verifyCodeResult = data.res
          this.$toast.success('Doğrulama işlemi başarılı.<br>Şimdi yeni parolanızı belirleyebilirsiniz.')
        } else {
          this.verifyCodeResult = false
          this.$toast.error(err)
        }
      })
    },
    resetPassword: async function () {
      if (!this.resetEmail) {
        this.$toast.error('E-Posta adresinizi girin')
        return
      }
      this.sendingMail = true
      const payload = {
        email: this.resetEmail,
        user: this.$store.state.user
      }
      await axios.post('/api/resetPassword.php', payload).then(response => {
        const data = response.data
        const err = data.error
        if (err === 'none') {
          this.$toast.success('Parolanızı sıfırlamak için e-posta adresinize gönderilen doğrulama kodunu girin.')
          this.isLoading = true
        } else {
          this.$toast.error(err)
        }
        this.sendingMail = false
        // this.isLoading = false
      })
    }
  }
})
</script>
