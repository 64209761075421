<template>
  <transition name="modal-fade">
    <div class="modalBg" @click.self="close">
      <div class="modalG" :class="{ fullPage: fullPage }">
        <div class="modalContent">
          <div method="dialog" autocomplete="never">
            <h3>
              <slot name="title"></slot>
              <button :title="$t('Kapat')" @click="close" v-if="!hideClose" class="closeBtn">&times;</button>
            </h3>
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['hideClose', 'fullPage'],
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 12px;
  display:grid;
  grid-template-columns: auto 20px;
  align-items: center;
}
p {
  margin-bottom: 12px;
}
.closeBtn {
  border:none;
  background:#666;
  color:#fff;
  width:20px;
  height:20px;
  font-size:14px;
  font-weight: bold;
  display:grid;
  place-items:center;
  float:right;
  border-radius:50%;
  font-family: var(--font3);
}
.closeBtn:hover {
  background: var(--color1);
}

.modalBg {
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 2224 !important;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px);
  .modalG {
    position: unset;
    top: 0;
    border-radius: 12px;
    background:#fff;
    margin-left:10px;
    margin-right:10px;
    width: calc(100vw - 20px);
    max-width: 600px;
    .modalContent {
      background: unset;
      padding: 12px;
    }
  }
  .fullPage {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    .modalContent{
      padding: 0;
      height: 100%;
    }
  }
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transform:scale(1.2) translateY(3%);
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all .15s ease;
}
</style>
