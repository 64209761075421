<template>
  <div>
    <Header :info="info" />
    <div class="container">
      <BreadCrumb :info="[{ id:0, title: $t('Mağazalarımız') }]" />
      <div class="mb catalog">
        <div class="mapCard">
          <gmaps-map :options="options" style="height:240px; border:1px solid #ccc;border-radius:4px;border:1px solid #ccc">
            <gmaps-marker :position="{ lat: options.center.lat, lng: options.center.lng }" />
          </gmaps-map>
          <div class="infoContainer">
            <div v-for="store,index in $store.state.info.stores" :key="index" class="card" style="position:relative">
              <h3>{{ store.title }} </h3>
              <ul class="contact" style="padding-bottom:40px">
                <li><i class="fa fa-map-marker"></i><span v-html="store.address"></span></li>
                <li><i class="fa fa-phone-alt"></i>
                  <a :href="'tel:+9' + store.phoneUrl">{{ $fnc.phone(store.phone) }} <span v-if="store.phoneExt">Dahili: {{ store.phoneExt }}</span></a>
                </li>
                <li v-if="store.phone2"><i class="fa fa-phone-alt"></i>
                  <a :href="'tel:+9' + store.phone2Url">{{ $fnc.phone(store.phone2) }} <span v-if="store.phone2Ext">{{ store.phone2Title }}: {{ store.phone2Ext }}</span></a>
                </li>
                <li><i class="fa fa-envelope"></i> {{ store.mail }}</li>
                <li><i class="fa fa-calendar"></i><span v-html="store.working_hours"></span></li>
                <form action="https://maps.google.com/maps" style="display:none" method="get" target="_blank" :id="'directions' + index">
                  <input type="hidden" name="daddr" :value="store.mapAddress" />
                </form>
              </ul>
              <div style="position:absolute;bottom:8px;left:8px">
                <button @click="getDirection('directions' + index)" class="btn1"><i class="fa fa-directions"></i> {{ $t('Yol Tarifi Al') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.mapCard {
  display:grid; grid-template-columns:repeat(1,1fr); gap:20px; padding-bottom:20px; margin-bottom:20px; border-bottom:1px solid #ccc
}

.btn1 {
  width:100%;
  padding:9px;
  background:var(--color2);
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  transition: all 0.1s ease-in;
  border-radius: 4px;
  i {
    color:#fff !important;
    font-size:15px !important;
  }
}
.btn1:hover {
  filter: brightness(1.1);
}
.catalog {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 400px);
}
.card {
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  h3 {
    background: var(--color1);
    font-family: var(--font2);
    color: var(--color2);
    color: #fff;
    padding: 8px;
    font-weight: 600;
    font-size: 16px;
  }
}
.infoContainer {
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  gap:20px
}
.contact {
  list-style: none;
  display:flex;
  flex-direction: column;
  align-items: space-around;
  li {
    display: grid;
    grid-template-columns: 28px auto;
    align-items: center;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px;
    i {
      font-size:12px;
      text-align: center;
      color: #5c5c5c;
    }
  }
}
@media screen and (max-width:768px) {
  .mapCard {
    display:grid; grid-template-columns:repeat(1,1fr);
  }
  .infoContainer {
    grid-template-columns: 1fr;
  }
}
</style>

<script>
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
  components: { BreadCrumb, gmapsMap, gmapsMarker },
  props: ['info'],
  data () {
    return {
      company: this.info.companyInfo,
      options: {
        center: {
          lat: parseFloat(this.info.stores[0].coords.split(',')[0]),
          lng: parseFloat(this.info.stores[0].coords.split(',')[1])
        },
        zoom: 15,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: true,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false
      }
    }
  },
  methods: {
    getDirection: function (formId) {
      document.querySelector('#' + formId).submit()
    }
  },
  metaInfo () {
    const title = this.$i18n.t('İletişim')
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  }
}
</script>
