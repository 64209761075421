<template>
  <div>
    <div v-if="loading" class="loading">
      <img src="@/assets/loading-pulse.svg">
    </div>
    <div class="mh" v-else>
      <div v-if="body">
        <div v-html="body" style="margin-top:12px"></div>
        <button style="margin-top:12px" @click="body=''">Yeni sorgu yapmak için tıklayın</button>
      </div>
      <div v-else class="mh" style="margin-top:12px">
        <div v-if="error">
          <p>Girdiğiniz bilgiler hatalı</p>
          <button style="margin-top:12px" @click="error=false">Tekrar Deneyin</button>
        </div>
        <div v-else>
          <p>Servisteki cihazınızın durumunu form numarası ya da cep telefonu numarası ile sorgulayabilirsiniz.</p>
          <form class="form" @submit="getDeviceStatus()">
            <div class="inputRow">
              <label>Telefon Numarası</label>
              <input v-model="phoneNumber" placeholder="Telefon Numarası" type="text" />
            </div>
            <p style="color:#555;font-size:12px">veya</p>
            <div class="inputRow">
              <label>Form Numarası</label>
              <input v-model="formNumber" placeholder="Form Numarası" type="text" />
            </div>
            <button>Sorgula</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mh {
  padding-bottom: 20px;
  min-height: 500px;
  .inputRow {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    input {
      padding: 8px;
      border: 1px solid var(--color1);
      border-radius: 2px;
    }
  }
}
.form {
  padding: 20px;
  padding-top: 10px;
  border: 1px solid #ccc;
  background:#fff;
  margin-top: 12px;
  border-radius: 4px;
}
button {
  // primary button
  border: none;
  background: var(--color1);
  color: white;
  padding: 12px;
  border-radius: 4px;
}
.loading {
  display:grid; place-items:center;
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      id: this.$route.params.id,
      formNumber: '',
      phoneNumber: '',
      body: '',
      error: false,
      loading: true
    }
  },
  metaInfo () {
    return {
      title: 'Teknik Servis Sorgu',
      meta: [{ vmid: 'description', name: 'description', content: 'Teknik Servis Sorgu' }]
    }
  },
  mounted () {
    if (this.id) {
      this.getDeviceStatus()
    } else {
      this.loading = false
    }
  },
  methods: {
    getDeviceStatus: async function () {
      this.loading = true
      await this.$http.get('/api/netsoft/getServiceStatus.php?id=' + this.id + '&formNumber=' + this.formNumber + '&phone=' + this.phoneNumber).then(response => {
        if (response.data === 'error') {
          this.error = true
        } else {
          this.body = response.data
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    }
  }
}
</script>
