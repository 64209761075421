<template>
  <router-link :to="{ name: 'Product', params: {pid: p.p.urunlerId + '-' + $fnc.seo(p.p.urunlerAdi) } } " class="product" v-if="p">
    <div class="imageContainer">
      <div class="discountRateBox" v-if="p.listPrice > p.price" style=" margin-top:-15px;position: absolute;display:flex;align-items:center;gap:4px;z-index:12;margin-left:4px;background:green; color:white;padding:4px;padding-left:8px;padding-right:8px;border-radius:4px;margin-right:4px;font-weight:bold;">
        %{{ Math.round(((p.listPrice - p.price) / p.listPrice) * 100) }} <span style="font-size:11px">indirim</span>
      </div>
      <div v-if="p.images.length === 0"></div>
      <div v-else-if="p.images.length > 1">
        <div v-if="hoverSlide" style="position:relative;">
          <img loading="lazy" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + p.images[index].thumb" v-if="p.images[index].thumb">
          <img loading="lazy" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + p.images[index].source" v-else>
        </div>
        <div style="width:230px;display:flex;position:absolute;height:240px;margin-top:-240px;" v-if="hoverSlide">
          <div @mouseover="selectSlide(idx)" v-for="(i, idx) in p.images" :key="idx" style="width:100%"><i class="fa fa-square"></i></div>
        </div>
        <splide :options="options" v-if="!hoverSlide">
          <splide-slide v-for="image in p.images" :key="image.source">
            <img loading="lazy" :height="height" :width="width" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + image.thumb" v-if="image.thumb">
            <img loading="lazy" :height="height" :width="width" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + image.source" v-else>
          </splide-slide>
        </splide>
      </div>
      <div v-else>
        <img loading="lazy" style="user-drag: none;" :height="height" :width="width" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + p.images[0].thumb" v-if="p.images[0].thumb">
        <img loading="lazy" style="user-drag: none;" :height="height" :width="width" :alt="p.p.urunlerAdi" :src="'https://nettechservis.com/storage/media/' + p.images[0].source" v-else>
      </div>
    </div>
    <div class="text">
      <p class="title">{{ p.p.urunlerAdi }}</p>
      <div style="display:grid;grid-template-columns:75px auto; justify-items:left;align-items:center">
        <star-rating :title="p.rating.average + ' / 5 (' + p.rating.rateCount + ' değerlendirme)' "  :round-start-rating="false" v-model="p.rating.average" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
        <span v-if="p.rating.rateCount" style="font-size:11px;color:#999">({{ p.rating.rateCount }})</span>
      </div>
      <p v-if="p.price > 0" class="priceContainer">
        <span v-if="$store.state.priceWithVat">
          {{ parseFloat(p.price * (1 + (p.p.urunlerKdv / 100))).toFixed(2) }}
        </span>
        <span v-else>
          <span v-if="p.listPrice > p.price" style="display:flex; place-items:center">
            <span style="text-decoration:line-through;font-size:15px;margin-right:8px;">{{ parseFloat(p.listPrice).toFixed(2) }}{{ p.cur }} </span>
            <span style="color:green;">{{ parseFloat(p.price).toFixed(2) }}{{ p.cur }} </span>
          </span>
          <span v-else>{{ parseFloat(p.price).toFixed(2) }}{{ p.cur }}</span>
        </span>
      </p>
    </div>
    <div style="text-align:center;cursor:default;padding:5px;" @click.self="prevent">
      <div v-if="p.p.stock > 0 && p.price > 0">
        <input @click.self="prevent" type="text" value="1" style="display:none;width:30px;text-align:center;margin-right:12px;width:32px;height:22px;">
        <button class="cartBtn" @click.self="addToCart(p.p)">
          <i @click="prevent" style="margin-right:4px;" class="fa fa-shopping-cart"></i>
          Sepete Ekle</button>
      </div>
      <div v-else>
        <button class="cartBtn" @click.self="prevent" style="color:#ccc;border-color:#ccc">
          <i @click="prevent" style="margin-right:4px;" class="fa fa-ban"></i>
          Stokta Yok</button>
      </div>
      <div style="color:#666;font-size:12px;margin-top:8px;" v-if="$store.state.user.customerGroup === 26">
        <router-link v-if="avantaj" :to="{ name: 'PageGroup', params: { parent: 'nettech-bayilik', id: '' } }">Avantajlı fiyatlar için<br> <b>Nettech Bayisi Olun</b></router-link>
      </div>
      <div v-else style="font-size:11px; color:#666;margin-top:12px">
        Stokta: <b>{{ p.p.stock }}</b> adet
      </div>
    </div>
  </router-link>
</template>

<script>
import axios from 'axios'
export default {
  props: ['p', 'prices', 'width', 'height'],
  methods: {
    selectSlide: function (idx) {
      this.index = idx
    },
    prevent: function () {
      event.preventDefault()
    },
    addToCart: async function (p) {
      event.preventDefault()
      const qty = 1
      this.isSaving = true
      const payload = {
        pid: parseInt(p.urunlerId),
        bid: 0,
        qty: parseInt(qty),
        productType: 'normal',
        user: this.$store.state.user
      }
      await axios.post('api/addToCart.php', payload).then(response => {
        if (response.data.error === 'none') {
          setTimeout(() => {
            this.$store.dispatch('setCart', response.data.cartCount)
            this.isSaving = false
            /* setTimeout(() => {
              this.$parent.itemDetails()
            }, 250) */
          }, 500)
          const items = [{
            id: 'p' + p.urunlerId + 'b' + 0,
            name: p.urunlerAdi.trim(),
            brand: p.urunlerMarka,
            category: p.urunlerKategori,
            variant: '',
            quantity: qty,
            price: p.urunlerFiyat1
          }]
          this.$gtag.event('add_to_cart', { items })
          this.$toast.success('Sepetinize Eklendi, sepetinize gitmek için buraya tıklayın', {
            position: 'bottom',
            onClick: () => {
              this.goCart()
            }
          })
        } else {
          this.$toast.error(response.data.error)
        }
      })
    },
    goCart: function () {
      this.$router.push({ name: 'Cart' })
    }
  },
  data () {
    return {
      hoverSlide: false,
      index: 0,
      avantaj: false,
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: false,
        arrows: false,
        pagination: true,
        lazyLoad: true,
        autoplay: false,
        type: 'loop',
        keyboard: false
      }
    }
  }
}
</script>
<style lang="scss">
.cartBtn {
  height:32px;
  border:none;
  background:var(--color1);
  background:#fff;
  border:1px solid var(--color1);
  color:var(--color1);
  border-radius:2px;
  width:140px;
  font-size:15px;
  font-weight:500;
  font-family: var(--font3);
}
.cartBtn:hover {
  filter: brightness(1.4);
}
.imageContainer {
  .splide__pagination{
    bottom: -15px;
  }
  .splide__pagination__page {
    background:#555;
    transform:scale(0.7);
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .splide__pagination__page.is-active {
    background:var(--color1);
    transform:scale(1);
  }
}
</style>
<style scoped lang="scss">
.imageContainer {
  // border: 1px solid red;
  padding-top: 30px;
  padding-bottom: 30px;
}
a {
  color: black;
}
.product {
  cursor: pointer;
  width: 100%;
  text-align: center;
  background:#fff;
  padding-bottom:12px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  // box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  transition: all 0.2s;
  .text {
    padding:8px;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
  }
  .title {
    text-align: left;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    height:32px;
    overflow: hidden;
  }
  .specs {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-top: 8px;
  }
  .priceContainer {
    font-weight: normal;
    text-align: left;
    margin-top: 6px;
    font-size: 11px;
    color:#999;
    display: flex;
    place-items: center;
    span {
      color: var(--color1);
      font-size: 15px;
      font-weight: bold;
    }
  }
}
.product:hover {
  // color: var(--color1);
  box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.18);
  z-index: 1;
}
@media screen and (max-width: 420px) {
  .product {
    border:none;
  }
  .product:hover {
    color: var(--color1);
    box-shadow: none;
  }
}
</style>
