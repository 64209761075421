<template>
  <div v-if="item">
    <router-link :to="item.url" class="banner">
      <img :alt="item.title" :title="item.title" :src="fileCdn + item.image" loading="lazy" />
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.banner {
  width: 100%;
  place-items: center;
  img {
    width: 100%;
    border-radius: 4px;
  }
}
</style>

<script>
export default {
  props: ['item'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn + '/'
    }
  }
}
</script>
