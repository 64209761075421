<template>
  <div>
    <div class="modal" v-if="showModal">
      <div class="modalContent">
        <div method="dialog" autocomplete="never" class="adres">
          <div style="overflow: hidden; height: 0px;background: transparent;" data-description="dummyPanel for Chrome auto-fill issue">
            <input type="text" style="height:0;background: transparent; color: transparent;border: none;" data-description="dummyUsername">
            <input type="password" style="height:0;background: transparent; color: transparent;border: none;" data-description="dummyPassword">
          </div>
          <h3>{{ $t('Adres Bilgileri') }} <button :title="$t('Kapat')" @click="$parent.showAddressModal" class="closeBtn">&times;</button></h3>
          <div>
            <div class="formRow">
              <div><label>{{ $t('Fatura Türü') }}</label></div>
              <div>
                <select name="companyTip" v-model.number="address.companyTip">
                  <option value="1">{{ $t('Bireysel') }}</option>
                  <option value="2">{{ $t('Kurumsal') }}</option>
                </select>
              </div>
            </div>
            <div class="formRow">
              <div><label>{{ $t('Ad Soyad') }}</label></div>
              <div><input name="ad" v-model.trim="address.ad" autocomplete="off" type="text" :placeholder="$t('Ad Soyad')"></div>
            </div>
            <div v-if="address.companyTip === 2" class="formRow">
              <div><label>{{ $t('Firma Ünvanı') }}</label></div>
              <div><input name="unvan" v-model.trim="address.unvan" autocomplete="off" type="text" :placeholder="$t('Firma Ünvanı')"></div>
            </div>
            <div style="display:grid;grid-template-columns:1fr 1fr;gap:12px" v-if="address.companyTip === 2">
              <div class="formRow">
                <div><label>{{ $t('Vergi Dairesi') }}</label></div>
                <div><input name="vd" v-model.trim="address.vd" autocomplete="off" type="text" :placeholder="$t('Vergi Dairesi')"></div>
              </div>
              <div class="formRow">
                <div><label>{{ $t('Vergi No') }}</label></div>
                <div><input name="vn" v-model="address.vn" maxlength="10" size="10" autocomplete="off" type="tel" :placeholder="$t('Vergi No')"></div>
              </div>
            </div>
            <div style="display:grid;grid-template-columns:1fr 1fr;gap:12px">
              <div class="formRow">
                <div><label>{{ $t('İl') }}</label></div>
                <div>
                  <select name="il" v-model="address.il" @change="getDistricts(true)" autocomplete="off">
                    <option value="">{{ $t('Lütfen Seçin') }}</option>
                    <option v-for="item in cities" :key="item.id" :value="item.name">{{ item.name }}</option>
                  </select>
                </div>
              </div>
              <div class="formRow">
                <div><label>{{ $t('İlçe') }}</label></div>
                <div>
                  <div v-if="address.il">
                    <select v-if="districts.length > 0" name="ilce" v-model="address.ilce" autocomplete="off">
                      <option value="">{{ $t('Lütfen Seçin') }}</option>
                      <option v-for="item in districts" :key="item.id" :value="item.name">{{ item.name }}</option>
                    </select>
                    <select v-else name="ilce" autocomplete="off" v-model="address.ilce">
                      <option value="">{{ $t('Lütfen Seçin') }}</option>
                      <option :value="address.ilce" v-if="address.ilce">{{ address.ilce }}</option>
                    </select>
                  </div>
                  <div v-else>
                    <select name="ilce" autocomplete="off" v-model="address.ilce">
                      <option value="">{{ $t('Lütfen Seçin') }}</option>
                      <option :value="address.ilce" v-if="address.ilce">{{ address.ilce }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="formRow">
              <div><label>{{ $t('Adres') }}</label></div>
              <div><textarea name="adres" v-model.trim="address.adres" autocomplete="never" type="text" :placeholder="$t('Adres')"></textarea></div>
            </div>
            <div class="formRow">
              <div><label>{{ $t('Telefon (Başında 0 olmadan 10 haneli girin)') }}</label></div>
              <div><input name="tel" v-model.number="address.tel" maxlength="10" size="10" autocomplete="never" type="tel" :placeholder="$t('Telefon (Başında 0 olmadan 10 haneli girin)')" ></div>
            </div>
            <div v-if="address.companyTip === 1">
              <div class="formRow">
                <div><label>{{ $t('T.C. Kimlik No (Fatura kesebilmemiz için gereklidir)') }}</label></div>
                <div><input name="tc" v-model.number="address.tc" autocomplete="off" type="tel" maxlength="11" size="11" :placeholder="$t('T.C. Kimlik No (Fatura kesebilmemiz için gereklidir)')"></div>
              </div>
            </div>
            <div class="formRow2" v-if="address.adresTip === 'FATURA'">
              <div>
                <input type="checkbox" v-model="address.sameForDelivery" id="addressToggler"><label for="addressToggler">{{ $t('Teslimat Adresimle Aynı') }}</label>
              </div>
            </div>
            <div class="formRow2">
              <div></div>
              <button v-if="!isSaving" @click="$parent.saveAddress" class="completeBtn b1"><i class="fa fa-save"></i>{{ $t('Adresi Kaydet') }}</button>
              <button v-else class="completeBtn b1"><span class="loading-spinner-white"></span>{{ $t('Adresi Kaydet') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modalBg" @click="$parent.showAddressModal" v-if="showModal"></div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['showModal', 'cities', 'address', 'isSaving'],
  data () {
    return {
      districts: []
    }
  },
  beforeMount () {
    if (this.address.il !== '') {
      this.getDistricts(false)
    }
  },
  watch: {
    showModal: function () {
      this.getDistricts(false)
    }
  },
  methods: {
    getDistricts: async function (reset) {
      this.districts = []
      if (reset) {
        this.$parent.address.ilce = ''
      }
      const payload = {
        city: this.address.il
      }
      await axios.post('/api/getDistricts.php', payload).then(response => {
        const data = response.data
        this.districts = data.data
      })
    },
    toggleAddress: function () {
      console.log('yeag bebe')
    }
  }
}
</script>

<style scoped lang="scss">
.b1 {
  display:flex;place-items:center;gap:5px;justify-content:center;
  span, i {
    width:24px;height:14px;
  }
}
.loading-spinner-white {
  background:url(~@/assets/loading-spinner-white.svg) no-repeat center;
  background-size: cover;
}
label {
  user-select: none;
}
.closeBtn {
  border:none;
  background:#666;
  color:#fff;
  width:20px;
  height:20px;
  font-size:14px;
  font-weight: bold;
  display:grid;
  place-items:center;
  float:right;
  border-radius:50%;
  font-family: var(--font3);
}
.closeBtn:hover {
  background: var(--color1);
}
.modalBg {
  position: absolute;
  width:100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  backdrop-filter: blur(20px);
  z-index: 3;
  top:0;
  left:0;
}
.modal {
  position: absolute;
  width:375px;
  height:660px;
  overflow: auto;
  top:30px;
  left:calc((100vw - 375px) / 2);
  justify-content: center;
  background: none;
  z-index: 4;
  box-shadow: none;
  padding-top:30px;
  .modalContent {
    width: auto;
    position: absolute;
    z-index: 1;
    .adres {
      background: #fff;
      padding:22px;
      margin: 4px;
      border:none;
      border-radius: 8px;
      margin:auto;
      margin-top:80px;
      width:375px;
      margin:auto;
      z-index: 1;
    }
  }
}
@media screen and (max-width:768px) {
  .modal {
    top:0;
    left:0;
    width: 100vw;
  }
  .modal .modalContent .adres {
    border-radius: 0px;
    width: 100vw;
  }
  .modalBg {
    height: 150vh;
  }
}
</style>
