<template>
  <div class="block">
    <div>
      <div class="tabs">
        <i class="fa fa-credit-card"></i>
        <button class="activeTab">{{ $t('Ödeme Bilgileri') }}</button>
      </div>
    </div>
    <div v-if="!$parent.isLoading">
      <div v-if="$parent.user.userId && $parent.invoiceAddress.adresId">
        <div v-if="$parent.invoiceAddress.sameForDelivery || $parent.deliveryAddress.adresId">
          <div class="formRow" style="display:flex; gap:12px;" v-if="paymentOptions.length > 1">
            <button :class="{ 'active': selectedPayment.id === item.id}" @click="$parent.selectPayment(idx)" v-for="(item, idx) in paymentOptions" :key="idx">{{ $t(item.title) }}</button>
          </div>
          <div class="paymentForm">
            <div v-if="selectedPayment.paymentType === 1">
              <form id="paymentForm" target="cc-start" :action="$store.state.info.base + 'cc-start.php'" method="post" @submit.prevent="processPayment">
                <div class="fields"></div>
                <div class="ccForm">
                  <div class="formRow">
                    <div><label>{{ $t('Kart üzerindeki isim') }}</label></div>
                    <div><input v-model="cc.ccName" autofocus="autofocus" autocomplete="cc-name" name="cc-name" required type="text" :placeholder="$t('Kart üzerindeki isim')"></div>
                  </div>
                  <div class="formRow">
                    <div><label>{{ $t('Kart Numarası') }}</label></div>
                    <div><input @keypress="iyzicoBin" @blur="iyzicoBin" v-model="cc.ccNumber" autocomplete="cc-number" name="cc-number" type="number" required :placeholder="$t('Kart Numarası')"></div>
                  </div>
                  <div style="display: grid; grid-template-columns: auto 90px; gap: 12px;">
                    <div class="formRow">
                      <div><label>{{ $t('Son Kullanma Tarihi') }}</label></div>
                      <div style="display:grid; grid-template-columns:1fr 1fr; gap:12px;">
                        <div><select v-model="cc.ccMonth" autocomplete="cc-exp-month" name="cc-exp-month" required><option value="0">{{ $t('AY') }}</option><option v-for="item in months" :key="item">{{ item }}</option></select></div>
                        <div><select v-model="cc.ccYear" autocomplete="cc-exp-year" name="cc-exp-year" required><option value="0">{{ $t('YIL') }}</option><option v-for="item in years" :key="item">{{ item }}</option></select></div>
                      </div>
                    </div>
                    <div class="formRow">
                      <div><label>{{ $t('Güvenlik Kodu') }}</label></div>
                      <div><input v-model="cc.ccCvv" autocomplete="cc-csc" name="cc-csc" required type="number" placeholder="CVV"></div>
                    </div>
                  </div>
                  <div>
                    <div class="formRow" v-if="selectedPayment.instalments">
                      <div><label>{{ $t('Taksit Seçenekleri') }}</label></div>
                      <div class="radioGroup">
                        <label :class="{ 'activeRadio': $parent.instalment === item.instalment }" :value="item.instalment"
                        v-for="item in selectedPayment.instalments.installments" :key="item.id">
                          <input name="instalment" :value="item.instalment" v-model="$parent.instalment" type="radio">
                          <span v-if="item.instalment === 1">{{ item.name }} ( {{ item.monthly.toFixed(2) }}₺ )</span>
                          <span v-else>{{ item.name }}
                            (
                              {{ item.monthly.toFixed(2) }}₺ x {{ item.instalment }} Ay
                            = {{ item.paidPrice.toFixed(2) }}₺
                            )
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="formRow2" v-if="selectedPayment.paymentType === 1">
                    <div>
                      <input type="checkbox" id="tds" checked disabled><label for="tds">{{ $t('3D Secure ile güvenli öde') }}</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="selectedPayment.paymentType === 2">
              <form id="paymentForm" :action="$store.state.info.base + 'process-payment.php'" method="post" @submit.prevent="processPayment">
                <div class="fields"></div>
                <p style="margin-top:12px;margin-bottom:12px;padding:5px;">Mevcut bakiyeniz üzerinden ödeme yapabilirsiniz</p>
              </form>
            </div>
            <div v-if="selectedPayment.paymentType === 3">
              <form id="paymentForm" :action="$store.state.info.base + 'process-payment.php'" method="post" @submit.prevent="processPayment">
                <div class="fields"></div>
                <p style="margin-top:12px;margin-bottom:12px;padding:5px;">Bu ödeme yönteminde kargo şirketi tarafından <b>{{ selectedPayment.service_fee.toFixed(2) }} ₺</b> hizmet bedeli alınmaktadır.</p>
              </form>
            </div>
          </div>
          <div class="formRow2" v-if="$fnc.getWidth() > 768">
            <div :class="{ 'error': $parent.contractError}">
              <input type="checkbox" v-model="$parent.contracts" id="contracts"><label for="contracts">{{ $t('Mesafeli Satış Sözleşmesini ve Ön Bilgilendirme Formunu Onaylıyorum') }}</label>
            </div>
          </div>
          <div class="formRow2" v-else>
            <div :class="{ 'error': $parent.contractError}">
              <input type="checkbox" v-model="$parent.contracts">
                <span v-if="$i18n.locale === 'tr'"><a @click="showContract(0)">Satış Sözleşmesini</a> ve <a @click="showContract(1)">Ön Bilgilendirme Formunu</a> Onaylıyorum</span>
                <span v-else-if="$i18n.locale === 'en'">I accept <a @click="showContract(0)">{{ $t('saleContract') }}</a> and <a @click="showContract(1)">{{ $t('saleContract2') }}</a></span>
                <span v-else-if="$i18n.locale === 'de'">I accept <a @click="showContract(0)">Distance Sales Contract</a> and <a @click="showContract(1)">Preliminary Information Form</a></span>
            </div>
          </div>
          <div class="formRow" v-if="$store.state.user.userId !== 6129">
            <button type="button" v-if="!$parent.isSavingCart" @click="processPayment" class="btn1 bb"><i class="fa fa-lock" :class="{ 'bounce': $parent.contractError }"></i> {{ $t('Ödeme İşlemini Onayla') }}</button>
            <button v-else class="btn1 bb" style="cursor:default"><span class="loading-spinner-white"></span> {{ $t('Ödeme İşlemini Onayla') }}</button>
          </div>
          <div class="formRow" v-else>
            <button class="btn1 bb" style="background:orangered">Demo hesapta alışveriş yapamazsınız</button>
          </div>
          <div v-if="$fnc.getWidth() > 768">
            <div v-for="item in $store.state.info.contracts" :key="item.id" class="pageContainer">
              <JsonPage style="padding:12px;" :page="item" />
            </div>
          </div>
        </div>
        <div v-else><i style="font-size:11px;"> {{ $t('Önce teslimat adresinizi girmelisiniz') }}</i></div>
      </div>
    </div>
    <div v-if="popup" class="modal">
      <JsonPage style="padding:12px" :page="contract" />
    </div>
    <div v-if="popup" class="modalBg" @click="hideContract"></div>
    <GlassModal v-if="ccError" @close="closeModal">
      <template v-slot:title>Hatalar var!</template>
      <template v-slot:body>
        <p style="margin-bottom:12px;color:red;font-weight:bold">Lütfen önce formdaki hataları düzeltin</p>
        <div style="font-size:14px;margin-bottom:8px" v-for="item in ccErrors" :key="item" v-html="item"></div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
import JsonPage from '@/components/JsonPage'
export default {
  components: { JsonPage },
  props: ['paymentOptions', 'selectedPayment'],
  data: function () {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear; i < currentYear + 12; i++) {
      years.push(i)
    }
    return {
      contract: null,
      popup: false,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: years,
      ccError: false,
      ccErrors: [],
      cc: {
        ccName: '',
        ccNumber: '',
        ccMonth: '0',
        ccYear: '0',
        ccCvv: ''
      }
    }
  },
  mounted: function () {
    this.iyzicoBin()
    setTimeout(() => {
      this.cc.ccNumber = ''
    }, 10)
  },
  methods: {
    showContract: function (id) {
      this.contract = this.$store.state.info.contracts[id]
      this.popup = true
    },
    hideContract: function () {
      this.popup = false
      this.contract = null
    },
    processPayment: function () {
      const errors = []
      const paymentType = this.selectedPayment.paymentType
      if (paymentType === 1) {
        if (!this.cc.ccName) { errors.push('İsim girmek zorundasınız') }
        if (!this.cc.ccNumber) { errors.push('Kart No girmek zorundasınız') }
        if (this.cc.ccMonth === '0') { errors.push('Son kullanma tarihi <b>Ay</b> seçmek zorundasınız') }
        if (this.cc.ccYear === '0') { errors.push('Son kullanma tarihi <b>YIL</b> seçmek zorundasınız') }
      }
      if (errors.length > 0) {
        this.ccError = true
        this.ccErrors = errors
      } else {
        this.$parent.processPayment()
      }
    },
    closeModal: function () {
      this.ccError = false
      this.ccErrors = []
    },
    iyzicoBin: async function () {
      if (this.cc.ccNumber.length > 5) {
        const payload = {
          bin: this.cc.ccNumber,
          // price: (this.$parent.$parent.summary.totalPrice + this.$parent.$parent.summary.shipment * this.$parent.summary.kur)
          price: (this.$parent.$parent.summary.payAmount)
        }
        await axios.get('/iyzico-bin.php', { params: payload }).then(response => {
          const data = response.data
          const stat = data.status
          if (stat === 'success') {
            console.log(data.installments)
            this.$parent.$parent.selectedPayment.instalments.installments = data.installments
          } else {
            this.$parent.$parent.selectedPayment.instalments.installments = null
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.radioGroup {
  label {
    display:grid; grid-template-columns:24px auto;
    height: 32px;
    align-items: center;
    padding:2px;
    border: 2px dashed #e2e2e2;
    margin-bottom: 8px;
    span {
      font-size:13px;
    }
    input:focus{
      border:none;
      box-shadow: none;
    }
  }
  .activeRadio {
    background:#f6feff;
    border: 2px solid #b4e9f0;
  }
}
.pageContainer {
  height:180px;
  margin-top:12px;overflow:auto;border:1px solid #ccc; margin-bottom:20px;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-5px);}
  60% {transform: translateY(-2px);}
}
.bounce {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounce;
}
.modal {
  position: fixed;
  top:0;
  left: 0;
  width:100vw;
  height: calc(100vh - 120px);
  z-index: 120;
  overflow: auto;
}
.modalBg {
    background: rgba(0,0,0,0.4);
    top:0;
    left: 0;
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
  }
iframe {
  width:100%;border:1px solid #ccc;margin-bottom:12px;
}
label {
  user-select: none;
}
.loading-spinner-white {
  background:url(~@/assets/loading-spinner-white.svg) no-repeat center;
  background-size: cover;
}
.checker {
  font-size:13px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.tabs {
  display:flex;
  margin-bottom: 8px;
  i {
    width:24px;
    display: none;
  }
  button {
    border:none;
    background:none;
    border-bottom: 3px solid white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    transition: all 0.1s ease-in;
    cursor: default;
  }
  .activeTab {
    border-bottom: 3px solid var(--color1);
  }
}
.formError {
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
}
.formRow2 {
  div {
    padding: 4px;
    border:2px solid #fff;
    span {
      font-size: 13px !important;
      a {
        color:blue;
        text-decoration: underline;
      }
    }
  }
  .error {
    background:#ffe9e9;
    border-radius: 6px;
    border:2px dashed #f79595;
  }
}

.formRow {
  .btn1 {
    padding:10px;
    border-radius: 5px;
    border:none;
    background: var(--color1);
    background: green;
    color: white;
    font-weight: normal;
    font-family: var(--font3);
    font-size: 14px;
    letter-spacing: 1px;
    width: 220px;
    display:flex;place-items:center;gap:5px;justify-content:center;
    span, i {
      width:24px;height:14px;
    }
  }
  .passive {
    filter: brightness(1.4);
  }
  .btn1:hover {
    border:none;
    filter: brightness(1.15);
  }
}
@media screen and (max-width:768px) {
  .formRow .btn1 {
    width: 100%;
  }
}
</style>
