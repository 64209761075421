<template>
  <div>
    <div class="container" style="min-height:650px" v-if="!isLoading && !error">
      <BreadCrumb :info="breadCrumb" v-if="showBc" />
      <Bcjsonld :jsonld = "jsonld" />
      <div class="pContainer">
        <div class="imageContainer" v-if="$store.state.width > 768">
          <div>
            <button @click="selectImage(image)" :class="{ activeTmb: image.id === activeImage.id }" class="tmb" v-for="image in product.images" :key="image.id" >
              <img :alt="product.title" :src="mediaCdn + '/' + image.thumb" width="100%">
            </button>
          </div>
          <div>
            <pinch-zoom :backgroundColor="'rgb(255,255,255)'" :wheel="false" style="margin-bottom:12px;border-radius:4px">
              <img :alt="product.title" :src="mediaCdn + '/' + activeImage.source" width="100%">
            </pinch-zoom>
          </div>
        </div>
        <div v-else>
          <div>
            <pinch-zoom :backgroundColor="'rgb(255,255,255)'" :wheel="false" style="margin-bottom:12px;border-radius:4px">
              <img :alt="product.title" :src="mediaCdn + '/' + activeImage.source" width="100%">
            </pinch-zoom>
          </div>
          <div style="display:flex; gap: 10px">
            <button @click="selectImage(image)" :class="{ activeTmb: image.id === activeImage.id }" class="tmb" v-for="image in product.images" :key="image.id" >
              <img :alt="product.title" :src="mediaCdn + '/' + image.thumb" width="100%">
            </button>
          </div>
        </div>
        <div>
          <div style="position:sticky; top:0px">
            <NormalProduct2 :product="product" v-if="productType === 'normal'" />
          </div>
        </div>
      </div>
      <Collection v-if="product.relatedProducts" :item="product.relatedProducts" />
      <div id="tabs" class="tabs" v-if="tabs">
        <div class="tabNavContainer">
          <div class="tabNav" style="min-width:300px">
            <button
            @click="selectTab(item)"
            v-for="item in tabs" :key="item.id" :class="{ 'tabActive': selectedTab.id === item.id }">{{ item.title }}</button>
          </div>
        </div>
        <div class="tabContent">
          <div v-for="item in tabs" :key="item.id">
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-if="item.type === 'comments'">
              <div class="reviewSummary">
                <h1 style="font-size:15px">{{ product.title }} Yorumları</h1>
                <button @click="makeReview" style="min-width:90px; height: 30px;" class="btn2">Değerlendir</button>
              </div>
              <ProductReview :product="product" />
              <div v-if="reviews.length > 0">
                <div v-for="item in reviews" :key="item.id" class="comment-row">
                  <div class="avatar">{{ item.user_name.split('')[0] }}</div>
                  <div class="comment-bubble">
                    <div class="person">
                      <span>{{ item.user_name }}</span>
                      <span style="font-size:11px;text-align:right;color:#666">{{ $fnc.tarih(item.created_at) }}</span>
                    </div>
                    <star-rating :round-start-rating="false" v-model="item.rating" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
                    <div v-if="item.comment" class="comment" v-html="$fnc.nl2br(item.comment)"></div>
                  </div>
                </div>
              </div>
              <div v-else>Şu anda bu ürüne hiç yorum yapılmamış</div>
            </div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'taksit'">Taksit Seçenekleri</div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else-if="item.type === 'jsonPage'">
              <JsonPageNormal :page="item.content" />
            </div>
            <div class="tabHtml hidden" :class="{ 'visible': selectedTab.id === item.id }" v-else>
              <div style="margin-bottom:12px;padding:8px; display:nsone" class="specs" v-if="product.body">
                <div v-html="product.body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="min-height:calc(100vh - 100px); display:grid;place-items:center">
      <img src="~@/assets/loading-pulse.svg">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import ProductImages from '@/small-components/ProductImages.vue'
import PinchZoom from 'vue-pinch-zoom'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import NormalProduct2 from '@/components/NormalProduct2.vue'
// import ProductSpecs from '@/small-components/ProductSpecs.vue'
import Collection from '@/home-components/Collection.vue'
import Bcjsonld from '@/small-components/Bcjsonld'
import ProductReview from '@/components/ProductReview.vue'

export default {
  components: { BreadCrumb, NormalProduct2, Collection, Bcjsonld, PinchZoom, ProductReview },
  metaInfo () {
    return {
      title: this.product.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.product.title + ' fiyatı: ' + this.product.price + ' TL, satın alın, toptan satışı, toptan satın alın' }
      ]
    }
  },
  data: function () {
    return {
      pid: this.$route.params.pid,
      mediaCdn: this.$store.state.mediaCdn,
      isLoading: false,
      isSaving: false,
      productType: '',
      product: '',
      variants: [],
      variantGroup: null,
      productPrice: 0,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: null,
      price: 0,
      totalPrice: 0,
      breadCrumb: [],
      jsonld: null,
      error: null,
      showBc: true,
      activeImage: false,
      rating: [],
      reviews: [],
      tabs: null,
      showReviewModal: false,
      selectedTab: {
        id: 1,
        title: ''
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('setBottomHide', false)
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      const hash = to.hash
      const fromHash = from.hash
      if ((!hash) && (!fromHash)) {
        this.pid = to.params.pid
        this.itemDetails()
      }
    }
  },
  methods: {
    makeReview: function () {
      this.showReviewModal = true
    },
    selectTab: function (item) {
      this.selectedTab = item
    },
    selectImage: function (image) {
      this.activeImage = image
    },
    itemDetails: async function () {
      // this.$store.dispatch('setBottomHide', true)
      this.isLoading = true
      const payload = {
        pid: this.pid,
        user: this.$store.state.user
      }
      await axios.post('api/template.php', payload).then(response => {
        if (response.data.error === 'none') {
          this.productType = response.data.productType
          this.breadCrumb = response.data.bc
          this.product = response.data.product
          this.rating = response.data.rating
          this.reviews = response.data.reviews
          this.tabs = response.data.tabs
          this.$fnc.pageView(this)
          const images = this.product.images.map(
            x => {
              return this.$store.state.mediaCdn + x.source
            }
          )
          this.activeImage = this.product.images[0]
          const jsonld = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: this.product.title,
            image: images,
            description: this.product.title + ' fiyatı: ' + this.product.price + ' TL, satın alın, toptan satışı',
            sku: this.product.sku,
            gtin13: this.product.barcode,
            brand: {
              '@type': 'Brand',
              name: this.product.brandName
            },
            review: this.product.reviews,
            aggregateRating: this.product.rating.average,
            offers: {
              '@type': 'Offer',
              url: window.location.href,
              priceCurrency: 'TRY',
              price: this.product.price,
              priceValidUntil: this.product.validUntil,
              itemCondition: 'https://schema.org/NewCondition',
              availability: 'https://schema.org/InStock',
              seller: {
                '@type': 'Organization',
                name: 'https://nettechstore.com/'
              }
            }
          }
          this.jsonld = jsonld
          this.error = null
          this.isLoading = false
        } else {
          this.error = response.data.error
          setTimeout(() => {
            this.$router.push({ name: '404', params: { catchAll: this.$i18n.t(this.$fnc.seo(response.data.error)) } })
          }, 400)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.reviewSummary {
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  margin-top: 12px;
}
.person {
  display: grid;
  grid-template-columns: auto 90px;
  font-weight: bold;
  align-items: center;
}
.comment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  align-items: flex-start;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ecebf3;
  margin-right: 10px;
  display: grid;
  place-items: center;
  font-weight: bold;
  color:#727272;
}
// comment bubble
.comment-bubble {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 0px;
}
.comment {
  padding: 6px;
}
.tabs {
  // border:1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background:#fff;
  .tabNavContainer {
    width: 100%;
    overflow-y: auto;
  }
  .tabNav {
    display: flex;
    gap: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece;
    button{
      border:none;
      background:none;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 3px;
      font-size: 14px;
    }
    .tabActive, button:hover {
      background: var(--color2);
      color: white;
    }
  }
}
.imageContainer {
  padding-bottom:20px; display:grid; grid-template-columns: 70px auto; gap: 10px;
  border-right: 2px solid #f5f5f5;
  // background: #fff;
  // padding: 16px;
  // border-radius: 8px;
}
.tmb {
  border: none;
  margin-bottom: 9px;
  overflow: hidden;
  border-radius: 4px;
  background: white;
  display: grid;
  place-items: center;
  border: 1px solid #f5f5f5;
  max-width: 70px;
}
.activeTmb {
  border: 1px solid var(--color1);
}
.hidden {
  display: none;
}
.visible {
  display:block;
}
.pContainer {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 0px;
  padding: 20px;
  background: #fff;
  border-radius: 6px;
}
@media screen and (max-width:768px) {
  .pContainer {
    grid-template-columns: 1fr;
    gap:12px;
  }
}
</style>
