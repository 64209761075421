<template>
  <div v-if="info.orders">
    <div class="row" v-for="item in info.orders" :key="item.faturaId" :class="{ 'activeRow': item.faturaId === activeOrder}">
      <div>
        <div class="faturaRow">
          <div class="grid2 kargoLink" style="color:black" @click="selectActive(item.faturaId)">
            <div>{{ item.faturaNo }}</div>
            <div class="right">{{ item.faturaTarih }}</div>
          </div>
          <div class="grid2">
            <div style="color:orange;font-weight:bold">{{ item.status }}</div>
            <div class="right">{{ item.faturaTutar.toFixed(2) }} {{ curSign }}</div>
          </div>
        </div>
        <div style="display:grid; grid-template-columns: auto 30px;font-size:13px;padding:12px;background:#fff;align-items:center">
          <div>
            <a v-if="item.trackingNumber" :href="item.trackingUrl" target="_blank" class="kargoLink">
              {{ item.shippingCompany }} Takip {{ item.trackingNumber }} <i class="fa fa-external-link-alt"></i>
            </a>
            </div>
          <div class="buttonSet right" style="width:30px">
            <button @click="selectActive(item.faturaId)" v-if="activeOrder !== item.faturaId" style="background:teal;color:white"><i class="fa fa-angle-down"></i></button>
            <button @click="selectActive(item.faturaId)" v-else><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>
      <div class="faturaDetails" v-if="activeOrder === item.faturaId">
        <div class="items">
          <ul style="">
            <li v-for="stock in item.items" :key="stock.id">
              <div class="ustRow">
                <span style="padding:12px">
                  <router-link v-if="!stock.noLink" :to="{ name: 'Product', params: { pid: stock.pid + '-' +$fnc.seo(stock.title) } }">
                    <img v-if="stock.image" class="ovalImage" :src="cdn + stock.image" width="100%">
                    <img v-else class="ovalImage" :src="cdn + '1800_no-image.png'" width="100%">
                  </router-link>
                </span>
                <span v-if="!stock.noLink">
                  <router-link :to="{ name: 'Product', params: { pid: stock.pid + '-' +$fnc.seo(stock.title) } }" v-html="stock.title">{{ stock.title }}</router-link>
                </span>
                <span v-else>{{ stock.title }}</span>
                <span style="text-align:right">{{ stock.qty }} {{ $t('Ad.') }}</span>
                <span style="text-align:right">{{ stock.tutar.toFixed(2) }} {{ curSign }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="items" style="border-top:1px solid #ccc;padding-top:14px;margin-top:-20px; padding-bottom:2px">
          <ul>
            <li>
              <div class="ustRow infoRow">
                <span style="text-align:right">{{ item.itemCount }} Ad.</span>
                <span style="text-align:right">{{ item.subTotal.toFixed(2) }} {{ curSign }}</span>
              </div>
              <div class="ustRow infoRow" v-for="item,idx in item.infoItems" :key="idx">
                <span style="text-align:right">{{ item.title }}</span>
                <span style="text-align:right">{{ item.tutar.toFixed(2) }} {{ curSign }}</span>
              </div>
              <div class="ustRow infoRow">
                <span style="text-align:right">Toplam</span>
                <span style="text-align:right">{{ item.faturaTutar.toFixed(2) }} {{ curSign }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GlassModal v-if="showFileUploader" @close="closeModal">
      <template v-slot:title>Dosyalarınızı yükleyin</template>
      <template v-slot:body>
        <p>Dosyalarınızı lütfen <b>PDF</b>, <b>Aİ</b> veya <b>PSD</b> formatında yükleyin</p>
        <div class="filePicker">
          <input type="file">
          <button><i class="fa fa-upload"></i> Dosyayı Yükle</button>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn,
      activeOrder: null,
      showFileUploader: false,
      stockId: null,
      curSign: this.$store.state.user.curSign
    }
  },
  mounted () {
    // this.selectActive(this.info.orders[0].faturaId)
  },
  methods: {
    openModal: function (stockId) {
      this.showFileUploader = true
      this.stockId = stockId
    },
    closeModal: function () {
      this.showFileUploader = false
      this.stockId = null
    },
    selectActive: function (orderNo) {
      if (this.activeOrder === orderNo) {
        this.activeOrder = null
      } else {
        this.activeOrder = orderNo
      }
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.kargoLink {
  cursor: pointer;
  color:teal;
  text-decoration:none;
}
.kargoLink:hover {
  text-decoration:underline;
}
.filePicker {
  margin-top:6px;
  padding: 12px;
  background:#e5e5e5;
  border-radius:6px;
  display:grid;
  grid-template-columns:auto 120px;
  gap:10px;
  place-items:center;
  input {
    width:100%;
  };
  button {
    font-size: 12px;
    width: 100%;
    padding:9px;
    border:none;
    border:1px solid #ccc;
    background:#fff;
    border-radius:6px;
    transition: all 0.1s ease-out;
  }
  button:hover {
    background: var(--color2);
    color:white;
  }
}
a {
  color:black;
  user-select: unset;
  user-select: text !important;
}
a:hover {
  color: var(--color1);
}
.ovalImage {
  padding:1px; border:1px solid #ccc;border-radius:50%;background:white;
  user-select: none;
  -webkit-user-drag: none;
}
.grid2 {
  display:grid;
  grid-template-columns: auto auto;
  .right {
    text-align:right;
  }
}
.buttonSet {
  text-align:center;
  button {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border: none;
    background: #f1f1f1;
    border-radius: 50%;
  }
  button:hover {
    background: #e0e0e0;
  }
}
.row {
  // border: 1px solid #ccc;
  margin-bottom: 22px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  transition: all 0.1s ease-out;
}
.row:hover, .activeRow {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.faturaRow {
  padding: 5px;
  align-items: center;
  display:grid;
  grid-template-columns: 240px auto;
  font-size: 14px;
  user-select: none;
  background: #fff;
  overflow: hidden;
  border-radius: 6px;
  div {
    padding: 4px;
  }
}
.items {
  background:#f3f3f3;
  border-radius: 6px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 20px;
      .ustRow {
        display: grid;
        grid-template-columns: 100px auto 90px 90px;
        align-items: center;
        min-height: 90px;
      }
    }
    li:nth-child(even) {
       background: white;
    }
  }
}
@media screen and (max-width: 768px) {
  .faturaRow {
    grid-template-columns: 1fr;
  }
  .ustRow {
    grid-template-columns: 60px auto 80px 80px !important;
  }
}
.infoRow{
  font-size:12px;
  min-height: 0px !important;
  padding:0;margin:0;margin-bottom:5px;
  grid-template-columns: auto 90px !important;
}
</style>
