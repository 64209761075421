<template>
  <div class="orderContainer">
    <div class="info">
      <div class="irow2"><span>{{ $store.state.user.userName }}</span></div>
      <div class="irow"><span>E-Posta</span><span>{{ $store.state.user.email }}</span></div>
      <div class="irow"><span>Telefon</span><span>{{ $fnc.phone(info.user.kullanicilarGsm) }}</span></div>
      <div class="irow">
        <span>Güncel Bakiye</span>
        <div style="display:grid; grid-template-columns: 1fr 1fr">
          <span class="impo">{{ $n(info.bakiye, 'currency', 'en') }}</span>
          <span class="impo" style="color:#666;">({{ $n(info.bakiyeTl, 'currency', 'tr') }})</span>
        </div>
      </div>
    </div>
    <div style="widtH:350px; overflow:auto; background:#fff; border:1px solid #ccc">
      <table class="t1">
        <thead>
          <tr>
            <th style="width:80px">Tarih</th>
            <th style="width:120px">Belge No</th>
            <th>İzahat</th>
            <th class="r">Borç</th>
            <th class="r">Alacak</th>
            <th class="r">Bakiye</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,idx) in info.rows" :key="idx">
            <td>{{ item.date_added }}</td>
            <td>{{ item.belgeno }}</td>
            <td>{{ item.izahat }}<br><b>{{ item.description }}</b> </td>
            <td v-if="item.borc > 0" class="r">${{ item.borc.toFixed(2) }}</td>
            <td v-else></td>
            <td v-if="item.alacak > 0" class="r">${{ item.alacak.toFixed(2) }}</td>
            <td v-else></td>
            <td class="r">${{ item.bakiye.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      cdn: this.$store.state.mediaCdn
    }
  },
  mounted () {
    // this.selectActive(this.info.orders[0].faturaId)
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.orderContainer {
  width: 100%;
}
.t1 {
  width: 100%;
  min-width: 750px;
}
.info {
  background: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  .irow {
    display:grid;
    grid-template-columns: 96px auto;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    border-bottom:1px solid #ccc;
    .impo {
      color:var(--color1);
      font-weight: bold;
      font-size: 18px;
    }
  }
  .irow:last-child {
    border-bottom: none;
  }
  .irow2 {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
  }
}
td,th {
  border-bottom:1px solid #ccc;
  text-align: left;
  font-size:12px;
  padding:3px;
}
tr {
  height:44px;
}
tr:last-child td {
  border: none;
}
.r {
  text-align: right;
  width:80px;
}
.filePicker {
  margin-top:6px;
  padding: 12px;
  background:#e5e5e5;
  border-radius:6px;
  display:grid;
  grid-template-columns:auto 120px;
  gap:10px;
  place-items:center;
  input {
    width:100%;
  };
  button {
    font-size: 12px;
    width: 100%;
    padding:9px;
    border:none;
    border:1px solid #ccc;
    background:#fff;
    border-radius:6px;
    transition: all 0.1s ease-out;
  }
  button:hover {
    background: var(--color2);
    color:white;
  }
}
a {
  color:black;
  user-select: unset;
  user-select: text !important;
}
a:hover {
  color: var(--color1);
}
.ovalImage {
  padding:1px; border:1px solid #ccc;border-radius:50%;background:white;
  user-select: none;
  -webkit-user-drag: none;
}
.grid2 {
  display:grid;
  grid-template-columns: auto auto;
  .right {
    text-align:right;
  }
}
.buttonSet {
  text-align:center;
  button {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border: none;
    background: #f1f1f1;
    border-radius: 50%;
  }
  button:hover {
    background: #e0e0e0;
  }
}
.row {
  // border: 1px solid #ccc;
  margin-bottom: 22px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  transition: all 0.1s ease-out;
}
.row:hover, .activeRow {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.faturaRow {
  padding: 10px;
  align-items: center;
  display:grid;
  grid-template-columns: 240px auto 40px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  background: #fff;
  overflow: hidden;
  border-radius: 6px;
  div {
    padding: 8px;
  }
}
.items {
  background:#f3f3f3;
  border-radius: 6px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 20px;
      .ustRow {
        display: grid;
        grid-template-columns: 100px auto 60px 60px;
        align-items: center;
        min-height: 90px;
      }
    }
    li:nth-child(even) {
       background: white;
    }
  }
}
@media screen and (max-width: 768px) {
  .orderContainer {
    width:calc(100vw - 20px);
  }
  .faturaRow {
    grid-template-columns: 1fr;
  }
  .ustRow {
    grid-template-columns: 60px auto 60px 60px !important;
  }
}
</style>
