<template>
  <div>
    <CartLogin :user="user" :error="loginError" />
    <CartAddress :user="user" />
    <CartDeliveryOptions v-if="invoiceAddress.adresId" :user="user" :shipmentOptions="$parent.shipmentOptions" :selectedShipment="$parent.selectedShipment" />
    <CartPaymentMethods :paymentOptions="paymentOptions" :selectedPayment="$parent.selectedPayment" />
    <GlassModal style="width:100%;margin:0;padding:0;z-index:100000" v-if="showPayment" :hideClose="true" :fullPage="true">
      <template v-slot:title></template>
      <template v-slot:body>
        <p style="text-align:center; font-weight:bold;padding-top:12px;color:green"><i class="fa fa-shield-alt"></i> Güvenli Ödeme Sayfasındasınız</p>
        <p style="text-align:center;margin-top:12px;padding-bottom:12px;font-size:13px">Lütfen ödeme işleminiz tamamlanana kadar bu sayfayı kapatmayın</p>
        <iframe name="cc-start" style="border:none;min-height:100vh;width:100%"></iframe>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
import CartLogin from '@/small-components/CartLogin'
import CartAddress from '@/small-components/CartAddress'
import CartPaymentMethods from '@/small-components/CartPaymentMethods'
import CartDeliveryOptions from '@/small-components/CartDeliveryOptions'
export default {
  props: ['paymentOptions'],
  components: { CartLogin, CartAddress, CartPaymentMethods, CartDeliveryOptions },
  data () {
    return {
      user: this.$store.state.user,
      loginError: false,
      invoiceAddress: {},
      deliveryAddress: {},
      isSavingCart: false,
      contracts: true,
      contractError: false,
      instalment: 1,
      showPayment: false
    }
  },
  methods: {
    closePayment: function () {
      this.showPayment = false
      this.isSavingCart = false
      // reload page
      // window.location.reload()
    },
    processPayment: async function () {
      console.clear()
      event.preventDefault()
      const payload = {
        paymentMethod: this.$parent.selectedPayment,
        instalment: this.instalment,
        user: this.$store.state.user,
        invoiceAddress: this.invoiceAddress,
        deliveryAddress: this.deliveryAddress,
        uniq: this.$parent.uniq,
        shipmentCompany: this.$parent.selectedShipment
      }
      if (this.contracts === false) {
        // alert('Sözleşmeleri onaylamalısınız')
        this.contractError = true
        this.isSavingCart = false
      } else {
        this.contractError = false
        this.isSavingCart = true
        const fields = []
        const form = document.querySelector('.fields')
        var str = ''
        await axios.post('/api/saveOrder.php', payload).then(response => {
          const data = response.data.data
          fields.push({ name: 'uniq', value: data.uniq })
          fields.push({ name: 'reference', value: data.reference })
          fields.forEach(f => {
            str += '<input type="hidden" name="' + f.name + '" value="' + f.value + '">'
          })
          form.innerHTML = str
          this.checkPayment(data.reference)
          this.showPayment = true
          setTimeout(() => {
            document.querySelector('#paymentForm').submit()
          }, 500)
        })
      }
    },
    checkPayment: async function (ref) {
      // check order status every 3 seconds
      const payload = {
        ref: ref,
        user: this.$store.state.user,
        uniq: this.$parent.uniq
      }
      const interval = setInterval(() => {
        axios.post('/api/checkOrder.php', payload).then(response => {
          const data = response.data
          console.log(data)
          if (data.status === 'success') {
            clearInterval(interval)
            this.showPayment = false
            this.isSavingCart = false
            // goto thanks page
            this.$router.push({ name: 'Thanks' })
          } else if (data.status === 'fail') {
            clearInterval(interval)
            this.$router.push('/tr/hata?r=' + ref)
            this.showPayment = false
            this.isSavingCart = false
          }
        })
      }, 3000)
    },
    selectPayment: function (idx) {
      this.$parent.selectedPayment = this.paymentOptions[idx]
      this.$parent.getCart()
    },
    getInfo: async function () {
      this.$parent.selectedShipment = null
      this.$parent.selectedPayment = null
      const sId = localStorage.getItem('sId')
      const payload = {
        sId: sId
      }
      await axios.post('api/info.php', payload).then(response => {
        this.$store.dispatch('setInfo', response.data)
        this.$store.dispatch('setUser', response.data.user)
        this.$store.dispatch('setCart', response.data.user.cartCount)
        localStorage.setItem('sId', response.data.user.sId)
      })
    },
    login: async function (login) {
      const payload = {
        email: login.email,
        pass: login.pass,
        user: this.$store.state.user
      }
      await axios.post('/api/login.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.user = this.$store.state.user
          this.loginError = false
        } else {
          this.loginError = err
        }
        this.getInfo()
        this.$parent.getCart()
      })
    },
    registerVisitor: async function (login) {
      const payload = {
        name: login.name,
        user: this.$store.state.user,
        type: 'visitor'
      }
      await axios.post('/api/registerVisitor.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.user = this.$store.state.user
          this.loginError = false
        } else {
          this.loginError = err
        }
        this.getInfo()
        this.$parent.getCart()
      })
    },
    register: async function (login, contract, messagePermission) {
      const payload = {
        email: login.email,
        pass: login.pass,
        name: login.name,
        user: this.$store.state.user,
        contract: contract,
        messagePermission: messagePermission
      }
      await axios.post('/api/register.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.user = this.$store.state.user
          this.loginError = false
        } else {
          this.loginError = err
        }
        this.getInfo()
        this.$parent.getCart()
      })
    },
    logout: async function () {
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('/api/logout.php', payload).then(response => {
        this.$store.dispatch('setUser', response.data.user)
        this.user = false
        this.invoiceAddress = {}
        this.deliveryAddress = {}
        this.$parent.getCart()
      })
    }
  }
}
</script>
