<template>
  <div>
    <div class="container">
      <BreadCrumb :info="breadCrumb" :jsonld="bcJsonld" />
      <div class="filterButtons" v-if="$store.state.width < 768">
        <button @click="showMobileFilters"><i class="fa fa-filter"></i> Filtreleri Göster</button>
        <button @click="showMobileSortOrder"><i style="font-size:16px" class="fa fa-sort"></i> Sırala</button>
      </div>
      <div class="catalog" :class="{ catalogNoFilter: !showFilters }">
        <div class="filters" id="filters" v-if="showFilters">
          <div class="block" v-if="subCats.length > 0">
            <ul v-for="item in subCats" :key="item.id" class="subs">
              <li>
                <router-link
                  :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id }}">
                  <b>{{ item.title }}</b>
                </router-link>
              </li>
              <li v-for="item2 in item.subs" :key="item2.id" style="padding-left:12px">
                <router-link :class="{ bold: item2.subs.length > 0, active: item2.id === parseInt($route.params.id) }"
                  :to="{ name: 'Products', params: { id: item2.id + '-' + $fnc.seo(item2.title), top: item.id }}">{{ item2.title }}
                </router-link>
                <ul style="padding-left:12px">
                  <li v-for="item3 in item2.subs" :key="item3.id">
                    <router-link
                      :to="{
                        name: 'Products',
                        params: { id: item3.id + '-' + $fnc.seo(item3.title), top: item.id }
                        }">
                      {{ item3.title }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div v-if="allBrands.length > 0">
            <div class="block">
              <h3>Üretici Markası</h3>
              <ul class="subs">
                <li v-for="item in allBrands" :key="item.id">
                  <router-link :class="{ active: parseInt(item.id) === parseInt($route.query.brand) }"
                  :to="{
                        name: 'Products',
                        query: {
                          comp_brands: $route.query.comp_brands,
                          comp_models: $route.query.comp_models,
                          brand: item.id,
                          campaignId: $route.query.campaignId
                        }
                        }"
                  >{{ item.title }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="allFilters.length > 0">
            <div class="block" style="padding:0" v-for="item in allFilters" :key="item.id">
              <h3 style="position:sticky; top: 0; background:#fff;padding:10px">{{ item.title }}</h3>
              <ul class="subs" style="padding-left: 10px; padding-right:10px">
                <li v-for="li in item.subs" :key="li.id">
                  <router-link
                  :to="{ name: 'Products',
                  params: {
                    id: $route.params.id,
                    top: $route.params.top
                  },
                  query: {
                    filters: li.degerId,
                    comp_brands: comp_brands,
                    comp_models: comp_models,
                    brand: $route.query.brand,
                    campaignId: $route.query.campaignId
                  }}">
                    {{ li.deger }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="products">
          <div v-if="this.cat.kat.showCompatibleBrandFilter" style="min-height:110px">
            <Brands :style="'max-width: ' + brandWidth + 'px'" :brand_info="brandInfo" :cat_id="$route.params.id" :item="compBrands" v-if="compBrands" />
            <div v-else style="display:grid; place-items:center;"><div class="loading"></div></div>
          </div>
          <CatInfo style="margin-bottom: 14px" v-if="showCatInfo && $store.state.width > 768" :info="{ totalProducts: totalProducts, title: cat.title }" />
          <div class="list" style="min-height:370px">
            <ProductCard :width="width" :height="height" v-for="p in products" :key="p.id" :p="p"  />
          </div>
          <Pager :id="id" :brands="brands" :filters="filters" :comp_brands="comp_brands" :comp_models="comp_models" :page="page" :pageRange="pageRange" :totalProducts="totalProducts" :pageSize="pageSize"/>
        </div>
      </div>
    </div>
    <GlassModal style="width:100%;margin:0;padding:0;z-index:100000" v-if="mobileSortOrder" :fullPage="false" :hideClose="false" @close="showMobileSortOrder">
      <template v-slot:title>Ürünleri Sıralayın</template>
      <template v-slot:body>
        <div class="sortOptions">
          <a @click="setSort('new')">En Yeniler</a>
          <a @click="setSort('arf')">En Düşük Fiyat</a>
          <a @click="setSort('azf')">En Yüksek Fiyat</a>
        </div>
      </template>
    </GlassModal>
    <GlassModal style="width:100%;margin:0;padding:0;z-index:100000" v-if="mobileFilters" :fullPage="false" :hideClose="false" @close="showMobileFilters">
      <template v-slot:title>&nbsp;</template>
      <template v-slot:body>
        <div style="height: calc(100vh - 200px); overflow:auto">
        <div v-if="allBrands.length > 0" style="margin-bottom:14px;">
          <div class="blockMobile">
            <h3>Üretici Markası</h3>
            <ul class="subs">
              <li v-for="item in allBrands" :key="item.id">
                <router-link :class="{ active: parseInt(item.id) === parseInt($route.query.brand) }"
                :to="{
                      name: 'Products',
                      query: {
                        comp_brands: $route.query.comp_brands,
                        comp_models: $route.query.comp_models,
                        brand: item.id,
                        campaignId: $route.query.campaignId
                      }
                      }"
                >{{ item.title }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="allFilters.length > 0">
          <div class="blockMobile" v-for="item in allFilters" :key="item.id">
            <h3>{{ item.title }}</h3>
            <ul class="subs">
              <li v-for="li in item.subs" :key="li.id">
                <router-link
                :to="{ name: 'Products',
                params: {
                  id: $route.params.id,
                  top: $route.params.top
                },
                query: {
                  filters: li.degerId,
                  comp_brands: comp_brands,
                  comp_models: comp_models,
                  brand: $route.query.brand,
                  campaignId: $route.query.campaignId
                }}">
                  {{ li.deger }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import CatInfo from '@/small-components/CatInfo.vue'
import ProductCard from '@/small-components/ProductCard.vue'
import Pager from '@/small-components/Pager.vue'
import Brands from '@/home-components/Brands'

export default {
  components: { BreadCrumb, CatInfo, ProductCard, Pager, Brands },
  computed: {
    brandWidth () {
      const products = document.querySelector('.products')
      return products.offsetWidth
    }
  },
  data () {
    return {
      products: [],
      id: this.$route.params.id,
      brands: this.$route.query.brands,
      filters: this.$route.query.filters,
      page: parseInt(this.$route.query.p),
      sort: this.$store.state.sort,
      totalProducts: 0,
      pageSize: this.$store.state.pageSize,
      pageRange: this.$fnc.pageRange(0, { min: 1, total: 1, length: 1 }),
      cat: {
        kat: {
          showCompatibleBrandFilter: 0
        }
      },
      showFilters: true,
      subCats: [],
      allBrands: [],
      allFilters: [],
      breadCrumb: [],
      bcJsonld: null,
      hero: {},
      showCatInfo: true,
      width: '320px',
      height: '320px',
      comp_brands: this.$route.query.comp_brands,
      comp_models: this.$route.query.comp_models,
      brandz: {},
      brandInfo: ' ',
      productFilters: [],
      compBrands: false,
      mobileFilters: false,
      mobileSortOrder: false
    }
  },
  metaInfo () {
    let title = this.cat.pageTitle
    if (!title) {
      title = this.cat.title
    }
    return {
      title: title + ' Toptan Satın Alın',
      meta: [{ vmid: 'description', name: 'description', content: this.cat.body }]
    }
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.brands = this.$route.query.brands
      this.comp_brands = this.$route.query.comp_brands
      this.comp_models = this.$route.query.comp_models
      this.filters = this.$route.query.filters
      this.page = parseInt(this.$route.query.p)
      this.getProducts()
      this.mobileFilters = false
    },
    '$store.state.sort': function () {
      this.sort = this.$store.state.sort
      if (this.page === 1) {
        this.getProducts()
      } else {
        this.$router.push({
          name: 'Products', query: { brands: this.brands, filters: this.filters }
        })
      }
    }
  },
  beforeMount () {
    this.setW()
    this.getProducts()
  },
  methods: {
    setSort: function (tip) {
      this.$store.dispatch('setSort', tip)
      this.mobileSortOrder = false
    },
    showMobileSortOrder () {
      this.mobileSortOrder = !this.mobileSortOrder
    },
    showMobileFilters () {
      this.mobileFilters = !this.mobileFilters
      // const f = document.getElementById('filters').innerHTML
      if (this.mobileFilters) {
        setTimeout(() => {
          // document.getElementById('mobileFiltersContainer').innerHTML = f
        }, 500)
      }
    },
    to (item, top) {
      let t = ''
      if (top) {
        t = top
      } else {
        t = item.parent_id
      }
      let q = {}
      q = {
        name: 'Products',
        params: {
          id: item.id + '-' + this.$fnc.seo(item.title),
          top: t
        }
      }
      return q
    },
    setW: function () {
      const w = this.$fnc.getWidth()
      if (w < 768) {
        this.width = '160px'
        this.height = '160px'
      } else if ((w > 768) && (w < 1200)) {
        this.width = '220px'
        this.height = '220px'
      } else if ((w > 1200) && (w < 1400)) {
        this.width = '220px'
        this.height = '220px'
      } else if ((w > 1400) && (w < 1600)) {
        this.width = '220px'
        this.height = '220px'
      } else {
        this.width = '220px'
        this.height = '220px'
      }
    },
    getProducts: async function () {
      this.products = []
      this.compBrands = false
      var page = 1
      if (this.page) {
        page = parseInt(this.page)
      } else {
        this.page = 1
      }
      const payload = {
        id: parseInt(this.$route.params.id),
        brands: this.$route.query.brands,
        filters: this.$route.query.filters,
        page: page,
        sort: this.sort,
        pageSize: this.pageSize,
        top: parseInt(this.$route.params.top),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId,
        comp_brands: this.$route.query.comp_brands,
        comp_models: this.$route.query.comp_models,
        brand: this.$route.query.brand,
        campaignId: this.$route.query.campaignId
      }
      await axios.get('api/products.php', { params: payload }).then(response => {
        const data = response.data
        this.products = data.catalog
        this.totalProducts = data.total
        this.cat = data.cat
        this.pageSize = data.pageSize
        this.brandz = data.brands
        this.brandInfo = data.brandInfo
        // console.log(this.brandz)
        var length = 10
        if (this.$fnc.getWidth() < this.$store.state.mobileWidth) {
          length = 6
        }
        this.pageRange = this.$fnc.pageRange(this.page, { min: 1, total: Math.ceil(data.total / data.pageSize), length: length })
        this.subCats = data.subMenus
        this.breadCrumb = data.bc
        let headerImage = ''
        let pageTitle = data.cat.title
        if (!data.catImage) {
          headerImage = this.$store.state.info.base + '/src/assets/header.png'
        } else {
          headerImage = this.$store.state.fileCdn + data.catImage
        }
        if (this.cat.pageTitle) {
          pageTitle = this.cat.pageTitle
        }
        this.bcJsonld = {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: data.breadcrumbItems
        }
        this.hero = {
          title: pageTitle,
          text: this.cat.body,
          color: this.cat.sUrlText ?? '#FFF',
          src: headerImage,
          show: true
        }
        if (this.cat.kat.showCompatibleBrandFilter === 1) {
          this.getCompBrands()
        } else {
          this.compBrands = false
        }
      }).then(response => {
        this.$fnc.pageView(this)
        if (this.cat.kat.showBrandFilter === 1) {
          this.getBrands()
        } else {
          this.allBrands = []
        }
        this.getFilters()
      })
    },
    getCompBrands: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/get_compatible_brands.php', { params: payload }).then(response => {
        const data = response.data
        this.compBrands = data.compBrands
        console.log(data.compBrands)
      })
    },
    getFilters: async function () {
      const payload = {
        id: parseInt(this.$route.params.id),
        pageSize: this.pageSize,
        top: parseInt(this.$route.params.top),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/get_features.php', { params: payload }).then(response => {
        // console.log(response.data)
        this.allFilters = response.data.filters
      })
    },
    getBrands: async function () {
      console.log('getting brands')
      const payload = {
        id: parseInt(this.$route.params.id),
        pageSize: this.pageSize,
        top: parseInt(this.$route.params.top),
        sId: this.$store.state.user.sId,
        userId: this.$store.state.user.userId
      }
      await axios.get('api/get_brands.php', { params: payload }).then(response => {
        // console.log(response.data)
        this.allBrands = response.data.brands
      })
    }
  }
}
</script>
<style scoped lang="scss">
.sortOptions {
  display: grid;
  gap: 10px;
  a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.filterButtons {
  display: flex;
  justify-items: space-around;
  gap: 3px;
  button {
    width: 100%;
    font-size:12px;
    margin-bottom:12px;padding:6px; border:none; background:white;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    i {
      font-size: 14px;
      color: var(--color1);
    }
  }
}
.bold {
  font-weight: bold;
}
.products {
  min-height: 370px;
  width:100%;
}
ul.subs {
  user-select: none;
  list-style:none;
  li{
    a {
      font-size: 13px;
      color:#000;
      display:block;
    }
    a:hover, .active {
      color:var(--color1);
    }
    .active {
      font-weight:bold;
    }
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-top: 1px;
        padding-bottom: 1px;
        a {
          font-weight: normal;
        }
      }
    }
  }
}
.catalog {
  display: grid;
  grid-template-columns: 240px auto;
  margin-bottom: 30px;
}
.filters {
    // border-right:1px solid #ddd;
  padding-right:20px;
}
.block {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 260px;
  overflow: auto;
  padding: 8px;
  background: #fff;
  p {
    font-weight: bold;
    font-size: 15px;
  }
  ul {
    list-style:none;
  }
}
.blockMobile {
  margin-bottom: 14px;
  ul {
    max-height: 200px;
    overflow: auto;
  }
  h3 {
    font-size:14px;
  }
  a {
    padding-top:3px;
    padding-bottom:3px;
  }
}
.catalogNoFilter {
  grid-template-columns: 1fr;
}
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  min-height: 1800px;
  min-height: 400px;
  grid-auto-rows: min-content;
}
@media screen and (min-width: 420px) and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}
@media screen and (max-width: 420px) {
  .products {
    width: calc(100vw - 20px);
  }
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }
}
</style>
