<template>
  <div class="simpleHeader">
    <router-link :to="{ name: 'Index' }" class="logo" :title="$t('Anasayfa')" v-if="$store.state.user.customerType === 6"></router-link>
    <div v-else>
      <router-link :to="{ name: 'Index' }" class="logoKurumsal" :title="$t('Anasayfa')" v-if="$store.state.user.customerGroup === 33"></router-link>
      <router-link :to="{ name: 'Index' }" class="logoBayi" :title="$t('Anasayfa')" v-else></router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  }
}
</script>

<style scoped lang="scss">
.simpleHeader {
  display: grid;
  place-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
}
.logo {
  margin: auto;
  width: 140px;
  height: 68px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.svg') center no-repeat;
  background-size: contain;
}
.logoBayi {
  margin: auto;
  width: 140px;
  height: 68px;
  display: grid;
  align-items:center;
  background: url('../assets/logoBayi.png') center no-repeat;
  background-size: contain;
}
.logoKurumsal {
  margin: auto;
  width: 140px;
  height: 68px;
  display: grid;
  align-items:center;
  background: url('../assets/logoKurumsal.png') center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 768px) {
  .logo {
    width: 140px;
    height: 68px;
  }
}
</style>
