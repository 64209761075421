<template>
  <div class="block">
    <div v-if="!user.userId">
      <div class="tabs">
        <i class="fa fa-file-invoice"></i>
        <button class="activeTab">{{ $t('Fatura ve Teslimat Bilgileri') }}</button>
      </div>
    </div>
    <div v-else>
      <div v-if="!isLoading" style="display:grid; grid-template-columns: 1fr 1fr">
        <div>
          <div class="tabs">
            <i class="fa fa-file-invoice"></i>
            <button class="activeTab" v-if="!invoiceAddress">{{ $t('Fatura ve Teslimat Bilgileri') }}</button>
            <button class="activeTab" v-if="invoiceAddress.sameForDelivery">{{ $t('Fatura ve Teslimat Bilgileri') }}</button>
            <button class="activeTab" v-if="invoiceAddress && !invoiceAddress.sameForDelivery">{{ $t('Fatura Bilgileri') }}</button>
          </div>
          <div class="formRow" style="height:100px" v-if="invoiceAddress">
            <p>{{ invoiceAddress.adres }}</p>
            <p>{{ invoiceAddress.il }} / {{ invoiceAddress.ilce }}</p>
            <p>{{ $fnc.phone(invoiceAddress.tel) }}</p>
          </div>
          <div class="formRow" v-else></div>
          <div class="formRow" style="display:flex;">
            <div v-if="$store.state.user.customerType === 6">
              <button @click="showAddressModal('invoice', invoiceAddress.adresId)" v-if="invoiceAddress">{{ $t('Adresi Düzenle') }}</button>
              <button @click="showAddressModal('invoice', 0)" v-else>{{ $t('Adres Ekle') }}</button>
            </div>
          </div>
        </div>
        <div v-if="!invoiceAddress.sameForDelivery && invoiceAddress">
          <div class="tabs">
            <i class="fa fa-file-invoice"></i>
            <button class="activeTab">{{ $t('Teslimat Bilgileri') }}</button>
          </div>
          <div class="formRow" style="height:100px" v-if="deliveryAddress">
            <p>{{ deliveryAddress.adres }}</p>
            <p>{{ deliveryAddress.il }} / {{ deliveryAddress.ilce }}</p>
            <p>{{ $fnc.phone(deliveryAddress.tel) }}</p>
          </div>
          <div class="formRow" v-else></div>
          <div class="formRow" style="display:flex;">
            <button @click="showAddressModal('delivery', invoiceAddress.adresId)" v-if="deliveryAddress">{{ $t('Adresi Düzenle') }}</button>
            <button @click="showAddressModal('delivery', 0)" v-else>{{ $t('Adres Ekle') }}</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="tabs">
          <i class="fa fa-file-invoice"></i>
          <button class="activeTab">Fatura ve Teslimat Bilgileri</button>
        </div>
        <div class="formRow" style="height:134px">
          <img src="@/assets/loading-pulse.svg">
        </div>
      </div>
    </div>
    <CartAddressModal :showModal="addressModal" :address="address" :cities="cities" :isSaving="addressSaving" />
  </div>
</template>

<script>
import axios from 'axios'
import CartAddressModal from '@/small-components/CartAddressModal'
export default {
  components: { CartAddressModal },
  props: ['user'],
  data () {
    return {
      invoiceAddress: false,
      deliveryAddress: false,
      addressModal: false,
      addressType: 'invoice',
      cities: false,
      addressSaving: false,
      isLoading: true,
      address: {},
      emptyAddress: {
        addressId: 0,
        companyTip: 1,
        il: '',
        ilce: '',
        ad: this.$store.state.user.userName,
        unvan: '',
        tel: '',
        adres: '',
        zip: '',
        vd: '',
        vn: '',
        tc: '',
        sameForDelivery: true,
        adresTip: ''
      }
    }
  },
  mounted () {
    this.getAddress()
  },
  watch: {
    user: function () {
      this.getAddress()
    }
  },
  methods: {
    showAddressModal: function (addressType, adresId) {
      this.addressType = addressType
      if (adresId) {
        if (addressType === 'invoice') {
          this.address = this.invoiceAddress
        } else {
          this.address = this.deliveryAddress
        }
      } else {
        this.emptyAddress.ad = this.$store.state.user.userName
        this.address = this.emptyAddress
        if (addressType === 'invoice') {
          this.address.adresTip = 'FATURA'
        } else {
          this.address.adresTip = 'TESLIMAT'
        }
      }
      this.addressModal = !this.addressModal
      this.getAddress()
    },
    saveAddress: async function () {
      this.addressSaving = true
      const payload = {
        user: this.$store.state.user,
        address: this.address
      }
      const errs = document.querySelectorAll('.inputError')
      errs.forEach(e => {
        e.classList.remove('inputError')
      })
      await axios.post('/api/saveAddress.php', payload).then(response => {
        const data = response.data
        const err = data.error
        if (err !== 'none') {
          if (err === 'noField') {
            const field = data.field
            if (field) {
              const el = document.querySelector('*[name=' + field + ']')
              if (el) {
                el.focus()
                el.classList.add('inputError')
              }
            }
          } else {
            alert(err)
          }
          this.addressSaving = false
        } else {
          this.getAddress()
          setTimeout(() => {
            this.addressSaving = false
            this.addressModal = false
            this.address = this.emptyAddress
          }, 750)
        }
      })
    },
    getAddress: async function () {
      if (!this.user.userId) {
        return
      }
      const payload = {
        user: this.user
      }
      await axios.post('/api/getAddress.php', payload).then(response => {
        const data = response.data
        this.invoiceAddress = data.invoiceAddress
        this.deliveryAddress = data.deliveryAddress
        this.$parent.invoiceAddress = data.invoiceAddress
        this.$parent.deliveryAddress = data.deliveryAddress
        this.cities = data.cities
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.checker {
  font-size:13px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.tabs {
  display:flex;
  margin-bottom: 8px;
  i {
    width: 24px;
    display: none;
  }
  button {
    border:none;
    background:none;
    border-bottom: 3px solid white;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    transition: all 0.1s ease-in;
    cursor: default;
  }
  .activeTab {
    border-bottom: 3px solid var(--color1);
  }
}
.formError {
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
}
.formRow {
  p {
    font-size: 14px;
  }
  .btn1 {
    padding:10px;
    border-radius: 5px;
    border:none;
    background: var(--color1);
    color: white;
    font-weight: normal;
    font-family: var(--font3);
    font-size: 14px;
    letter-spacing: 1px;
  }
  .passive {
    filter: brightness(1.4);
  }
  .btn1:hover {
    border:none;
    filter: brightness(1.15);
  }
}
</style>
