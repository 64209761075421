<template>
  <div id="toppHeader">
    <div v-if="promoDesktop && $store.state.width > 768" id="dektopPromo" class="dektopPromo">
      <img style="max-width:100%;padding:5px" :src="fileCdn + promoDesktop.image">
    </div>
    <div v-if="promoMobile && $store.state.width < 768 && $store.state.user.customerType === 6" id="dektopPromo" class="dektopPromo">
      <img style="width:calc(100% - 20px);" :src="fileCdn + promoMobile.image">
    </div>
    <header class="container" :class="{ 'iosSafe': !promoMobile}" id="topHeader" v-if="$store.state.width < 768">
      <router-link :alt="$tc('Anasayfa')" :title="$tc('Anasayfa')" :to="{ name: 'Index' }">
        <div class="logo" v-if="$store.state.user.customerType === 6"></div>
        <div v-else>
          <div class="logoKurumsal" v-if="$store.state.user.customerGroup === 33"></div>
          <div class="logoBayi" v-else></div>
        </div>
      </router-link>
      <div class="topMobileMenu">
        <router-link v-if="$store.state.user.customerType === 1" class="bs"
        :to="{ name: 'Page', params: { id: 280 + '-kurye', parent: 280 }}">
          <div class="scooterIcon"></div>
          <div>Kurye</div>
        </router-link>
        <router-link v-else :to="{ name: 'Stores' }" class="bs">
          <div class="mapIcon"></div>
          <div>Mağazalarımız</div>
        </router-link>
        <router-link :to="{ name: 'Blogs' }" class="bs">
          <div class="blogIcon"><i class="fa fa-blog"></i></div>
          <div>Blog</div>
        </router-link>
      </div>
      <div v-if="$store.state.width > 768" class="right">
        <div class="right" v-if="!$store.state.user.userId">
          <router-link :to="{ name: 'Login' }"><i class="fa fa-key"></i> {{ $t('Giriş Yapın') }}</router-link>
          <router-link class="primary" :to="{ name: 'PageGroup', params: { parent: 'nettech-bayilik', id: '' } }"><i class="fa fa-user-plus"></i> {{ $t('Üye Olun') }}</router-link>
        </div>
        <div class="right" v-else>
          <a></a>
          <router-link class="primary" :to="{ name: 'MyAccountOrders' }"><i class="fa fa-user-lock"></i> {{ $t('Hesabım') }}</router-link>
        </div>
      </div>
    </header>
    <div class="container secondRow" v-if="$store.state.width < 768">
      <div class="searchContainer">
        <input name="search" autocomplete="off" placeholder="Ürünlerde Arayın" @keydown.enter="makeSearch" type="text" v-model.trim="searchField">
        <router-link v-if="$store.state.user.customerType === 1" :to="{ name: 'Barcode' }"><i class="fa fa-qrcode"></i></router-link>
      </div>
      <div v-if="$store.state.width > 768">
        <router-link :to="{ name: 'Cart' }" class="cartButton bs">
          <div class="cartIcon"></div>
          <div class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</div>
        </router-link>
      </div>
    </div>
    <div v-if="$store.state.width > 768">
      <div id="deskNav" class="container" @mouseover="toggleMenu(false)" style="display:flex;justify-items:space-between;align-items:center;padding-top:25px;padding-bottom:20px">
        <router-link style="width:auto;margin-right:12px" :alt="$tc('Anasayfa')" :title="$tc('Anasayfa')" :to="{ name: 'Index' }">
          <div class="logo" v-if="$store.state.user.customerType === 6"></div>
          <div v-else>
          <div class="logoKurumsal" v-if="$store.state.user.customerGroup === 33"></div>
          <div class="logoBayi" v-else></div>
        </div>
        </router-link>
        <div style="display:flex;width:1400px;margin-right:12px;">
          <div class="searchContainer" style="margin-right:12px;width:100%">
            <input name="search" autocomplete="off" placeholder="Ürünlerde Arayın" @keydown.enter="makeSearch" type="text" v-model.trim="searchField">
          </div>
          <div v-if="$store.state.width > 768">
            <router-link :to="{ name: 'Cart' }" class="cartButton bs">
              <div class="cartIcon"></div>
              <div class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</div>
            </router-link>
          </div>
        </div>
        <div class="right" style="width:auto">
          <div class="right" v-if="!$store.state.user.userId">
            <router-link :to="{ name: 'Login' }"><i class="fa fa-key"></i> {{ $t('Giriş Yapın') }}</router-link>
            <router-link class="primary" :to="{ name: 'Register' }"><i class="fa fa-user-plus"></i> {{ $t('Üye Olun') }}</router-link>
          </div>
          <div class="right" v-else>
            <a></a>
            <router-link v-if="$store.state.user.customerType !== 1" class="primary" :to="{ name: 'MyAccountOrders' }"><i class="fa fa-user-lock"></i> {{ $t('Hesabım') }}</router-link>
            <router-link v-else class="primary" :to="{ name: 'MyAccountCariHesabim' }"><i class="fa fa-user-lock"></i> {{ $t('Hesabım') }}</router-link>
          </div>
        </div>
      </div>
      <div class="deskNav">
        <div class="container" style="position:relative">
          <nav style="display:grid; grid-template-columns: auto auto">
            <div class="desktopNav">
              <a class="catsLink" @mouseover="toggleMenu(true)"><i class="fa fa-bars" style="margin-right:8px"></i> Kategoriler</a>
              <router-link v-for="item in $store.state.info.homeLinks" :key="item.id" :to="item.to" @mouseover="toggleMenu(false)">
                {{ item.title }}
              </router-link>
            </div>
            <div class="desktopNav" style="justify-content:flex-end;" @mouseover="toggleMenu(false)">
              <router-link :to="{ name: 'Campaigns' }">
                <i class="fa fa-bullhorn"></i><span>Kampanyalar</span>
              </router-link>
              <router-link v-if="$store.state.user.customerType === 1" :to="{ name: 'Page', params: { id: 280 + '-kurye', parent: 280 }}">
                <i class="fa fa-motorcycle"></i><span>Kurye</span></router-link>
              <router-link v-else :to="{ name: 'Stores' }">
                <i class="fa fa-map-marker"></i><span>Mağazalarımız</span>
              </router-link>
              <a v-if="$store.state.user.customerType === 1" style="color:black;cursor:default">USD: {{ $store.state.currency.kurCarpan.toFixed(2) }}₺</a>
            </div>
          </nav>
          <transition name="fadasde2">
            <div v-if="$store.state.catMenu">
              <ul class="menu" @mouseleave="toggleMenu(false)">
                <div class="cont">
                  <li v-for="item in menu" :key="item.id" @mouseover="setSubMenus(item.subs)">
                    <router-link
                    :class="{ activeLink: subMenuParentId === item.id }"
                    @click="toggleMenu(false)" :to="to(item, item.id)">
                      {{ item.title }}
                      <i class="fa fa-angle-right" v-if="item.subs.length > 0"></i>
                    </router-link>
                  </li>
                </div>
                <div class="submenus" style="border-left:1px solid #ccc">
                  <ul class="submenu2" id="subMenusContainer" v-if="subMenus.length > 0">
                    <li v-for="item in subMenus" :key="item.id">
                      <router-link
                      @click="toggleMenu(false)" :to="to(item)" :class="{ bold: item.subs.length > 0}">
                        {{ item.title }}
                        <i class="fa fa-angle-down" v-if="item.subs.length > 0"></i>
                      </router-link>
                      <ul style="padding-left:15px" v-if="item.subs.length >0">
                        <li v-for="subItem in item.subs" :key="subItem.id">
                          <router-link style="font-weight:normal"
                          @click="toggleMenu(false)" :to="to(subItem, item.parent_id)">
                            {{ subItem.title }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div v-if="showScroller" class="up">
      <a v-if="info.companyInfo.PAGE_WHATSAPP" :href="'https://wa.me/90' + info.companyInfo.PAGE_WHATSAPP" target="_blank" class="wpp"><i class="fab fa-whatsapp"></i></a>
      <button @click="scrollToTop"><i class="fa fa-angle-up"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false,
      menuOpen: false,
      menu: this.info.menu,
      promoDesktop: this.$store.state.info.topPromoDesktop,
      promoMobile: this.$store.state.info.topPromoMobile,
      searchField: '',
      fileCdn: this.$store.state.fileCdn,
      showScroller: false,
      subMenus: [],
      subMenuParentId: 0
    }
  },
  mounted () {
    if (this.$fnc.getWidth() < 768) {
      document.addEventListener('scroll', this.setFixed)
    } else {
      document.addEventListener('scroll', this.setFixed2)
    }
    if (this.$route.params.search) {
      this.searchField = this.$route.params.search
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.setFixed)
    document.removeEventListener('scroll', this.setFixed2)
  },
  methods: {
    to (item, top) {
      let t = ''
      if (top) {
        t = top
      } else {
        t = item.parent_id
      }
      let q = {}
      q = {
        name: 'Products',
        params: {
          id: item.id + '-' + this.$fnc.seo(item.title),
          top: t
        }
      }
      return q
    },
    setSubMenus (item) {
      if (item.length > 0) {
        this.subMenus = item
        this.subMenuParentId = item[0].parent_id
        const subMenusContainer = document.getElementById('subMenusContainer')
        if (item.length > 12) {
          if (subMenusContainer) {
            subMenusContainer.classList.add('twoCols')
          }
        } else {
          if (subMenusContainer) {
            subMenusContainer.classList.remove('twoCols')
          }
        }
      } else {
        this.subMenus = []
        this.subMenuParentId = 0
      }
    },
    scrollToTop () {
      // scroll top of document that works on safari smoothly
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    setFixed: function (e) {
      const y = window.scrollY
      const th = document.querySelector('#toppHeader').clientHeight
      if (y > (th - 52)) {
        document.querySelector('.searchContainer').classList.add('searchFixed')
        document.querySelector('#topHeader').classList.add('h')
        if (this.$route.name !== 'Product') {
          this.showScroller = true
        }
      } else {
        document.querySelector('.searchContainer').classList.remove('searchFixed')
        document.querySelector('#topHeader').classList.remove('h')
        this.showScroller = false
      }
    },
    setFixed2: function (e) {
      const y = window.scrollY
      const th = document.querySelector('#toppHeader').clientHeight
      if (y > (th - 38)) {
        document.querySelector('.deskNav').classList.add('searchFixed2')
        document.querySelector('#deskNav').classList.add('h2')
        this.showScroller = true
      } else {
        document.querySelector('.deskNav').classList.remove('searchFixed2')
        document.querySelector('#deskNav').classList.remove('h2')
        this.showScroller = false
      }
    },
    toggleMenu: function (state) {
      this.$store.dispatch('setCatMenu', state)
      this.subMenus = []
      this.subMenuParentId = 0
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
#subMenusContainer {
  width:300px;
  padding:12px;
}
.twoCols {
  column-count:2;
  width:500px !important;
}
.up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 12;
  width: 40px;
  button {
    width:100%;
    height: 40px;
    color: #fff;
    border-radius: 4px;
    background: black;
    border: none;
    margin-top: 8px;
  }
  .wpp {
    background-color: #25d366;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display:grid;
    place-items: center;
  }
}
.dektopPromo {
  text-align: center;
  background: var(--color2);
  padding-top: calc(env(safe-area-inset-top));
}
.deskNav {
  background:var(--color1);
  margin-top: 0px;
  margin-bottom: 0px;
  height:40px;
  display: flex;
  align-items: center;
}
.desktopNav {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  a {
    color:#fff;
    display:flex;
    gap:8px;
    align-items:center;
    justify-content:center;
    padding-left:12px;
    padding-right:12px;
    margin-right: 12px;
    width: auto;
  }
  a:last-child {
    margin-right: 0px;
  }
}
.catsLink {
  width:140px;
  background:var(--color2);
}
.catsLink:hover {
  color: #fff;
  background:#565656;
}
.topMobileMenu {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  a {
    border-radius:3px;
    display:flex;
    align-items:center;
    width: auto;
    padding-right: 12px;
    background:#fff;
    margin-right: 8px;
    box-shadow: unset;
    padding-top: 3px;
    padding-bottom: 3px;
    div {
      padding-left:6px;
      font-size: 10px;
    }
  }
  a:last-child{
    margin-right: 0px;
  }
  .scooterIcon {
    width:24px;
    height:24px;
    display: block;
    background: url(../assets/icons/scooter.svg) no-repeat;
    background-size: 20px;
    background-position: center;
  }
  .mapIcon {
    width:24px;
    height:24px;
    display: block;
    background: url(../assets/icons/map.png) no-repeat;
    background-size: 20px;
    background-position: center;
  }
  .blogIcon {
    width:24px;
    height:24px;
    display: grid;
    place-items: center;
    background: none;
    background-size: 20px;
    background-position: center;
    i {
      font-size: 16px;
      color: #666;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.overlay {
  background:rgba(0,0,0,0.3); width:100%; height:100%; position:absolute; z-index:2; top:0; left:0;
}
.menu {
  position:absolute;
  z-index: 3;
  top:40px;
  box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.2);
  display: grid;
  grid-template-columns: 260px auto;
  background: #fff;
  .cont {
    padding:13px;
    border-radius:0px;
    background: #fff;
  }
  li {
    list-style:none;
    a {
      display:grid;
      grid-template-columns: auto 10px;
      align-items: center;
      gap: 12px;
      text-align:left;
      padding:5px;
      transition: 0.05s ease-out all;
      border-radius:3px;
      letter-spacing: 0px;
      font-size: 12px;
      color: #666;
    }
    a:hover, .activeLink {
      background:#cacaca;
    }
  }
}
.submenu {
  li {
    list-style:none;
    a {
      display:grid;
      grid-template-columns: auto 10px;
      align-items: center;
      gap: 12px;
      text-align:left;
      padding:8px;
      transition: 0.05s ease-out all;
      border-radius:3px;
    }
    a:hover {
      background:#cacaca;
    }
  }
}
.submenu2 {
  li {
    list-style:none;
    a {
      font-family: unset;
      text-transform: unset;
      letter-spacing: unset;
      font-family:var(--font1);
      font-weight: unset;
      display:grid;
      grid-template-columns: auto 10px;
      align-items: center;
      gap: 12px;
      text-align:left;
      padding:4px;
      font-size:13px;
      transition: 0.05s ease-out all;
      border-radius:3px;
    }
    a:hover {
      background:unset;
      text-decoration: underline;
      color: var(--color1);
    }
  }
}
.bold {
  font-weight:600 !important;
}
.secondRow {
  display: grid;
  grid-template-columns: auto 40px;
  gap: 15px;
}
.bs {
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
  transition: all 0.2s ease-out;
}
.bs:hover, .bs:focus {
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.22);
}
.catsButton {
  width: 100% !important;
  font-size:14px;
  border-radius:20px;
  letter-spacing:1px;
  text-transform:unset;
  grid-template-columns: 34px auto;
  align-items:center;
  justify-content:left;
}
.cartIcon {
  width:40px;
  height:40px;
  display: block;
  mask: url(../assets/icons/cart.svg) no-repeat;
  mask-size: 20px;
  mask-position: center;
  background-color: #000;
}
.cartCount {
  font-family: var(--font2);
  position: absolute;
  margin-left: 26px;
  background: red;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  display: grid;
  place-content: center;
}
.searchFixed {
  position:fixed;
  width: calc(100vw - 20px);
  top:0px;
  padding-top: calc(10px + calc(env(safe-area-inset-top)));
  left:10px;
  // padding-top:10px;
  padding-bottom:0px;
  background:var(--bodyBg);
  z-index: 11;
}
.searchFixed2 {
  position:fixed;
  top:0px;
  left:0px;
  width: 100%;
  background:var(--color1);
  z-index: 221;
}
.h {
  margin-bottom:40px;
}
.h2 {
  margin-bottom:38px;
}
.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  input{
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    background: white;
    border: none;
    height: 40px;
    width: 100%;
    padding:16px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.2s ease-out;
    padding-left: 40px;
    background: url(../assets/icons/mag.svg) no-repeat;
    background-size: 16px;
    background-position: 16px 12px;
    background-color: #fff;
  }
  input:focus, input:hover {
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.22);
  }
  input::placeholder {
    color: #c0c0c0;
  }
  a {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    background: white;
    border: none;
    height: 40px;
    width: 40px;
    display: grid;
    place-items:center;
  }
}
.cartButton {
  background: #fff;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  font-size: 16px;
  i {
    transition: all 0.2s ease-out;
    color: #666;
  }
}
.cartButton:hover {
  i {
    color: var(--color2);
  }
}
a {
  font-family: var(--font1);
  color: black;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.04px;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-out;
  white-space: nowrap;
}
a:hover, a.active {
  color: var(--color2);
}
.iosSafe {
  padding-top: calc(20px + env(safe-area-inset-top));
}
header {
  // padding-top: 20px;
  padding-bottom: 14px;
  padding-top: calc(20px);
  display: grid;
  grid-template-columns: 130px calc(100% - 430px) 300px;
  align-content: center;
  justify-items: left;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
.right {
  width: 100%;
  display: flex;
  gap: 15px;
  place-items: center;
  justify-content: flex-end;
  .primary {
    padding: 14px;
    background: var(--color2);
    color: white;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
  }
  .primary:hover {
    background-color: var(--color2);
    // box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
  }
}
.logo, .logoBayi, .logoKurumsal{
  width: 140px;
  height: 68px;
  display: grid;
  align-items:center;
}
.logo {
  background: url('../assets/logo.svg') center no-repeat;
  background-size: contain;
}
.logoBayi {
  background: url('../assets/logoBayi.png') center no-repeat;
  background-size: contain;
}
.logoKurumsal {
  background: url('../assets/logoKurumsal.png') center no-repeat;
  background-size: contain;
}
.sticky {
  width: calc(100% - 40px);
  margin-left: 20px;
  position: sticky !important;
  top: 20px;
  z-index: 4;
}
@media screen and (max-width: 1200px) {
  .desktopNav {
    a {
      span {
        display:none;
      }
    }
  }
}
@media screen and (max-width:768px) {
  .up {
    bottom: 60px;
    right: 10px;
  }
  .logo, .logoBayi {
    width: 110px;
    height: 58px;
  }
  .topMobileMenu{
    justify-content: flex-end;
  }
  .secondRow {
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
  }
  header {
    grid-template-columns: 120px auto;
  }
  .menu {
    .cont {
      margin-top:0px;
    }
  }
  .overlay {
    backdrop-filter: blur(20px);
  }
}
</style>
