<template>
  <div v-if="item.subs.length > 0" style="margin-bottom:12px">
    <p>Uyumlu Markaya Göre Arayın</p>
    <div class="bcc">
      <div class="brandContainer" :style="'width:' + width + 'px'">
        <div :class="{ 'activeBrand': item.id === $route.query.comp_brands }" @click="getModels(item)" :id="'brand' + idx" class="brand" v-for="(item, idx) in item.subs" :key="idx">
          <img v-if="item.image" :src="fileCdn + item.image">
          <span v-else>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <span v-if="brand_info !== ' '" class="chip">
      {{ brand_info }}
      <a @click="removeBrand">&times;</a>
    </span>
    <GlassModal v-if="showModelPicker" @close="closeModal">
      <template v-slot:title>Model Seçin</template>
      <template v-slot:body>
        <div v-if="mode === 'Models'">
          <div>
            <span v-if="brand.image" style="margin-right:12px"><img :src="fileCdn + brand.image"></span>
            <span v-else>{{ brand.title }}</span>
          </div>
          <div v-if="!loadedCats">
            <input
              v-if="models.length > 10"
              style="padding:10px;margin-top:12px; width:100%;border:1px solid #ccc;margin-bottom:12px"
              autocomplete="off"
              type="text"
              placeholder="Modellerde Ara"
              v-model="searchTerm"
              id="modelFilter">
            <div v-if="!loadingModels" style="height:240px; overflow:auto">
              <div class="model" v-if="!$route.params.top"><a @click="getCats('brand:' + brand.id)">Tüm Modeller</a></div>
              <div class="model" style="padding-left:5px;" v-for="item in filtered" :key="item.modelId">
                <router-link class="modelLink" :class="{ 'modelLinkActive': item.modelId === $route.query.comp_models }" v-if="cat_id" style="display:block"
                :to="{ name: 'Products', query: {
                  comp_brands: brand.id,
                  comp_models: item.modelId
                }}">
                  {{ item.modelAdi }}
                </router-link>
                <a v-else @click="getCats(item.modelId)">{{ item.modelAdi }}</a>
              </div>
            </div>
            <div v-else style="height:240px; overflow:auto;display:grid;justify-content:center">
              <img src="@/assets/loading-pulse.svg">
            </div>
          </div>
          <div v-else style="height:240px; overflow:auto">
            <div class="model" @click="closeCats">
              <a><i class="fa fa-caret-left"></i> Geri Dön</a>
            </div>
            <div v-if="catsLoaded">
            <div class="model" style="padding-left:10px" v-for="item in loadedCats" :key="item.id">
            <router-link class="modelLink" v-if="item.menuId"
            :to="{ name: 'Products', query: {
                  comp_brands: brand.id,
                  comp_models: item.modelId
                }, params: {
                  id: item.menuId + '-' + item.catTitle,
                  top: item.parentId
                }}"
            >
              {{ item.title }}
            </router-link>
            <a v-else></a>
            </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="cats.length > 0" style="height:240px; overflow:auto">
            <div v-for="item in cats" :key="item.id" class="model">
             <router-link class="modelLink"
             :to="{ name: 'Products',
             query: {
               brand: brand.brand.ureticiMarka,
             },
             params: {
               id: item.id + '-' + $fnc.seo(item.title),
               top: item.id
              }}"
            >
              {{ item.title }}
            </router-link>
            </div>
          </div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['item', 'cat_id', 'brand_info'],
  mounted () {
    this.width = ((this.item.count) * 76) - 12
    if (this.bounce) {
      setTimeout(() => {
        document.querySelector('#brand7').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
      }, 400)
      setTimeout(() => {
        document.querySelector('#brand0').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
      }, 800)
    }
  },
  watch: {
    $route (to, from) {
      this.closeModal()
    }
  },
  computed: {
    filtered () {
      return this.models.filter(item => {
        return item.modelAdi.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    }
  },
  methods: {
    removeBrand () {
      this.$router.push({
        name: 'Products'
      })
    },
    getCats: async function (modelId) {
      this.loadedCats = []
      const payload = {
        user: this.$store.state.user,
        modelId: modelId
      }
      await axios.post('api/getModelCats.php', payload).then(response => {
        this.loadedCats = response.data.data
        this.catsLoaded = true
      })
    },
    closeCats: function () {
      this.loadedCats = false
    },
    getModels: async function (brand) {
      this.brand = brand
      this.showModelPicker = true
      this.loadingModels = true
      this.models = []
      this.mode = 'Models'
      const payload = {
        user: this.$store.state.user,
        brandId: brand.id,
        brand: brand.brand,
        catId: parseInt(this.cat_id)
      }
      await axios.post('api/getModels.php', payload).then(response => {
        this.models = response.data.data
        this.loadingModels = false
        this.mode = response.data.mode
        if (this.mode === 'Cats') {
          this.cats = response.data.cats
        }
      })
    },
    closeModal: function () {
      this.showModelPicker = false
      this.brand = {}
      this.models = []
      this.loadedCats = false
      this.loadingModels = true
    }
  },
  data () {
    return {
      fileCdn: this.$store.state.fileCdn + '/',
      width: 0,
      showModelPicker: false,
      brand: {},
      models: [],
      bounce: false,
      loadingModels: true,
      loadedCats: false,
      catsLoaded: false,
      searchTerm: '',
      mode: 'Models',
      cats: [],
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: false,
        arrows: false,
        pagination: true,
        lazyLoad: false,
        autoplay: false,
        type: 'loop',
        keyboard: false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chip {
  border:1px solid #ccc; font-size:12px;padding:4px; background:#fff;cursor:defualt;user-select:none;
}
p {
  margin-bottom: 6px;
  font-weight: bold;
}
.model {
  border-bottom: 1px solid #ccc;
  width: calc(100% - 20px);
  a {
    display: block;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  a:hover {
    color: orange;
    width: 100%;
    text-decoration: underline;
  }
}
.brandContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 64px;
  overflow: hidden;
  width: 100%;
}
.brand {
  margin-right:12px;
  border: 2px solid #fff;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  height: 64px;
  width: 64px;
  display:grid;
  place-items: center;
  font-size: 10px;
  cursor: pointer;
}
.modelLink {
  color:#666;
}
.modelLinkActive {
  color:var(--color1);
}
.brand:hover, .activeBrand {
  border:2px solid var(--color1);
}
.brand:last-child {
  margin-right: 0px;
}

.bcc {
  overflow:auto;
  min-height:75px;overflow-x:scroll;
}
.bcc::-webkit-scrollbar {
  display: none;
}
.bcc {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@media screen and (max-width: 768px) {
  .bcc {
    width: calc(100vw - 20px);
  }
}
</style>
