<template>
  <div v-if="!$parent.isLoading">
    <div class="priceBox" v-if="totalPrice > 0">
    <div style="display:none">
      <div v-if="$parent.stockInCart > 0" style="color:green">Sepetinizde {{ $parent.stockInCart }} adet var</div>
      <div style="color:red;font-weight:bold;" v-if="$parent.max < 5">{{ $tc('StokUyarı', $parent.max) }}</div>
    </div>
    <div class="grd" v-if="totalPrice > 0">
      <div class="qtySelector">
        <div class="selector">
          <button @click="decrease">-</button>
          <input class="qty" v-model.number="$parent.qty" min="1" :max="($parent.max - $parent.stockInCart)" type="tel">
          <button @click="increase">+</button>
        </div>
      </div>
      <div class="bigPrice" style="">
        <div v-if="$parent.product.listPrice > totalPrice" style="display:flex; place-items:center; height:30px;" >
          <div class="discountRateBox">% {{ $parent.product.discountRate }}</div>
          <div class="listPrice">{{ $parent.product.listPrice.toFixed(2) }}{{ $parent.product.cur }}</div>
        </div>
        <div>
          {{ totalPrice.toFixed(2) }}{{ $parent.product.cur }}
        </div>
      </div>
      <div style="display:none" class="kargo">{{ $t('Ücretsiz Kargo') }}</div>
      <div v-if="!isSaving">
        <button v-if="$parent.max > 0" @click="addToCart" class="sptButton2"><i class="fa fa-shopping-basket" style="font-size: 16px;"></i> {{ $t('Sepete Ekle') }}</button>
        <button v-else class="sptButton2"><i class="fa fa-minus-circle" style="font-size: 16px;"></i> {{ $t('Stokta yok') }}</button>
      </div>
      <div v-else>
        <button class="sptButton2" style="cursor:default;background-color:red"><span class="loadingCart"></span>{{ $t('Ekleniyor') }}</button>
      </div>
        <div v-if="$store.state.width < 768" style="display:none">
          <button @click="$parent.goCart" class="sptButton1">
            <i class="fa fa-shopping-cart"></i>
            <span v-if="$store.state.cart">{{ $store.state.cart }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['totalPrice', 'isSaving'],
  methods: {
    increase () {
      if (this.$parent.qty < this.$parent.max) {
        this.$parent.qty++
      }
    },
    decrease () {
      if (this.$parent.qty > 1) {
        this.$parent.qty--
      }
    },
    addToCart: function () {
      this.$parent.addToCart()
    }
  },
  watch: {
    $route (to, from) {
      this.$parent.calculate()
    }
  }
}
</script>
<style>
.pz-zoom-button {
  z-index: 1 !important;
}
.splide__slide {
  z-index: 0 !important;
}
</style>
<style scoped lang="scss">
.qtySelector {
  display:grid;
  grid-template-columns: auto;
  place-items:center;
  border-radius:5px;
  gap: 6px;
  .selector {
    border-radius: 6px;
    overflow: hidden;
    background:#ccc;
    border:1px solid #ccc;
    display: grid;
    grid-template-columns: 30px 60px 30px;
    button {
      border:none;
      background:none;
    }
    .qty {
      text-align:center;
      border:1px solid #ccc;
      height:24px;
    }
    .qty:focus {
      outline:none;
      box-shadow: unset;
    }
  }
}
.discountRateBox{
  height:20px;
  width:30px;
  font-size:9px;
  background:red;
  color:white;
  display:grid;
  place-items:center;
}
.listPrice {
  font-weight:bold;
  font-size:14px;
  color:#666;
  text-align:left;
  text-decoration:line-through;
}
.priceBox {
  // background: #fff;
  // padding: 10px;
  // margin-top:12px;
  place-items: center;
  justify-items: left;
  width: 100%;
}
.grd {
  width: 100%;
  place-items: center;
  display:grid;
  gap: 15px;
  grid-template-columns: 105px auto 120px;
}
@media screen and (max-width:768px) {
  .priceBox {
    border-top: 1px solid #ccc;
    position: unset;
    height: unset;
    position: fixed;
    bottom: 40px;
    left:0;
    display:grid;
    border-radius: 0;
    z-index: 1001 !important;
    padding-top:5px;
    padding-bottom: 20px;
    // padding-bottom: calc(25px + env(safe-area-inset-bottom));
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
  }
  .grd {
    width: 100%;
    place-items: center;
    display:grid;
    gap: 12px;
    grid-template-columns: 105px auto 120px;
  }
}
.sptButton1{
  display: flex;
  place-items: center;
  gap: 2px;
  justify-content: center;
  background: var(--color2);
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  transition: all 0.1s;
  span {
    background:red;
    width:16px;
    height: 16px;
    border-radius: 10px;
    display:grid;
    place-items: center;
    font-size:9px;
    font-family: var(--font3);
    font-weight: bold;
  }
}
.sptButton2{
  display: flex;
  place-items: center;
  gap: 5px;
  justify-content: center;
  background: var(--color1);
  border: none;
  outline: none;
  font-size: 14px;
  font-family: var(--font3);
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  transition: all 0.1s;
}
.sptButton2:hover {
  filter: brightness(var(--bri));
}
.topFiyat {
  font-size: 11px;
}
.bigPrice {
  font-size: 22px !important;
  color: var(--color1);
  font-weight: bold;
  width: 100%;
  font-family: var(--font2);
  display: flex;
  place-items: center;
}
.kargo {
  margin-top: 3px;
  margin-bottom: 12px;
  color: #226e4b;
  font-size: 13px !important;
  font-weight: bold;
}
.loadingCart {
  background:url(~@/assets/loading-cart.gif) center no-repeat;
  display:inline-block;
  width:30px;
  height:13px;
}
</style>
