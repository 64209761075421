<template>
  <div>
    <Header :info="info" />
    <Campaign />
    <Footer :info="info" />
  </div>
</template>

<script>
import Campaign from '@/components/Campaign'
export default {
  props: ['info'],
  components: { Campaign },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: this.$store.state.baseUrl,
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: this.$store.state.baseUrl + '/tr/ara/{search_term_string}'
        },
        'query-input': 'required name=search_term_string'
      }
    }
  }
}
</script>
