<template>
  <div>
    <div class="productTitle">
      <h1>{{ product.title }}</h1>
      <star-rating :round-start-rating="false" v-model="$parent.rating.average" :star-size="14" :show-rating="false" :read-only="true"></star-rating>
    </div>
    <div v-if="!isLoading" class="productDetail">
      <div>
        <div v-if="product.menuInfo" style="font-size: 12px;">
           {{ product.description }}
        </div>
        <div class="combs" style="margin-bottom:12px;" v-if="product.combinations.length > 1">
          <div :class="{ 'active': item.urunlerBarkod === product.barcode, 'nope': item.stock < 1 }" v-for="item in product.combinations" :key="item.urunlerId">
            <router-link :to="{ name: 'Product', params: {pid: item.urunlerId + '-' + $fnc.seo(item.urunlerAdi) } }">
              <img :title="item.urunlerAdi" :src="mediaCdn + JSON.parse(item.images)[0].thumb" v-if="JSON.parse(item.images)[0].thumb" width="100%">
              <img :title="item.urunlerAdi" :src="mediaCdn + JSON.parse(item.images)[0].source" v-else width="100%">
              {{ JSON.parse(item.attributes)[0].value }}
            </router-link>
          </div>
        </div>
        <div class="info">
          <ProductPriceNormal style="margin-bottom: 16px" v-if="$store.state.width > 768" :totalPrice="totalPrice" :vat="product.kdvOran" :isSaving="isSaving" />
          <div class="icons">
            <div class="icon" v-for="item in $store.state.info.productIcons" :key="item.id">
              <img :src="fileCdn + item.image" :alt="item.title" :title="item.title" width="100%">
            </div>
          </div>
          <div class="row"><div>Marka</div><div>{{ product.brandName }}</div></div>
          <div class="row" v-if="product.compatbile_models.length > 0">
            <div>Uyumlu Modeller</div>
            <div>
              <span class="model" v-for="m in this.product.compatbile_models" :key="m.id">{{ m.marka_adi }} {{ m.model_adi }}</span>
            </div>
          </div>
          <div class="row"><div>Stok Kodu</div><div>{{ product.sku }}</div></div>
          <div class="row"><div>Barkod</div><div>{{ product.barcode }}</div></div>
          <div class="row" v-if="$store.state.user.customerGroup !== 26">
            <div>Stok Adeti</div>
            <div>{{ max }} Adet <button class="btn1" @click="showStoreStocks(product.id)">Stok Durumu</button></div>
          </div>
          <div style="display:flex; gap:12px; margin-top:18px;">
            <button class="btn" @click="gotoDown('tabs', 1)" style="margin:0">Ürün Özellikleri</button>
            <button class="btn" @click="gotoDown('tabs', 2)" style="margin:0">Ürün Yorumları</button>
          </div>
        </div>
      </div>
      <div v-if="$store.state.width < 768">
        <ProductPriceNormal :totalPrice="totalPrice" :vat="product.kdvOran" :isSaving="isSaving" />
      </div>
    </div>
    <GlassModal  v-if="stockStores" @close="closeStockModal">
      <template slot="title">Stok Durumu</template>
      <template slot="body">
        <div class="stockRow header">
          <div>Mağaza / Depo</div>
          <div style="text-align:center">Stok Miktarı</div>
        </div>
        <div v-for="item in stockStores" :key="item.id" class="stockRow">
          <div>{{ item.storeName }}</div>
          <div  style="text-align:center">{{ item.stock }} Ad.</div>
        </div>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
import ProductPriceNormal from '@/small-components/ProductPriceNormal.vue'

export default {
  props: ['product'],
  components: { ProductPriceNormal },
  data: function () {
    return {
      pid: this.product.id,
      bid: 0,
      isLoading: false,
      isSaving: false,
      productType: '',
      selectedVariant: null,
      kdv: 0,
      termin: 0,
      price: 0,
      totalPrice: 0,
      qty: 1,
      max: this.product.avl,
      stockInCart: 0,
      mediaCdn: this.$store.state.mediaCdn,
      fileCdn: this.$store.state.fileCdn,
      stockStores: false
    }
  },
  mounted () {
    if (this.product.variants) {
      const variants = this.product.variants
      const found = variants.find(element => element.stock > 0)
      if (found) {
        this.selectVariant(found)
      }
    } else {
      this.selectedVariant = null
      this.max = this.product.avl
      this.totalPrice = this.product.price
      this.stockInCart = this.product.stockInCart
      this.calculate()
    }
  },
  watch: {
    $route (to, from) {
      this.qty = 1
      setTimeout(() => {
        this.calculate()
      }, 100)
    }
  },
  methods: {
    gotoDown: function (div, tabId) {
      const item = {
        id: tabId,
        title: ''
      }
      this.$parent.selectTab(item)
      const el = document.getElementById(div)
      const top = el.offsetTop
      // smooth scroll to element
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    },
    showStoreStocks: async function (pid) {
      const payload = {
        pid: pid,
        user: this.$store.state.user
      }
      this.stockStores = false
      await axios.post('api/store-stocks.php', payload).then(response => {
        this.stockStores = response.data
      })
    },
    closeStockModal: function () {
      this.stockStores = false
    },
    validate: function () {
      if (this.max === 0) {
        this.$toast.error('Bu ürün şu anda tedarik edilememektedir')
        return false
      }
      if (this.qty < 1) {
        this.qty = 1
        this.$toast.error('En az 1 adet ekleyebilirsiniz')
        return false
      }
      if (this.qty > (this.max - this.stockInCart)) {
        if ((this.max - this.stockInCart) <= 0) {
          this.$toast.error('Stok durumundan dolayı hiç ekleyemezsiniz')
        } else {
          this.$toast.error('En fazla ' + (this.max - this.stockInCart) + ' adet ekleyebilirsiniz')
        }
        return false
      }
      if (!Number.isInteger(this.qty)) {
        // this.$toast.warning('notInteger')
        this.qty = 1
      }
      return true
      // this.calculate()
    },
    increase: function () {
      if (this.qty < (this.max - this.stockInCart)) {
        this.qty = this.qty + 1
        this.calculate()
      }
    },
    decrease: function () {
      if (this.qty > 1) {
        this.qty = this.qty - 1
        this.calculate()
      }
    },
    calculate: function () {
      if (this.selectedVariant) {
        this.totalPrice = this.selectedVariant.price
        this.max = this.selectedVariant.stock
        this.pid = this.product.id
        this.bid = this.selectedVariant.id
      } else {
        this.totalPrice = this.product.price
        this.max = this.product.avl
        this.pid = this.product.id
      }
    },
    addToCart: async function (qty) {
      const v = this.validate()
      console.log(v)
      if (!v) {
        console.log('notValite')
        return
      }
      this.isSaving = true
      const payload = {
        pid: parseInt(this.pid),
        bid: parseInt(this.bid),
        qty: parseInt(this.qty),
        productType: this.productType,
        user: this.$store.state.user
      }
      await axios.post('api/addToCart.php', payload).then(response => {
        this.stockInCart = response.data.inCart
        setTimeout(() => {
          this.$store.dispatch('setCart', response.data.cartCount)
          this.isSaving = false
          /* setTimeout(() => {
            this.$parent.itemDetails()
          }, 250) */
        }, 500)
        const items = [{
          id: 'p' + this.pid + 'b' + 0,
          name: this.product.title.trim(),
          brand: this.product.brandName.trim(),
          category: this.product.googleCategoryName.trim(),
          variant: '',
          quantity: this.qty,
          price: this.totalPrice
        }]
        this.$gtag.event('add_to_cart', { items })
        this.$toast.success('Sepetinize Eklendi, sepetinize gitmek için buraya tıklayın', {
          position: 'top',
          onClick: () => {
            this.goCart()
          }
        })
      })
    },
    goCart: function () {
      this.$router.push({ name: 'Cart' })
    },
    selectVariant: function (variant) {
      if (variant.stock < 1) {
        return
      }
      if (this.selectedVariant !== variant) {
        this.qty = 1
      }
      this.selectedVariant = variant
      this.stockInCart = this.selectedVariant.stockInCart
      if (this.selectedVariant.images.length > 0) {
        this.$parent.product.images = this.selectedVariant.images
      } else {
        this.$parent.product.images = this.product.fullImages
      }
      this.calculate()
    }
  }
}
</script>
<style lang="scss">
.btn {
  border: 1px solid var(--color2);
  background:  #fff;
  color: var(--color2);
  padding: 12px;
  border-radius: 3px;
  transition: all 0.1s ease-in-out;
}
.btn:hover {
  background: var(--color2);
  color: #fff;
}
.icons {
  display:flex;
  gap:8px;
  padding-bottom: 16px;
  .icon {
    width: 130px;
    height: 60px;
    display: grid;
    place-items: center;
  }
}
.stockRow {
  display:grid; grid-template-columns: auto 110px;
  border-bottom: 1px solid #ccc;
  padding:6px;
}
.header {
  background :#5c5c5c;
  color:white;
  font-weight: bold;
}
.btn1 {
  margin-left:12px; padding:6px; border:none; background:none;
  background-color: var(--color2);
  border-radius: 4px;
  cursor: pointer;
  color:white;
}
.specs {
  .mt-5 {
    h3 {
      display:none;
      font-size:13px;
      margin-top: 12px;
      margin-bottom: 2px;
    }
  }
  th {
    font-size: 11px;
    font-weight: 600;
    color: black;
    text-align: left;
    font-size: 12px;
    padding: 6px !important;
  }
  td {
    font-size: 11px;
    padding: 6px !important;
  }
}
</style>
<style scoped lang="scss">
.info {
  // border:1px solid #ccc;
  font-size:13px;
  margin-bottom: 12px;
  .row {
    display:grid;
    align-items: center;
    grid-template-columns: 120px auto;
    margin-top: 3px;
    margin-bottom: 3px;
    padding:4px;
    div:first-child{
      font-weight: 600;
    }
  }
  .row:nth-child(odd) {
    background: #efefef;
  }
  .model::after {
    content: ', ';
  }
  .model:last-child::after {
    content: '';
  }
}
.combs {
  display:grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  a {
    border: 1px solid #ccc;
    background: #fff;
    padding: 4px;
    display:grid;
    place-items: center;
    font-size:11px;
    color:#666;
  }
  .active {
    border: 1px solid var(--color1);
  }
  .nope {
    display:none;
  }
}
.qtyRow {
  margin-top: 20px;
  display:grid;justify-content: center;align-items:center;
  gap:12px;
  p {
    text-align: center;
  }
  .qtySelector {
    display: grid;
    grid-template-columns:28px 80px 28px;
    height: 30px;
    // border: 1px solid #ccc;
    background: #ccc;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
    button {
      display: grid;
      place-items: center;
      height: 100%;
      border: none;
      width: 100%;
      background: #dadada;
      i {
        color:#666;
      }
    }
    button:hover {
      background: var(--color1);
      i {
        color:#fff;
      }
    }
    input {
      border:none;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      height: 100%;
      text-align:center;
    }
    input:focus {
      box-shadow: unset;
    }
  }
}
.productDetail {
  display:grid;
  grid-template-columns:auto;
  height: 100%;
  gap:20px;
}
.productTitle {
  h1 {
    font-weight: 600;
    color:var(--black1);
    text-align: left;
    font-size:1.1rem;
    line-height: 1.6rem;
    font-display: swap;
    user-select: text;
    font-family: var(--font2);
  }
  padding-bottom: 10px;
}
@media screen and (max-width:768px) {
  .combs {
    display:grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .productDetail {
    grid-template-columns: 1fr;
    height: unset;
  }
}
.selections {
  display:grid;
  grid-template-columns: 1fr;
  place-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  div {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
  }
  .buttonset {
    display:flex;
    align-content: center;
    gap:10px;
  }
  .title {
    display: grid;
    grid-template-columns:1fr;
    align-items: center;
    color: #555;
  }
  .title i {
    display:none;
  }
}
.buttonset button {
  width: 100%;
  border:2px dashed #ccc;
  color: grey;
  background: #fff;
  border-radius: 4px;
  font-size: 11px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0px;
  outline: none;
  display: grid;
  gap: 2px;
  place-items: center;
  box-shadow:0px 4px 12px -8px rgba(0, 0, 0, 0.4) !important;
}
.buttonset button i {
  font-size: 26px;
  margin-top: 4px;
}
.buttonset button:hover {
  border: 2px solid rgb(136, 136, 136);
  color: black;
}
.buttonset button:focus, .buttonset button:active, .activeButton {
  border: 2px solid #40bbf4 !important;
  color: black !important;
}
.adets {
  background:#fafafa;
  padding: 15px;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 6px;
}
.adetRow {
  display: grid;
  grid-template-columns: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.options {
  display: flex;
  gap:12px;
  margin-top: 8px;
  div {
    user-select: none;
    border:2px solid #ccc;
    display: grid;
    place-items: center;
    gap: 0px;
    font-weight: bold;
    margin:0px;
    padding:4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    cursor: pointer;
  }
  div.active {
    border: 2px solid var(--color1);
    color: var(--color1);
  }
  div.notInStock {
    border-color: #bebebe;
    color: #bebebe;
    text-decoration: line-through;
    cursor: not-allowed;
  }
}
.adetci {
  display: grid;
  place-items: center;
  gap: 0px;
  font-size: 10px !important;
  font-weight: bold;
  margin:0px;
  padding:0px;
}
.rakamci {
  text-align: center;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #d4d4d4;
  cursor: pointer;
  transition: all 0.3s;
}
.rakamci .kdvHaric {
  font-size: 20px !important;
  font-weight: bold;
  line-height:20px;
}
.rakamci .kdvDahil{
  font-size: 11px !important;
  font-weight: bold;
  line-height:11px;
}
.rakamci:hover {
  border: 2px solid #979797;
}
.rakam {
  font-weight: bold;
  font-size: 13px;
  line-height: 13px !important;
}
.adet {
  font-size: 11px;
  line-height: 11px !important;
}
</style>
