<template>
  <div class="techContainer">
    <div>
      <a @click="$parent.setMode('query')">
        <img v-if="image1" width="100%" :src="fileCdn + image1.source">
      </a>
    </div>
    <div>
      <a @click="$parent.setMode('request')">
        <img v-if="image2" width="100%" :src="fileCdn + image2.source">
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default ({
  data: function () {
    return {
      image1: '',
      image2: '',
      fileCdn: this.$store.state.fileCdn
    }
  },
  mounted: function () {
    this.getImage1()
    this.getImage2()
  },
  methods: {
    getImage1: async function () {
      const response = await axios.get('api/getTechnicImage.php?image=teknikSorgu')
      this.image1 = response.data
    },
    getImage2: async function () {
      const response = await axios.get('api/getTechnicImage.php?image=teknikKayit')
      this.image2 = response.data
    }
  }
})
</script>

<style scoped lang="scss">
.techContainer {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background: #fff;
  border:1px solid #ccc;
  padding: 20px;
  img {
    border-radius: 4px;
  }
}
@media screen and (max-width: 768px) {
  .techContainer {
    display:grid;
    grid-template-columns: 1fr;
    border:none;
    padding: 0px;
  }
}
</style>
