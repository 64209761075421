<template>
  <div>
    <Header :info="info" />
    <div class="container" v-if="page">
      newpagecreated
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.otherPages {
  grid-template-columns: 1fr !important;
}
.catalog {
  display: grid;
  grid-template-columns: 200px auto;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 400px);
  .filters {
    border-right:1px solid #ddd;
    padding-right:20px;
    user-select: none;
    .block {
      font-size: 13px;
      line-height: 22px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      p {
        font-weight: bold;
        font-size: 15px;
      }
      ul {
        user-select: none;
        list-style:none;
        padding-left: 12px;
        li{
          a {
            font-size: 13px;
            color:#666;
          }
          a:hover, .active {
            color:var(--color1);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .catalog {
    grid-template-columns: 1fr;
    .filters {
      display:none;
    }
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>

<script>
export default {
  props: ['info'],
  data () {
    return {
      id: null,
      parent: null,
      pageType: null,
      page: {},
      otherPages: []
    }
  },
  metaInfo () {
    return {
      title: this.page.title,
      meta: [{ vmid: 'description', name: 'description', content: this.page.description }]
    }
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.params.id
      this.parent = this.$route.params.parent
      this.pageType = this.$route.name
      this.page = null
      // this.getPage()
    }
  },
  methods: {}
}
</script>
