import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Products from '../views/Products.vue'
import Product from '../views/Product.vue'
import Page from '../views/Page.vue'
import BayiSozlesme from '../views/BayiSozlesme.vue'
import Barcode from '../views/ProductBarcode.vue'
import PageGroup from '../views/PageGroup.vue'
import TeknikServis from '../views/TeknikServis.vue'
import TeknikServisSorgu from '../views/TeknikServisSorgu.vue'
import Bayilik from '../views/Bayilik.vue'
import Kurye from '../views/Kurye.vue'
import Campaigns from '../views/Campaigns.vue'
import Campaign from '../views/Campaign.vue'
import Blogs from '../views/Blogs.vue'
import Franchise from '../views/Franchise.vue'
import Contacts from '../views/Contacts.vue'
import Stores from '../views/Stores.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import Cart from '../views/Cart.vue'
import CompleteOrder from '../views/CompleteOrder.vue'
import MyAccount from '../views/MyAccount.vue'
import Thanks from '../views/Thanks.vue'
import ErrorPage from '../views/ErrorPage.vue'
import NotFound from '../views/404.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

let locale = ''
const savedLocale = localStorage.getItem('lang')
if (savedLocale) {
  locale = savedLocale
} else {
  const bLocale = window.location.pathname.replace('/', '').trim().substring(0, 2)
  const langs = ['tr']
  if (langs.includes(bLocale)) {
    locale = bLocale
  } else {
    locale = 'tr'
  }
}
i18n.locale = locale

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  }, {
    path: '/:lang/' + i18n.t('nasil-calisir'),
    name: 'HowItWorks',
    component: Contacts
  }, {
    path: '/:lang/' + i18n.t('cok-satanlar'),
    name: 'BestSellers',
    component: Contacts
  }, {
    path: '/:lang/c/:top/:id',
    id: 0,
    t: '',
    brands: '',
    filters: '',
    comp_brands: '',
    comp_models: '',
    name: 'Products',
    component: Products,
    props: true
  }, {
    path: '/:lang/' + i18n.t('ara') + '/:search',
    search: '',
    name: 'Search',
    component: Search,
    props: true
  }, {
    path: '/:lang/p/:pid',
    name: 'Product',
    component: Product,
    props: true
  }, {
    path: '/barkod',
    name: 'Barcode',
    component: Barcode,
    props: true
  }, {
    path: '/:lang/pg/:parent/:id',
    name: 'Page',
    component: Page,
    props: true
  }, {
    path: '/bayi-sozlesme',
    name: 'BayilikSozlesme',
    component: BayiSozlesme,
    props: true
  }, {
    path: '/:lang/pgs/:parent/',
    name: 'PageGroup',
    component: PageGroup,
    props: true,
    children: [
      {
        path: ':id',
        name: 'SubPage',
        props: true
      }
    ]
  }, {
    path: '/:lang/' + i18n.t('tesekkurler'),
    name: 'Thanks',
    component: Thanks,
    props: true
  }, {
    path: '/:lang/hata',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true
  }, {
    path: '/:lang/' + i18n.t('iletisim'),
    name: 'Contacts',
    component: Contacts,
    props: true
  }, {
    path: '/:lang/' + i18n.t('magazalarimiz'),
    name: 'Stores',
    component: Stores,
    props: true
  }, {
    path: '/:lang/' + i18n.t('giris-yapin'),
    name: 'Login',
    component: Login
  }, {
    path: '/:lang/' + i18n.t('nettech-bayisi-olun'),
    name: 'Register',
    component: Login
  }, {
    path: '/:lang/' + i18n.t('sifrenizi-sifirlayin'),
    name: 'ForgetPassword',
    component: Login
  }, {
    path: '/:lang/' + i18n.t('sepetim'),
    name: 'Cart',
    component: Cart
  }, {
    path: '/:lang/nettech-franchise',
    name: 'Franchise',
    component: Franchise
  }, {
    path: '/:lang/nettech-bayilik',
    name: 'Bayilik',
    component: Bayilik
  }, {
    path: '/technic-service',
    name: 'Teknik',
    component: TeknikServis
  }, {
    path: '/service-status/:id',
    name: 'TeknikStatus',
    component: TeknikServis
  }, {
    path: '/service-query/',
    name: 'TeknikSorgu',
    component: TeknikServisSorgu
  }, {
    path: '/:lang/kurye',
    name: 'Kurye',
    component: Kurye
  }, {
    path: '/:lang/kampanyalar',
    name: 'Campaigns',
    component: Campaigns
  }, {
    path: '/:lang/kampanyalar/:id',
    name: 'Campaign',
    component: Campaign,
    props: true
  }, {
    path: '/:lang/blog',
    name: 'Blogs',
    component: Blogs
  }, {
    path: '/:lang/blog/etiket/:tag',
    name: 'Tag',
    component: Blogs
  }, {
    path: '/:lang/' + i18n.t('siparisi-tamamla'),
    name: 'CompleteOrder',
    component: CompleteOrder
  }, {
    path: '/:lang/' + i18n.t('hesabim'),
    name: 'MyAccount',
    component: MyAccount,
    props: true,
    meta: {
      title: 'Hesabım'
    },
    children: [
      {
        path: i18n.t('siparislerim'),
        name: 'MyAccountOrders',
        props: true,
        meta: {
          title: 'Siparişlerim'
        }
      }, {
        path: i18n.t('uyelik-bilgilerim'),
        name: 'MyAccountProfile',
        props: true,
        meta: {
          title: 'Üyelik Bilgilerim'
        }
      }, {
        path: i18n.t('adreslerim'),
        name: 'MyAccountAddresses',
        props: true,
        meta: {
          title: 'Adres Bilgilerim'
        }
      }, {
        path: i18n.t('sifre-degis'),
        name: 'MyAccountPassword',
        props: true,
        meta: {
          title: 'Şifrenizi Değiştirin'
        }
      }, {
        path: i18n.t('cari-hesabim'),
        name: 'MyAccountCariHesabim',
        props: true,
        meta: {
          title: 'Cari Hesabım'
        }
      }, {
        path: i18n.t('cari-odeme'),
        name: 'MyAccountCariOdeme',
        props: true,
        meta: {
          title: 'Cari Ödeme'
        }
      }
    ]
  }, {
    path: '/:catchAll(.*)',
    // path: '/:lang/404.html',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    console.log('noMatcjh')
    next({ name: '404', params: { lang: locale } })
  } else {
    to.params.lang = locale
    next()
  }
})

export default router
