<template>
  <div v-if="$parent.showReviewModal">
    <div v-if="!user.email">
      <GlassModal @close="closeReviewModal">
        <template v-slot:title>Yorum Yapabilmek İçin Giriş Yapın</template>
        <template v-slot:body>
          <router-link :to="{ name: 'Login'}">Giriş yapmak için tıklayın</router-link>
        </template>
      </GlassModal>
    </div>
    <div v-else>
      <GlassModal @close="closeReviewModal">
        <template v-slot:title>Ürünü Değerlendirin</template>
        <template v-slot:body>
          <div v-if="!isSavingReview">
            <div style="display:grid; gap:6px; align-items:center">
              <p style="font-weight:bold;font-family:arial" v-html="product.title"></p>
            </div>
            <div v-if="review.c_id > 0" style="display:grid;place-items:center;padding-top:60px;padding-bottom:60px;text-align:center">
              <p><i style="color:green;font-size:64px" class="fa fa-check-circle"></i></p>
              <h3 style="margin-top:22px;">Değerlendirmeniz bize başarıyla ulaştı</h3>
              <p style="font-size:13px;margin-top:12px">Yorumlarınız
                <router-link style="color:red" target="_blank" :to="{ name: 'Page', params: { id: 61 + '-' + $fnc.seo('ön-bilgilendirme-metni'), parent: 1 }}">Ön Bilgilendirme Metni</router-link>
              'nde belirtilen kapsamda işlenecek ve yayınlanacaktır.</p>
            </div>
            <div v-else>
              <div style="display:grid;place-items:center;padding:9px; min-width:320px">
                <star-rating v-model="review.rating" :star-size="30" :show-rating="false" />
              </div>
              <div style="margin-top:8px">
                <p>Ürün hakkındaki düşünceleriniz</p>
                <textarea v-model="review.comment" rows="5" style="width:100%; border-radius:6px;margin-top:12px;margin-bottom:12px;border-color:#ccc;padding:12px"></textarea>
              </div>
              <div style="margin-bottom:12px">
                <p style="margin-bottom:8px;" v-if="review.showUserName">
                  {{ userName[0].split('')[0] }}****
                  <span v-if="userName[1]">
                    {{ userName[1].split('')[0] }}****
                  </span>
                  <span v-if="userName[2]">
                    {{ userName[2].split('')[0] }}****
                  </span>
                </p>
                <p style="margin-bottom:8px;" v-else>
                  {{ userName[0] }}
                  <span v-if="userName[1]">
                    {{ userName[1].split('')[0] }}****
                  </span>
                  <span v-if="userName[2]">
                    {{ userName[2].split('')[0] }}****
                  </span>
                </p>
                <label>
                  <input v-model="review.showUserName" type="checkbox">
                  İsmim Görünmesin
                </label>
              </div>
              <p style="font-size:13px;margin-top:12px;margin-bottom:12px">Yorumlarınız
                <router-link style="color:red" target="_blank" :to="{ name: 'Page', params: { id: 287 + '-' + $fnc.seo('kvkk-bilgilendirme'), parent: 7 }}">KVKK Metni</router-link>
              'nde belirtilen kapsamda işlenecek ve yayınlanacaktır.</p>
              <button style="font-size:12px;padding:13px;margin:0" @click="saveReview" class="btn1" :class="{ 'fileUploadBtn': review.rating > 0 }">
                <i style="margin-right:5px" class="fa fa-save"></i>
                Değerlendirmeyi Kaydet
              </button>
            </div>
          </div>
          <div v-else style="display:grid; place-items:center; min-height:200px; min-width:320px">
            <img src="@/assets/loading-pulse.svg">
          </div>
        </template>
      </GlassModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['product'],
  data () {
    return {
      isSavingReview: false,
      savedReviewId: 0,
      review: {}
    }
  },
  computed: {
    userName () {
      return this.$store.state.user.userName.split(' ')
    },
    user () {
      return this.$store.state.user
    },
    cdn () {
      return this.$store.state.cdn
    }
  },
  methods: {
    saveReview: async function () {
      this.isSavingReview = true
      // const pid = this.product.urunlerId
      this.review.pid = this.product.id
      const payload = {
        user: this.$store.state.user,
        review: this.review
      }
      await axios.post('/api/saveReview.php', payload).then(response => {
        const err = response.data.error
        if (err === 'none') {
          setTimeout(() => {
            this.isSavingReview = false
          }, 1500)
          this.review.c_id = parseInt(response.data.data)
          this.isSavingReview = false
        } else {
          this.isSavingReview = false
          this.$toast.error(err)
        }
      })
    },
    closeReviewModal () {
      this.$parent.showReviewModal = false
    }
  }
}
</script>
