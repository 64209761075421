<template>
  <div class="container" style="min-height:700px;padding-top:10px">
    <div class="campaigns">
      <div class="campaign" v-for="item in campaigns" :key="item.id">
        <router-link :to="{ name: 'Products', params: { id: item.category.id + '-' + $fnc.seo(item.category.title), top: item.category.parent_id }, query: { campaignId: item.id } }">
          <img :src="item.bannerUrl" :alt="item.name" :title="item.name" />
        </router-link>
        <flip-countdown class="flipper" :deadline="item.endDate" :labelSize="'8px'" :countdownSize="'20px'" :labels="labels"></flip-countdown>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FlipCountdown from 'vue2-flip-countdown'
export default {
  components: {
    FlipCountdown
  },
  data () {
    return {
      campaigns: [],
      mobileWidth: this.$store.state.mobileWidth,
      width: 0,
      labels: {
        days: 'Gün',
        hours: 'Saat',
        minutes: 'Dakika',
        seconds: 'Saniye'
      }
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Nettech Bayi Kampanyaları')
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: this.$i18n.t('metaDescription') }]
    }
  },
  beforeMount () {
    this.width = this.$fnc.getWidth()
    this.getCampaigns()
  },
  methods: {
    getCampaigns: async function () {
      this.$fnc.pageView(this)
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('api/getCampaigns.php', payload).then(response => {
        this.campaigns = response.data.data
      })
    }
  }
}
</script>
<style>
.flipper {
  width:240px;
  margin-top:-20px !important;
  border-radius: 4px;
  padding-top:0px;
  padding-bottom:0px;
  pointer-events: none;
}
.flip-clock__slot {
  color:#666;
}
</style>
<style scoped lang="scss">
.campaigns {
  margin-top:10px;
  margin-bottom:10px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
.campaign {
  position: relative;
  display: block;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
  a {
    img {
      width: 100%;
      border-radius: 3px;
    }
  }
}
@media screen and (max-width: 768px) {
  .campaigns {
    grid-template-columns: 1fr;
  }
}
</style>
