<template>
  <div id="app" @scroll="setScroll">
    <router-view :info="info" v-if="isLoaded"/>
    <GlassModal data-nosnippet v-if="$store.state.cookieConsent" @close="hideConcentPopup">
      <template v-slot:title>Gizlilik Bildirimi</template>
      <template slot="body">
        <p>Bu <span v-if="platform === 'web'">internet sitesinde</span>
        <span v-else>uygulamada</span>, kullanıcı deneyimini geliştirmek ve <span v-if="platform === 'web'">internet sitesinin</span>
        <span v-else>uygulamanın</span> verimli çalışmasını sağlamak amacıyla çerezler kullanılmaktadır.</p>
        <br>
        <p>İnternet sitesini veya mobil uygulamamızı kullanarak bu çerezlerin kullanılmasını kabul etmiş olursunuz.</p>
        <br>
        <p>Çerezleri nasıl kullandığımız, sildiğimiz ve engellediğimiz ile ilgili detaylı bilgi için lütfen Çerezler (Cookies) sayfasını okuyunuz.</p>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      isLoaded: false,
      platform: 'web',
      scroll: 0
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Nettech Store')
    return {
      title: title,
      titleTemplate: '%s - ' + title
    }
  },
  computed: {
    info () {
      return this.$store.state.info
    }
  },
  mounted: function () {
    this.getCurrencies()
    this.$root.statusBar()
    this.$root.getDeviceInfo()
    // this.$root.openBrowser('https://kareiki.com')
    window.addEventListener('resize', this.resize)
    /*
    setInterval(() => {
      const now = new Date().getTime()
      const last = localStorage.getItem('time') || 0
      const diff = now - last
      if (diff > (1000 * 10 * 1)) {
        // localStorage.setItem('time', now)
        console.log('refreshed')
        this.getInfo()
      }
      console.log(now)
    }, 1000 * 1)
    */
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
  },
  beforeMount () {
    /*
    const savedLocale = localStorage.getItem('lang')
    if (!savedLocale) {
      this.$i18n.locale = this.$route.params.lang
      localStorage.setItem('lang', this.$route.params.lang)
    }
    */
    this.getInfo()
  },
  watch: {
    $route (to, from) {
      this.getCurrencies()
      document.querySelector('body').style.overflow = 'auto'
      this.$store.dispatch('setBottomMenu', false)
      this.$store.dispatch('setBottomHide', false)
      this.$store.dispatch('setCatMenu', false)
    }
  },
  methods: {
    setScroll: function () {
      this.scroll = window.scrollY
    },
    getCurrencies: async function () {
      await axios.post('api/getCurrencies.php').then(response => {
        this.$store.dispatch('setCurrency', response.data.currency)
      })
    },
    resize: function () {
      const size = { width: this.$fnc.getWidth(), height: this.$fnc.getHeight() }
      this.$store.dispatch('setSize', size)
    },
    showConcentPopup: function () {
      // show cookie concept modal
      this.$store.dispatch('setCookieConsent', true)
    },
    hideConcentPopup: function () {
      // hide cookie concept modal
      this.$store.dispatch('setCookieConsent', false)
      localStorage.setItem('cookieConsent', true)
    },
    getInfo: async function () {
      // const menu = (this.$store.state.info.menu)
      const menu = false
      const sId = localStorage.getItem('sId')
      if (!menu) {
        const payload = {
          sId: sId
        }
        await axios.post('api/info.php', payload).then(response => {
          if (response.data.error) {
            localStorage.removeItem('sId')
            this.getInfo()
          } else {
            this.$store.dispatch('setInfo', response.data)
            this.$store.dispatch('setUser', response.data.user)
            this.$store.dispatch('setCart', response.data.user.cartCount)
            localStorage.setItem('sId', response.data.user.sId)
            // epoch time
            const now = new Date().getTime()
            localStorage.setItem('time', now)
          }
        }).then(response => {
          this.platform = localStorage.getItem('platform')
          this.isLoaded = true
          // show cookie concent after 3 seconds
          // detect user scroll
          const concent = localStorage.getItem('cookieConsent')
          if (concent !== 'true') {
            // test userAgent as bot|googlebot|crawler|spider|robot|crawling
            const userAgent = navigator.userAgent.toLowerCase()
            if (userAgent.indexOf('bot') === -1 && userAgent.indexOf('googlebot') === -1 && userAgent.indexOf('crawler') === -1 && userAgent.indexOf('spider') === -1 && userAgent.indexOf('robot') === -1 && userAgent.indexOf('crawling') === -1) {
              setTimeout(() => {
                this.showConcentPopup()
              }, 3000)
            }
          }
        })
      }
    }
  }
}
</script>

<style>
  @import './assets/css/main.css';
</style>
