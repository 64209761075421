<template>
  <div :class="cls">
    <GlassModal v-if="contractOpen" @close="closeContract" :fullPage="false">
      <template slot="title">&nbsp;</template>
      <template slot="body">
        <JsonPageNormal style="overflow-y:scroll; max-height: 450px" :page="contractOpen" />
      </template>
    </GlassModal>
    <div v-if="!msg">
      <h2 v-if="form.title">{{ form.title }}</h2>
      <p v-if="form.description" v-html="form.description"></p>
      <form :id="'simpleForm' + form.id" v-if="!formSending" autocomplete="nopese" method="dialog" @submit="gonder">
        <div v-for="(item,idx) in formFields" :key="idx" class="row">
          <label>{{ item.label }} <span v-if="item.req" style="color:red">*</span></label>
          <div v-if="item.type === 'text' || item.type === 'number' || item.type === 'email'">
            <input :required="item.req" autocomplete="off" :type="item.type" :name="'element[' + item.label +']'" :placeholder="item.label">
          </div>
          <div v-if="item.type === 'textarea'">
            <textarea rows="5" style="min-height:120px" :required="item.req" :name="'element[' + item.label +']'" :placeholder="item.label"></textarea>
          </div>
          <div v-if="item.type === 'select'">
            <select :required="item.req" :name="'element[' + item.label +']'">
              <option value="">Lütfen Seçin</option>
              <option :value="op" v-for="op in item.options" :key="op">{{ op }}</option>
            </select>
          </div>
        </div>
        <div v-if="form.title === 'BAYİLİK BAŞVURU FORMU'" style="display:grid; grid-template-columns: 22px auto; margin-bottom: 12px">
          <a @click="setContract"><i :class="[contract ? 'far fa-check-square' : 'far fa-square']"></i></a>
          <a @click="showContract('bayi-sozlesme')" style="width:100%;display:block">{{ $t('Bayi sözleşmesini kabul ediyorum') }}</a>
        </div>
        <div class="flx">
          <div class="row">
            <button type="submit">{{ form.button_title }}</button>
          </div>
          <div class="row" v-if="form.title === 'BAYİLİK BAŞVURU FORMU'">
            <router-link :to="{ name: 'Login' }" class="btn">GİRİŞ YAP</router-link>
          </div>
        </div>
      </form>
      <div v-else id="formPlaceholder" :style="'height:' + formHeight + 'px;display:grid; place-items:center'">
        <img src="@/assets/loading-pulse.svg">
      </div>
    </div>
    <div v-else :style="'height:' + formHeight + 'px;display:grid;place-items:center'">
      <div v-if="mailSonuc" class="sonuc">
        <i class="success far fa-check-circle"></i>
        <h1>İşlem Başarılı</h1>
        <p>{{ msg }}</p>
        <p>Müşteri temsilcimiz en kısa sürede size dönüş yapacaktır.</p>
        <router-link :to="{ name: 'Index' }">Anasyfaya Dön</router-link>
      </div>
      <div v-else class="sonuc">
        <i class="fail fa fa-ban"></i>
        <h1>İşlem Başarısız</h1>
        <p>{{ msg }}</p>
        <a @click="resetForm">Tekrar Deneyin</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flx {
  display: flex;
  .row {
    margin-right: 12px;
  }
  .row:last-child {
    margin-right: 0;
  }
}
.sonuc {
  display:grid;
  width: 100%;
  place-items: center;
  a {
    font-size:12px; color:blue;
    text-decoration: underline;
  }
}
.success {
  font-size: 100px;
  color: green;
}
.fail {
  font-size: 100px;
  color: red;
}
.row {
  display: grid;
  margin-bottom: 12px;
  label {
    font-size: 11px;
    margin-bottom: 2px;
    padding-left: 4px;
    color: var(--color2);
    font-weight: bold;
  }
  input, select, button, .btn, textarea {
    border: 1px solid var(--color1);
    width: 100%;
    padding: 8px;
    height: 34px;
    font-size: 13px;
    border-radius: 4px;
  }
  button {
    padding-left:14px;
    padding-right:14px;
    font-size: 14px;
  }
  .btn {
    padding-left:14px;
    padding-right:14px;
    background-color: var(--color2);
    font-weight:bold;
    color: #fff;
    border: none;
    display:grid;
    place-items: center;
    font-size: 14px;
  }
  button {
    background: var(--color1);
    color:#fff;
    font-weight: bold;
  }
}
</style>

<script>
import JsonPageNormal from '@/components/JsonPageNormal'
import axios from 'axios'
export default {
  components: {
    JsonPageNormal
  },
  props: ['form', 'cls'],
  data () {
    return {
      formFields: [],
      formSending: false,
      formHeight: 100,
      msg: '',
      mailSonuc: false,
      contractOpen: false,
      contract: false
    }
  },
  beforeMount () {
    this.init()
  },
  methods: {
    setContract: function () {
      this.contract = !this.contract
    },
    showContract: async function (url) {
      this.contractOpen = true
      await this.axios.post('/api/getPageWithUrl.php', { url: url }).then(res => {
        this.contractOpen = res.data.page
      }).catch(err => {
        this.contractOpen = false
        console.log(err)
      })
    },
    closeContract: function () {
      this.contractOpen = false
    },
    gonder: async function () {
      if (this.form.title === 'BAYİLİK BAŞVURU FORMU') {
        if (!this.contract) {
          this.$toast.error('Lütfen bayi sözleşmesini kabul ediniz.')
          return
        }
      }
      this.formSending = true
      const myForm = document.getElementById('simpleForm' + this.form.id)
      const formData = new FormData(myForm)
      const data = {}
      for (const [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }
      const payload = {
        user: this.$store.state.user,
        data: data,
        form_id: this.form.id,
        form_title: this.form.title
      }
      await axios.post('api/saveSimpleForm.php', payload).then(response => {
        console.log(response.data)
        const err = response.data.error
        const msg = response.data.msg
        const mailSonuc = response.data.mailSonuc
        setTimeout(() => {
          if (err !== 'none') {
            this.$toast.error(err)
          } else {
            this.success = true
            this.msg = msg
            this.mailSonuc = mailSonuc
          }
          this.formSending = false
        }, 2000)
      })
    },
    resetForm: function () {
      this.msg = ''
      this.mailSonuc = false
    },
    init: function () {
      if (typeof this.form.fields === 'string') {
        this.formFields = JSON.parse(this.form.fields)
      } else {
        this.formFields = this.form.fields
      }
      setTimeout(() => {
        this.formHeight = document.getElementById('simpleForm' + this.form.id).offsetHeight
      }, 200)
    }
  }
}
</script>
