<template>
  <div class="orderContainer">
    <div class="info">
      <div class="irow2"><span>{{ $store.state.user.userName }}</span></div>
      <div class="irow"><span>E-Posta</span><span>{{ $store.state.user.email }}</span></div>
      <div class="irow">
        <span>Güncel Bakiye</span>
        <span class="impo" style="font-size:15px">
          {{ $n(info.bakiye, 'currency', 'en') }}
          </span>
        </div>
      <div class="irow">
        <span>Güncel Bakiye (TL)</span>
        <span class="impo" style="font-size:15px;color:black">
          {{ $n(info.bakiyeTl, 'currency', 'tr') }}
        </span>
      </div>
    </div>
    <div class="paymentForm">
      <div v-if="!showCC">
        <label>Ödeme Tutarı (Türk Lirası Olarak Girin)</label>
        <input style="margin-top:5px;" type="number" v-model="price" placeholder="Ödeme Tutarı (TL)">
        <button v-if="$store.state.user.userId !== 6129" style="margin-top:12px" @click="makePayment">Ödeme işlemini başlat</button>
        <button v-else style="margin-top:12px; background:orangered">Demo hesapta ödeme yapamazsınız!</button>
      </div>
      <form v-else id="paymentForm" target="cc-start" :action="$store.state.info.base + 'cc-start.php'" method="post" @submit.prevent="processPayment">
        <div class="fields"></div>
        <div class="ccForm">
          <div class="formRow">
            <div><label>{{ $t('Kart üzerindeki isim') }}</label></div>
            <div><input v-model="cc.ccName" autofocus="autofocus" autocomplete="cc-name" name="cc-name" required type="text" :placeholder="$t('Kart üzerindeki isim')"></div>
          </div>
          <div class="formRow">
            <div><label>{{ $t('Kart Numarası') }}</label></div>
            <div><input @keypress="iyzicoBin" @blur="iyzicoBin" v-model="cc.ccNumber" autocomplete="cc-number" name="cc-number" type="number" required :placeholder="$t('Kart Numarası')"></div>
          </div>
          <div style="display: grid; grid-template-columns: auto 90px; gap: 12px;">
            <div class="formRow">
              <div><label>{{ $t('Son Kullanma Tarihi') }}</label></div>
              <div style="display:grid; grid-template-columns:1fr 1fr; gap:12px;">
                <div><select v-model="cc.ccMonth" autocomplete="cc-exp-month" name="cc-exp-month" required><option value="0">{{ $t('AY') }}</option><option v-for="item in months" :key="item">{{ item }}</option></select></div>
                <div><select v-model="cc.ccYear" autocomplete="cc-exp-year" name="cc-exp-year" required><option value="0">{{ $t('YIL') }}</option><option v-for="item in years" :key="item">{{ item }}</option></select></div>
              </div>
            </div>
            <div class="formRow">
              <div><label>{{ $t('Güvenlik Kodu') }}</label></div>
              <div><input v-model="cc.ccCvv" autocomplete="cc-csc" name="cc-csc" required type="number" placeholder="CVV"></div>
            </div>
          </div>
          <div class="formRow" v-if="installments">
            <div><label>{{ $t('Taksit Seçenekleri') }}</label></div>
            <div class="radioGroup">
              <label :class="{ 'activeRadio': instalment === item.instalment }" :value="item.instalment"
              v-for="item in installments" :key="item.id">
                <input name="instalment" :value="item.instalment" v-model="instalment" type="radio">
                <span v-if="item.instalment === 1">{{ item.name }} ( {{ (((item.commission / 100) + 1) * (price)).toFixed(2) }}₺ )</span>
                <span v-else>{{ item.name }}
                  (
                    {{ item.monthly.toFixed(2) }}₺ x {{ item.instalment }} Ay
                  = {{ item.paidPrice.toFixed(2) }}₺
                  )
                </span>
              </label>
            </div>
          </div>
          <div class="formRow2">
            <div style="display:grid;grid-template-columns: 20px auto; margin-top:12px;margin-bottom:12px">
              <input type="checkbox" id="tds" checked disabled><label for="tds">{{ $t('3D Secure ile güvenli öde') }}</label>
            </div>
          </div>
          <button @click="processPayment">Ödeme işlemini başlat</button>
        </div>
      </form>
    </div>
    <GlassModal style="width:100%;margin:0;padding:0;z-index:100000" v-if="showPayment" :hideClose="true" :fullPage="true">
      <template v-slot:title></template>
      <template v-slot:body>
        <p style="text-align:center; font-weight:bold;padding-top:12px;color:green"><i class="fa fa-shield-alt"></i> Güvenli Ödeme Sayfasındasınız</p>
        <p style="text-align:center;margin-top:12px;padding-bottom:12px;font-size:13px">Lütfen ödeme işleminiz tamamlanana kadar bu sayfayı kapatmayın</p>
        <iframe name="cc-start" style="border:none;min-height:100vh;width:100%"></iframe>
      </template>
    </GlassModal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['info'],
  data () {
    const currentYear = new Date().getFullYear()
    const years = []
    for (let i = currentYear; i < currentYear + 12; i++) {
      years.push(i)
    }
    return {
      instalment: 1,
      installments: null,
      cdn: this.$store.state.mediaCdn,
      showPayment: false,
      showCC: false,
      price: '',
      contract: null,
      popup: false,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: years,
      ccError: false,
      ccErrors: [],
      cc: {
        ccName: '',
        ccNumber: '',
        ccMonth: '0',
        ccYear: '0',
        ccCvv: ''
      }
    }
  },
  mounted () {
    // this.selectActive(this.info.orders[0].faturaId)
  },
  methods: {
    processPayment: async function () {
      console.clear()
      event.preventDefault()
      const payload = {
        paymentMethod: { id: 1 },
        instalment: this.instalment,
        user: this.$store.state.user,
        price: this.price
      }
      this.isSavingCart = true
      const fields = []
      const form = document.querySelector('.fields')
      var str = ''
      await axios.post('/api/saveOrderPayment.php', payload).then(response => {
        const data = response.data.data
        fields.push({ name: 'uniq', value: data.uniq })
        fields.push({ name: 'reference', value: data.reference })
        fields.forEach(f => {
          str += '<input type="hidden" name="' + f.name + '" value="' + f.value + '">'
        })
        form.innerHTML = str
        this.checkPayment(data.reference)
        this.showPayment = true
        setTimeout(() => {
          document.querySelector('#paymentForm').submit()
        }, 500)
      })
    },
    checkPayment: async function (ref) {
      // check order status every 3 seconds
      const payload = {
        ref: ref,
        user: this.$store.state.user,
        uniq: this.$parent.uniq
      }
      const interval = setInterval(() => {
        axios.post('/api/checkOrder.php', payload).then(response => {
          const data = response.data
          console.log(data)
          if (data.status === 'success') {
            clearInterval(interval)
            this.showPayment = false
            this.isSavingCart = false
            // goto thanks page
            this.$router.push({ name: 'Thanks', query: { r: ref } })
          } else if (data.status === 'fail') {
            clearInterval(interval)
            this.$router.push('/tr/hata?r=' + ref)
            this.showPayment = false
            this.isSavingCart = false
          }
        })
      }, 3000)
    },
    makePayment () {
      if (this.price > 0) {
        this.showCC = !this.showCC
      } else {
        alert('Tutar girmelisiniz')
      }
    },
    closePayment () {
      this.showPayment = false
    },
    iyzicoBin: async function () {
      if (this.cc.ccNumber.length > 5) {
        const payload = { bin: this.cc.ccNumber, price: this.price }
        await axios.get('/iyzico-bin.php', { params: payload }).then(response => {
          const data = response.data
          const stat = data.status
          if (stat === 'success') {
            console.log(data.installments)
            this.installments = data.installments
          } else {
            this.installments = null
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.formRow {
  .btn1 {
    padding:10px;
    border-radius: 5px;
    border:none;
    background: var(--color1);
    background: green;
    color: white;
    font-weight: normal;
    font-family: var(--font3);
    font-size: 14px;
    letter-spacing: 1px;
    width: 220px;
    display:flex;place-items:center;gap:5px;justify-content:center;
    span, i {
      width:24px;height:14px;
    }
  }
  .passive {
    filter: brightness(1.4);
  }
  .btn1:hover {
    border:none;
    filter: brightness(1.15);
  }
}

.formError {
  padding:8px;
  font-size: 13px;
  border:2px dashed orangered;
  border-radius: 8px;
}
.formRow2 {
  div {
    padding: 4px;
    border:2px solid #fff;
    span {
      font-size: 13px !important;
      a {
        color:blue;
        text-decoration: underline;
      }
    }
  }
  .error {
    background:#ffe9e9;
    border-radius: 6px;
    border:2px dashed #f79595;
  }
}
.paymentForm {
  display:grid;
  place-items: left;
  gap: 10px;
  background: #fff;
  padding: 10px;
  border:1px solid #ccc;
  margin-top: 20px;
  padding-top: 2px;
  label {
    text-align: left;
    font-size: 11px;
    margin-bottom: 4px;
    font-weight: bold;
  }
  input, button, select {
    width: 100%;
    padding: 12px;
    border:1px solid #ccc;
  }
  button {
    border:none;
    background: var(--color1);
    color: white;
    font-weight: bold;
  }
}
.orderContainer {
  width: 100%;
}
.t1 {
  width: 100%;
  min-width: 750px;
}
.info {
  background: #fff;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  .irow {
    display:grid;
    grid-template-columns: 120px auto;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    .impo {
      color:var(--color1);
      font-weight: bold;
      font-size: 18px;
    }
  }
  .irow2 {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
  }
}
td,th {
  border-bottom:1px solid #ccc;
  text-align: left;
  font-size:12px;
  padding:3px;
}
tr {
  height:44px;
}
tr:last-child td {
  border: none;
}
.r {
  text-align: right;
  width:80px;
}
.filePicker {
  margin-top:6px;
  padding: 12px;
  background:#e5e5e5;
  border-radius:6px;
  display:grid;
  grid-template-columns:auto 120px;
  gap:10px;
  place-items:center;
  input {
    width:100%;
  };
  button {
    font-size: 12px;
    width: 100%;
    padding:9px;
    border:none;
    border:1px solid #ccc;
    background:#fff;
    border-radius:6px;
    transition: all 0.1s ease-out;
  }
  button:hover {
    background: var(--color2);
    color:white;
  }
}
a {
  color:black;
  user-select: unset;
  user-select: text !important;
}
a:hover {
  color: var(--color1);
}
.ovalImage {
  padding:1px; border:1px solid #ccc;border-radius:50%;background:white;
  user-select: none;
  -webkit-user-drag: none;
}
.grid2 {
  display:grid;
  grid-template-columns: auto auto;
  .right {
    text-align:right;
  }
}
.buttonSet {
  text-align:center;
  button {
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border: none;
    background: #f1f1f1;
    border-radius: 50%;
  }
  button:hover {
    background: #e0e0e0;
  }
}
.row {
  // border: 1px solid #ccc;
  margin-bottom: 22px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
  transition: all 0.1s ease-out;
}
.row:hover, .activeRow {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
}
.faturaRow {
  padding: 10px;
  align-items: center;
  display:grid;
  grid-template-columns: 240px auto 40px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  background: #fff;
  overflow: hidden;
  border-radius: 6px;
  div {
    padding: 8px;
  }
}
.items {
  background:#f3f3f3;
  border-radius: 6px;
  ul {
    list-style: none;
    padding: 0px;
    li {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 20px;
      .ustRow {
        display: grid;
        grid-template-columns: 100px auto 60px 60px;
        align-items: center;
        min-height: 90px;
      }
    }
    li:nth-child(even) {
       background: white;
    }
  }
}
.radioGroup {
  label {
    display:grid; grid-template-columns:24px auto;
    height: 32px;
    align-items: center;
    padding:2px;
    border: 2px dashed #e2e2e2;
    margin-bottom: 8px;
    span {
      font-size:13px;
      font-weight: normal;
    }
    input:focus{
      border:none;
      box-shadow: none;
    }
  }
  .activeRadio {
    background:#f6feff;
    border: 2px solid #b4e9f0;
  }
}
@media screen and (max-width: 768px) {
  .orderContainer {
    width:calc(100vw - 20px);
  }
  .faturaRow {
    grid-template-columns: 1fr;
  }
  .ustRow {
    grid-template-columns: 60px auto 60px 60px !important;
  }
}
</style>
