<template>
  <div>
    <Header :info="info" />
    <div class="tContainer">
      <div class="container" style="display:grid;place-items:center">
        <i class="far fa-check-circle"></i>
        <h1>{{ $t('Teşekkürler') }}</h1>
        <div v-if="order.mode !== 'payment'">
          <h2>{{ $t('Siparişiniz başarıyla tamamlandı') }}</h2>
          <p>{{ $t('Hesabım sayfasından siparişinizin güncel durumunu takip edebilirsiniz') }}</p>
          <p><router-link :to="{ name: 'MyAccountOrders' }">{{ $t('Sipariş Takibi') }}</router-link></p>
        </div>
        <div v-else>
          <h2>{{ $t('Ödemeniz başarıyla tarafımıza ulaştı') }}</h2>
          <p><router-link :to="{ name: 'MyAccountCariHesabim' }">{{ $t('Hesabım Sayfası') }}</router-link></p>
        </div>
        <p><router-link :to="{ name: 'Index' }">{{ $t('Anasayfa') }}</router-link></p>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.tContainer {
  padding-top:50px;
  padding-bottom:50px;
}
i {
  font-size: 100px;
  color: green;
}
h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 20px;
  font-size:18px;
  text-align: center;
}
p {
  margin-bottom: 8px;
  text-align: center;
}
a {
  color: blue;
  text-decoration: underline;
}
a:hover {
  color: var(--color1);
}
</style>

<script>
import axios from 'axios'
export default {
  components: { },
  props: ['info'],
  data () {
    return {
      message: 1,
      order: { mode: null }
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Teşekkürler'),
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  mounted () {
    this.getOrder()
  },
  methods: {
    getOrder: async function () {
      if (!this.$route.query.r) {
        return
      }
      const payload = {
        id: parseInt(this.$route.query.r),
        sId: this.$store.state.user.sId,
        user: this.$store.state.user
      }
      await axios.post('api/getOrder.php', payload).then(response => {
        setTimeout(() => {
          this.order = response.data.order
        }, 0)
      })
    }
  }
}
</script>
