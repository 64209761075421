<template>
  <div>
    <div v-if="$store.state.user.userId">
      {{ $store.state.user.userName }}<br>
      {{ $store.state.user.email }}<br><br>
    </div>
    <form v-if="!$store.state.user.userId">
      <h1>{{ $t('Üye Olun') }}</h1>
      <p class="formError" v-if="formError">{{ $t(formError) }}</p>
      <div id="registername" style="margin-top:22px">
        <label>{{ $t('Adınız Soyadınız') }}</label>
        <input @keypress.enter="register" v-model="name" type="text" autocomplete="name" :placeholder="$t('Adınız Soyadınız')">
      </div>
      <div id="registeremail">
        <label>{{ $t('E-Posta Adresiniz') }}</label>
        <input @keypress.enter="register" v-model="email"  type="email" autocomplete="email" :placeholder="$t('E-Posta Adresiniz')">
      </div>
      <div id="registerpass">
        <label>{{ $t('Parolanız') }}</label>
        <input @keypress.enter="register" v-model="pass" type="password" autocomplete="new-password" :placeholder="$t('Parolanız')">
      </div>
      <div id="registercontract">
        <div style="display:grid; grid-template-columns: 22px auto">
          <a @click="setContract"><i :class="[contract ? 'far fa-check-square' : 'far fa-square']"></i></a>
          <a @click="showContract('uyelik')" style="width:100%;display:block">{{ $t('Üyelik sözleşmesini kabul ediyorum') }}</a>
        </div>
      </div>
      <div>
        <a @click="setMessagePermission">
          <i :class="[messagePermission ? 'far fa-check-square' : 'far fa-square']"></i> {{ $t('Kampanya ve haberlerden bilgi sahibi olmak istiyorum')}}
        </a>
      </div>
      <div>
        <router-link :to="{ name: 'Login' }"><i class="fa fa-user"></i> {{ $t('Giriş yapmak için tıklayın') }}</router-link>
      </div>
      <div style="display:none">
        <router-link :to="{ name: 'ForgetPassword' }"><i class="fa fa-fish"></i> {{ $t('Parolanızı mı unuttunuz?') }}</router-link>
      </div>
      <div>
        <button @click="register" type="button"><i class="fa fa-lock"></i>{{ $t('Üyeliği Tamamla') }}</button>
      </div>
    </form>
    <GlassModal v-if="contractOpen" @close="closeContract" :fullPage="false">
      <template slot="title">{{ $t('Üyelik Sözleşmesi') }}</template>
      <template slot="body">
        <JsonPageNormal style="overflow-y:scroll; max-height: 450px" :page="contractOpen" />
      </template>
    </GlassModal>
  </div>
</template>

<script>
import JsonPageNormal from '@/components/JsonPageNormal.vue'
import axios from 'axios'
export default ({
  components: {
    JsonPageNormal
  },
  props: ['info', 'from'],
  data () {
    return {
      name: '',
      email: '',
      pass: '',
      contract: true,
      messagePermission: false,
      user: this.$store.state.user,
      formError: '',
      contractOpen: false
    }
  },
  mounted () {
    // this.goBayilik()
  },
  methods: {
    goBayilik: function () {
      this.$router.push({ name: 'PageGroup', params: { parent: 'nettech-bayilik', id: '' } })
    },
    setContract: function () {
      this.contract = !this.contract
    },
    setMessagePermission: function () {
      this.messagePermission = !this.messagePermission
    },
    showContract: async function (url) {
      this.contractOpen = true
      await this.axios.post('/api/getPageWithUrl.php', { url: url }).then(res => {
        this.contractOpen = res.data.page
      }).catch(err => {
        this.contractOpen = false
        console.log(err)
      })
    },
    closeContract: function () {
      this.contractOpen = false
    },
    register: async function () {
      const payload = {
        name: this.name,
        email: this.email,
        pass: this.pass,
        user: this.user,
        contract: this.contract,
        messagePermission: this.messagePermission
      }
      await axios.post('/api/register.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.$router.push('/')
        } else {
          this.formError = err
          const field = response.data.field
          const labels = document.querySelectorAll('label')
          labels.forEach(l => {
            l.classList.remove('red')
          })
          if (field) {
            const lbl = document.querySelector('#register' + field + '> label')
            if (lbl) {
              lbl.classList.add('red')
            }
            const inp = document.querySelector('#register' + field + '> input')
            if (inp) {
              inp.select()
            }
          }
        }
      })
    }
  }
})
</script>
