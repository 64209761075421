<template>
  <div>
    <Header :info="info" />
    <div class="container mh" style="margin-top:12px">
      <ServiceSelector v-if="!mode" />
      <div v-else-if="mode && $store.state.width < 768">
        <a @click="setMode('')">Teknik Servis</a>
      </div>
      <div v-if="mode" id="mode" class="first">
        <ServiceRequest v-if="mode === 'request'" />
        <ServiceQuery v-if="mode === 'query'" />
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.first {
  background:#fff;padding:20px;margin-top:12px;border:1px solid #ccc;border-radius:4px
}
@media screen and (max-width: 768px) {
  .first {
    padding: 5px;
    border:none;
    width:100%;
  }
}
.mh {
  padding-bottom: 20px;
  min-height: 500px;
  .inputRow {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    input {
      padding: 8px;
      border: 1px solid var(--color1);
      border-radius: 2px;
    }
  }
}
.form {
  padding: 20px;
  padding-top: 10px;
  border: 1px solid #ccc;
  background:#fff;
  margin-top: 12px;
  border-radius: 4px;
}
button {
  // primary button
  border: none;
  background: var(--color1);
  color: white;
  padding: 12px;
  border-radius: 4px;
}
.loading {
  display:grid; place-items:center;
}
.serviceForm {
  padding: 200px;
  width:100%;
  h2 {
    text-align:center;
    font-size: 18px;
  }
}
</style>

<script>
import ServiceSelector from '@/small-components/ServiceSelector'
import ServiceRequest from '@/small-components/ServiceRequest'
import ServiceQuery from '@/small-components/ServiceQuery'

export default {
  components: {
    ServiceRequest,
    ServiceQuery,
    ServiceSelector
  },
  props: ['info'],
  data () {
    return {
      id: this.$route.params.id,
      formNumber: '',
      phoneNumber: '',
      body: '',
      error: false,
      loading: true,
      mode: false
    }
  },
  metaInfo () {
    return {
      title: 'Teknik Servis Kaydı',
      meta: [{ vmid: 'description', name: 'description', content: 'Teknik Servisimize Cihazınızı Gönderin' }]
    }
  },
  mounted () {
    if (this.id) {
      this.getDeviceStatus()
    } else {
      this.loading = false
    }
  },
  methods: {
    setMode: function (mode) {
      this.mode = mode
    },
    getDeviceStatus: async function () {
      this.loading = true
      await this.$http.get('/api/netsoft/getServiceStatus.php?id=' + this.id + '&formNumber=' + this.formNumber + '&phone=' + this.phoneNumber).then(response => {
        if (response.data === 'error') {
          this.error = true
        } else {
          this.body = response.data
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    }
  }
}
</script>
