<template>
  <div v-if="item" class="container slideContainer">
    <splide :options="options">
      <splide-slide v-for="item in item.subs" :key="item.id">
        <router-link :to="item.url">
          <img width="100%" :src="fileCdn + item.image" loading="lazy">
        </router-link>
      </splide-slide>
    </splide>
  </div>
</template>

<style scoped lang="scss">
.slideContainer {
  margin-bottom:12px;
  border-radius:4px;
  overflow:hidden;
  aspect-ratio: 1400/350;
}
.primary {
  padding: 16px;
  background: var(--color2);
  color: white;
  border-radius: 32px;
  font-size: 15px;
  font-weight: 800;
  letter-spacing: 1.2px;
}
.primary:hover {
  background-color: var(--color1);
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
}
.banner {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 80px;
  border-radius: 2px;
  background-position: center !important;
  background-size: cover !important;
  display: grid;
  grid-template-columns: 3fr 4fr;
  align-items: center;
  .text {
    margin:auto;
    // margin-left: 30px;
    // margin-top: 30px;
    width:calc(100% - 80px);
    // width:100%;
    h1 {
      text-transform: uppercase;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    h2 {
      font-weight: 600;
      line-height: 28px;
      font-size: 16px;
      margin-bottom: 30px;
    }
    p {
      width: 100%;
      text-align: left;
    }
  }
}
@media screen and (max-width: 768px) {
  .banner {
    grid-template-columns: 1fr;
    .text {
      margin-top: 20px;
      margin-left: 20px;
      width: calc(100% - 40px);
      h1 {
        font-size: 22px;
      }
      h2 {
        font-size: 13px;
      }
      p {
        text-align: center;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width:1024px) {
  .banner {
    .text {
      h1 {
        font-size: 24px;
      }
      h2 {
        font-size: 12px;
      }
      p {
        text-align: left;
      }
    }
  }
}

.slider {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 800px auto;
  div {
    img {width: 100%};
  }
  .sag {
    display: grid;
    gap: 20px;
  }
}
.promo {
  width:100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}
</style>

<script>
export default {
  props: ['item'],
  data () {
    return {
      fileCdn: this.$store.state.fileCdn + '/',
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: false,
        arrows: false,
        pagination: true,
        lazyLoad: true,
        autoplay: true,
        type: 'loop',
        keyboard: false
      }
    }
  }
}
</script>
