<template>
  <div>
    <div class="footerContainer">
      <footer class="container" v-if="info.companyInfo">
        <div class="ft">
          <div style="margin-bottom:15px;">
            <router-link :to="{ name: 'Contacts' }">
              <i class="fa fa-map-marker"></i>
              {{ info.companyInfo.PAGE_ADRES }}
            </router-link>
            <a :href="'tel:+90' + info.companyInfo.PAGE_PHONE">
              <i class="fa fa-phone-alt"></i> {{ $fnc.phone(info.companyInfo.PAGE_PHONE) }}
            </a>
            <a :href="'https://wa.me/90' + info.companyInfo.PAGE_WHATSAPP" target="_blank">
              <i class="fab fa-whatsapp"></i> {{ $fnc.phone(info.companyInfo.PAGE_WHATSAPP) }}
            </a>
            <a :href="'mailto:' + info.companyInfo.PAGE_EPOSTA + ''">
              <i class="fa fa-envelope"></i>
              {{ info.companyInfo.PAGE_EPOSTA }}
            </a>
            <router-link :to="{ name: 'Contacts' }">
              <i class="fa fa-envelope"></i>
              İletişim
            </router-link>
          </div>
          <div class="socialIcons">
            <a :title="item.paramTitle" v-for="item in info.socials" :key="item.id" rel="noopener" :href="item.paramValue" target="_blank">
              <i :class="'fab fa-' + item.paramTitle.toLowerCase()"></i>
            </a>
          </div>
        </div>
        <div class="ft">
          <p>Kategoriler</p>
          <div style="columns: 2">
            <router-link v-for="item in info.menu" :key="item.id" :to="{ name: 'Products', params: { id: item.id + '-' + $fnc.seo(item.title), top: item.id } }">
              {{ item.title }}
            </router-link>
          </div>
        </div>
        <div class="ft">
          <p>{{ $t('Kurumsal') }}</p>
          <div>
            <router-link v-for="item in info.kurumsal" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
          </div>
          <div>
            <router-link v-for="item in info.kurumsal2" :key="item.id" :to="{ name: 'Page', params: { id: item.id + '-' + $fnc.seo(item.title), parent: item.cat_id }}">{{ item.title }}</router-link>
          </div>
          <a href="https://www.eticaret.gov.tr/siteprofil/6AF4DA0F7E174D128FA2555EFA77D8A6/wwwnettechstorecom" target="_blank">
            <img alt="Etbis" style="width:86px;height:100px;background:#fff;padding:5px;border-radius:4px" src="~@/assets/etbis.png">
          </a>
        </div>
        <div class="ft">
          <p>Müşteri Hizmetleri</p>
          <div>
            <router-link :to="item.to" v-for="item in info.homeLinks" :key="item.id">
              {{ item.title }}
            </router-link>
          </div>
          <div style="margin-top:12px" v-if="$store.state.platform.platform === 'web'">
            <a :href="info.androidUrl" v-if="info.androidUrl" target="_blank"><img height="50" width="168" alt="Google Play'den İndirin" src="~@/assets/google-play-badge.png"></a>
            <a :href="info.iosUrl" v-if="info.iosUrl" target="_blank"><img height="50" width="189" alt="Apple Store'dan indirin" src="~@/assets/ios-badge.png"></a>
          </div>
        </div>
      </footer>
      <div class="copy" style="border-top:1px solid #ccc">
        <div class="container">
          <a rel="noopener" target="_blank" href="http://www.nettelgroup.com/">
            <img title="Nettel Group Markasıdır" alt="Nettel Group Markasıdır" src="~@/assets/nettel-group-logo.png">
          </a>
          <div class="iyzs"><img src="~@/assets/logo_band_colored.svg"></div>
        </div>
      </div>
      <div class="copys" style="padding:0;padding-bottom:20px">
        <div class="container" style="text-align:center">
          <a rel="noopener" style="justify-content:center" target="_blank" href="https://www.kareiki.com">2022 © Kare iKi</a>
        </div>
      </div>
    </div>
    <FixedNavbar :info="info" v-if="$store.state.width < 768" :hideBottom="$store.state.hideBottom" />
  </div>
</template>

<script>
import FixedNavbar from '@/components/FixedNavbar'
export default {
  components: { FixedNavbar },
  props: ['info']
}
</script>

<style scoped lang="scss">
.iyzs {
  width:290px;
  height:40px;
  display: grid;
  place-items: center;
  img {
    width:100%;
  }
}
.socialIcons {
  display:flex;
  gap: 15px;
  a {
    width:40px;
    height: 40px;
    background: #fff;
    border:2px solid #8b9198;
    border-radius: 50%;
    text-align: center;
    color: #8b9198;
    font-size: 18px;
    display: grid;
    place-items: center;
  }
  a:hover {
    color: var(--color1);
    border:2px solid var(--color1);
  }
}
.footerContainer{
  background:#eee;
  border-top: 1px solid #ccc;
  padding-top: 30px;
}
.copy {
  // background:var(--color2);
  background:#eee;
  padding-bottom: 0px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-top: 15px;
    a {
      color:var(--color2);
    }
    a:hover {
      color:black;
    }
    font-size: 13px;
  }
}

footer {
  display:grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap:0px;
  .ft {
    margin-right: 20px;
    p {
      font-size: 14px;
      color: #313131;
      font-weight: bold;
      padding-bottom: 4px;
    }
  }
  .ft:last-child {
    margin-right: 0px;
  }
}
a {
  font-size: 13px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  color:#686868;

}
a:hover {
  color:var(--color1);
}
@media screen and (max-width: 768px) {
  footer {
    grid-template-columns: repeat(1, 1fr);
    .ft {
      margin-bottom: 20px;
      margin-right: 0px;
    }
  }
  .socialIcons{
    justify-content: space-around;
    width: calc(100% - 80px);
    margin: auto;
  }
  .copy {
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
      a {
        text-align: center;
        font-size: 13px;
      }
    }
  }
}
</style>
