<template>
  <div>
    <Header :info="info" />
    <ProductDetail />
    <Footer :info="info" class="ftr" />
  </div>
</template>

<style scoped>
@media screen and (max-width: 768px) {
  .ftr {
    padding-bottom: 70px;
  }
}
</style>

<script>
import ProductDetail from '@/components/ProductDetail.vue'

export default {
  components: { ProductDetail },
  props: ['info']
}
</script>
