<template>
  <div>
    <div v-if="!isLoading" style="min-height:700px;padding-top:10px">
      <div class="container secondMenu" v-if="$store.state.width < 768">
        <router-link v-for="item in $store.state.info.homeLinks" :key="item.id" :to="item.to">
          {{ item.title }}
        </router-link>
      </div>
      <div v-for="item in home.components" :key="item.id">
        <Collection v-if="item.tip === 'collection'" class="collection" :item="item" />
        <SmallSlider v-if="item.tip === 'slider'" :item="item" />
        <Brands class="container" v-if="item.tip === 'markalar'" :item="item" :brand_info="' '" />
      </div>
      <div class="container" v-if="$store.state.width > 768">
        <div style="width: calc(100%); display:grid; grid-template-columns: 1fr 1fr; gap: 20px">
          <Banner style="margin:0" v-for="(item, index) in home.banners" :key="index" :item="item" />
        </div>
      </div>
      <div v-else class="container">
        <Banner v-for="(item, index) in home.banners" :key="index" :item="item" />
      </div>
    </div>
    <div v-else class="container" style="min-height:700px;padding-top:10px">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Banner from '@/home-components/Banner'
import SmallSlider from '@/home-components/SmallSlider'
import Brands from '@/home-components/Brands'
// import Blogs from '@/home-components/Blogs'
import Collection from '@/home-components/Collection'
export default {
  components: {
    Collection,
    // Blogs,
    Banner,
    SmallSlider,
    Brands
  },
  data () {
    return {
      home: {},
      mobileWidth: this.$store.state.mobileWidth,
      width: 0,
      isLoading: true,
      fileCdn: this.$store.state.fileCdn,
      interval: null
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Hoş Geldiniz')
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: this.$i18n.t('metaDescription') }]
    }
  },
  mounted: function () {
    // this.refresh()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
    // clearInterval(this.interval)
  },
  beforeMount () {
    this.width = this.$fnc.getWidth()
    this.getHome()
  },
  methods: {
    refresh: function () {
      this.interval = setInterval(() => {
        const now = new Date().getTime()
        const last = localStorage.getItem('time') || 0
        const diff = now - last
        // console.log(diff)
        if (diff > (1000 * 60 * 1)) {
          localStorage.setItem('time', now)
          console.log('refreshed')
          this.getHome()
        }
        console.log('HOME' + (now))
      }, 1000)
    },
    getHome: async function () {
      const hhh = false
      this.isLoading = true
      this.$fnc.pageView(this)
      this.home = {}
      if (!hhh) {
        const payload = {
          user: this.$store.state.user
        }
        await axios.post('api/home.php', payload).then(response => {
          this.$store.dispatch('setHome', response.data)
          this.home = this.$store.state.home
          this.isLoading = false
        })
      } else {
        this.home = this.$store.state.home
      }
    }
  }
}
</script>

<style scoped lang="scss">
.homeSideBar {
  position: sticky;
  top: 50px;
}
.box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  border-radius: 3px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.loader {
  width:100%;
  height:822px;
  background:url(../../src/assets/home-placeholder-mobile.png) no-repeat center;
  background-size:cover;
  background-position: top;
}
.secondMenu {
  display:flex;
  margin-bottom:9px;
  font-size:12px;
  letter-spacing: -0.8px;
  justify-content: space-between;
  a {
    color: #666;
    color: var(--color2);
    font-weight: bold;
    font-family: var(--font3);
    text-decoration: underline;
    text-align: center;
  }
}
.slide {
  background-position:center !important;
  height:100%;
  background-size:cover !important;
}
h1 {
  font-size: 22px;
}
.blogs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background:#f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.blosist {
  padding: 20px;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  div {
    padding: 0px;
    background:#fff;
    .text {
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 15px;
    }
    img {
      width: 100%;
      height:227px;
    }
    h3 {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.mainProducts {
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
}
.product {
  width: 100%;
  text-align: center;
  h3 {
    font-weight: normal;
    font-size: 18px;
    margin-top: 8px;
  }
  p {
    font-weight: bold;
  }
  img {
    width: 100%;
  }
}
.slider {
  height: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 800px auto;
  div {
    img {width: 100%};
  }
  .sag {
    display: grid;
    gap: 20px;
  }
}
.promo {
  width:100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}
.textArea {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  h1 {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
  }
}
</style>
